import CustomChainAlert from 'alert/components/Alert/CustomChainAlert';
import { CHAIN_BY_ID, CHAIN_ID_BY_EIP } from 'shared/constants/blockchain.constants';
import { AnalyzeTransactionResponseType } from 'shared/interfaces/analyze.interfaces';
import { CustomChainTransactionAnalysis } from 'shared/interfaces/custom-chain.interfaces';

import styles from './styles.module.scss';
import { Alert } from '../../../../components';

const AlertContainer = (): React.ReactElement => {
  if (typeof window.getAlertData !== 'function') {
    return <h3>No data</h3>;
  }

  const {
    data, projectAddress, siteUrl, transactionValue, chainId,
  } = window.getAlertData();

  const chain = chainId ? CHAIN_BY_ID[CHAIN_ID_BY_EIP[chainId]] : null;

  const isCustomChain = 'txType' in data && chain;

  return (
    <div className={styles.alertContainer} data-testid="alertContent">
      {isCustomChain
        ? (
          <CustomChainAlert
            data={data as CustomChainTransactionAnalysis}
            projectAddress={projectAddress}
            transactionValue={transactionValue}
            handleDecline={() => null}
            handleProceed={() => null}
            chain={chain}
          />
        ) : (
          <Alert
            data={data as AnalyzeTransactionResponseType}
            projectAddress={projectAddress}
            transactionValue={transactionValue}
            siteUrl={siteUrl}
            handleDecline={() => null}
            handleProceed={() => null}
          />
        )}
    </div>
  );
};

export default AlertContainer;
