export const CHECK_NFT_PATH = process.env.NEXT_PUBLIC_API_PATH || process.env.REACT_APP_CHECK_NFT_PATH || '';
export const CHECK_NFT_TOKEN_PATH = 'https://checknft.io/token/';
export const CHECK_NFT_COLLECTION_PATH = 'https://checknft.io/search/';
export const API_PROD_PATH = CHECK_NFT_PATH;

export const TOKEN_SHORT_INFO_API = `${CHECK_NFT_PATH}api/v1/nft/`;
export const CONTRACT_ANALYZER_API = `${CHECK_NFT_PATH}api/v1/contract-analyzer/`;
export const ANALYSIS = '/analysis';
export const EXTENSION_CONTRACT_API = `${CHECK_NFT_PATH}api/v1/extension/contract/`;
export const EXTENSION_CONTRACT_API_V2 = `${CHECK_NFT_PATH}api/v2/extension/analyze/transaction/`;
export const EXTENSION_ANALYZE_CUSTOM_CHAIN_TRANSACTION = `${CHECK_NFT_PATH}api/v2/extension/analyze/custom-chain-transaction/`;
export const EXTENSION_NFTS_API = `${CHECK_NFT_PATH}api/v1/extension/nft/`;
export const EXTENSION_NFTS_COLLECTIONS_API = `${CHECK_NFT_PATH}api/v2/extension/analyze/listing/risks`;
export const EXTENSION_MAKE_OFFER_API = `${CHECK_NFT_PATH}api/v2/extension/analyze/make-offer/`;
export const EXTENSION_MAKE_OFFER_API_V2 = `${CHECK_NFT_PATH}api/v2/extension/analyze/make-offer-v2`;
export const EXTENSION_LIMIT_ORDER_API = `${CHECK_NFT_PATH}api/v2/extension/analyze/contract/`;
export const EXTENSION_DEX_PAIRS_API = `${CHECK_NFT_PATH}api/v2/extension/analyze/dex-pairs/[address]`;
export const EXTENSION_FOUNDATION_AUCTION_API = `${CHECK_NFT_PATH}api/v2/extension/analyze/foundation-auction/`;
export const EXTENSION_PAYMENT_TOKEN_API = `${CHECK_NFT_PATH}api/v1/extension/payment-token/`;
export const EXTENSION_GET_TOKEN = `${CHECK_NFT_PATH}api/v1/extension/token/`;
export const EXTENSION_INITIALIZE_PATH = 'api/v1/extension/initialize/';
export const EXTENSION_TRANSACTION_EVENT_API = `${CHECK_NFT_PATH}api/v2/extension/analyze/transaction-event`;
export const EXTENSION_USER_API = `${CHECK_NFT_PATH}api/v2/extension/session`;
export const EXTENSION_GAS_MONITORING_API = `${CHECK_NFT_PATH}api/v2/extension/session/gas-monitoring`;
export const EXTENSION_METADATA_API = `${CHECK_NFT_PATH}api/v1/extension/nft-metadata`;

const EXTENSION_CONSTANTS_BASE = `${CHECK_NFT_PATH}api/v2/extension/charts/`;
export const EXTENSION_COLLECTION_CHART_API = `${EXTENSION_CONSTANTS_BASE}collection/[collectionId]`;
export const EXTENSION_CONTRACT_CHART_API = `${EXTENSION_CONSTANTS_BASE}contracts/[contractAddress]`;
export const EXTENSION_ERC20_CHART_API = `${EXTENSION_CONSTANTS_BASE}erc20/[contractAddress]`;

export const EXTENSION_ACTION_API = `${CHECK_NFT_PATH}api/v1/extension/action`;
export const EXTENSION_TRANSACTION_API = `${CHECK_NFT_PATH}api/v2/extension/transaction/[id]`;
export const EXTENSION_ALERTS_API = `${CHECK_NFT_PATH}api/v2/extension/alerts`;
export const EXTENSION_COLLECTION_ANALYZE_API = `${CHECK_NFT_PATH}api/v2/extension/analyze/listing/collection`;

export const URL_ANALYZE_API = `${CHECK_NFT_PATH}api/v1/blockchain-project${ANALYSIS}`;
export const URL_WHITE_LIST = `${CHECK_NFT_PATH}api/v1/blockchain-project/whitelist`;
export const URL_ANALYZE_APPROVES = `${CHECK_NFT_PATH}api/v2/extension/analyze/approve`;
export const URL_CUSTOM_CHAIN_APPROVE = `${CHECK_NFT_PATH}api/v2/extension/analyze/custom-chain-approve`;
export const URL_SIGNATURE_REQUESTED = `${CHECK_NFT_PATH}api/v2/extension/signature-request`;
export const URL_PUBLIC_CONFIG = `${CHECK_NFT_PATH}api/v2/extension/public-config`;
