import cn from 'classnames';
import { memo } from 'react';

import { Count } from 'shared/components';
import { APP_CONFIG, REQUEST_OPEN_TRACING_DIAGRAM_PAGE } from 'shared/constants/common.constants';
import { COMMUNICATION_MESSAGES } from 'shared/constants/extension-webapp-communication.constants';
import { IconExternalLink } from 'shared/constants/icons.constants';
import { withTranslation } from 'shared/helpers/i18n.helpers';
import { TraceWithRisk } from 'shared/interfaces/fetch.interfaces';
import { Communicator } from 'shared/services/communicator/webapp-communicator';

import ContractCard from './ContractCard';
import { IconHierarchy } from './icons.constants';
import styles from './styles.module.scss';
import { ContractInfo } from '../../interfaces';
import Note from '../Note';

interface Props {
  mainContract: ContractInfo;
  relatedContracts?: ContractInfo[];
  trace?: TraceWithRisk[]
}

const ContractsInfo = ({ mainContract, relatedContracts, trace }: Props) => {

  const handleButtonClick = async () => {
    if (!APP_CONFIG.IS_WEBAPP) {
      const { sendRuntimeMessage } = await import('shared/helpers/browser-api.helpers');
      sendRuntimeMessage({ message: REQUEST_OPEN_TRACING_DIAGRAM_PAGE, trace });
    } else {
      const communicator = new Communicator('w3a-extension', 'w3a-webapp');
      communicator.sendMessageToWindow({ message: COMMUNICATION_MESSAGES.OPEN_TRACE, payload: trace });
    }
  };

  return (
    <div className={styles.wrap}>
      <div className={styles.info}>
        <div className={styles.title}>{withTranslation('Main contract')}</div>
        <div className={styles.description}>
          {APP_CONFIG.IS_WEBAPP
            ? 'This is the contract you will interact with directly, so its risks are crucial.'
            : withTranslation('main_contract_description')}
        </div>
      </div>
      <ContractCard title={withTranslation('Main contract')} className={styles.contractCard} withChart {...mainContract} />
      {Boolean(relatedContracts?.length) && (
        <div className={cn(styles.info, styles.relatedInfo)}>
          <div className={styles.title}>{withTranslation('Related contracts')} <Count data={relatedContracts?.length || 0} /></div>
          <div className={styles.description}>
            {APP_CONFIG.IS_WEBAPP ? `These contracts are related to the main contract in some way.
            Since you won&apos;t directly interact with them, their risks are not as significant.`
              : withTranslation('related_contract_description')}
          </div>
        </div>
      )}
      {relatedContracts?.map((contractInfo, index) => (
        <ContractCard
          title={`${withTranslation('Related contract')} #${index + 1}`}
          key={contractInfo.address}
          className={styles.contractCard}
          {...contractInfo}
        />
      ))}
      {trace && (
        <button className={styles.traceBtn} onClick={handleButtonClick}>
          <div className={styles.name}>
            {IconHierarchy}
            {withTranslation('Transaction simulation')}
          </div>
          <IconExternalLink className={styles.iconLink} />
        </button>
      )}
      <Note />
    </div>
  );
};

export default memo(ContractsInfo);
