import { RefObject, useEffect } from 'react';

import { ENTER_KEY } from 'shared/constants/common.constants';
import { getShadowRoot } from 'shared/helpers/extension.helpers';

const setScrollForMobile = (container: Element) => {
  let isDragging = false;
  let startY: number;
  let scrollTop: number;

  container.addEventListener('touchstart', (evt) => {
    const e = evt as TouchEvent;
    isDragging = true;
    startY = e.touches[0].pageY - container.clientTop;
    scrollTop = container.scrollTop;
  });

  container.addEventListener('touchmove', (evt) => {
    const e = evt as TouchEvent;
    e.preventDefault(); // Prevent the default touchmove behavior (e.g., page scroll)
    if (isDragging) {
      const deltaY = e.touches[0].pageY - container.clientTop - startY;
      container.scrollTop = scrollTop - deltaY;
    }
  });

  container.addEventListener('touchend', () => {
    isDragging = false;
  });
};

export const useExtensionScroll = (className: string): void => {

  useEffect(() => {
    const root = getShadowRoot();
    const [container] = (root || document).getElementsByClassName(className);

    container?.addEventListener('wheel' as keyof ElementEventMap, (evt) => {
      evt.preventDefault();
      container.scrollTop += (evt as WheelEvent).deltaY;
    });

    if (container) {
      setScrollForMobile(container);
    }
  }, [className]);
};

export const useSubmitByEnterButton = (submit: () => void): void => {
  useEffect(
    () => {
      const listener = (event: KeyboardEvent) => {
        if (event.code === ENTER_KEY) {
          event.preventDefault();
          submit();
        }
      };

      if (typeof document !== 'undefined') {
        document.addEventListener('keydown', listener);
      }

      return () => {
        if (typeof document !== 'undefined') {
          document.removeEventListener('keydown', listener);
        }
      };
    },
    [submit],
  );
};

export const useOnClickOutside = (ref: RefObject<HTMLElement>, handler: (e: MouseEvent | TouchEvent) => void): void => {
  useEffect(
    () => {
      const listener = (event: MouseEvent | TouchEvent) => {
        if (!ref.current || ref.current.contains(event.target as Node)) {
          return;
        }

        handler(event);
      };

      if (typeof document !== 'undefined') {
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
      }

      return () => {
        if (typeof document !== 'undefined') {
          document.removeEventListener('mousedown', listener);
          document.removeEventListener('touchstart', listener);
        }
      };
    },
    [ref, handler],
  );
};
