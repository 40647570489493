import cn from 'classnames';
import { FC } from 'react';

import { Image } from 'shared/components';
import WashTrading from 'shared/components/SwapInfo/WashTrading';
import { getERC20AmountWithSymbol, roundNumber } from 'shared/helpers/big-number.helpers';
import { getNftName, isEmpty } from 'shared/helpers/common.helpers';
import { INftDetails } from 'shared/modules/analyze/Scan/interfaces';

import styles from './styles.module.scss';

interface Props {
  data: INftDetails,
  price?: {
    usdPrice?: string | number | null,
    symbol?: string,
    cryptoPrice?: string | number | null,
  } | null,
  washTrading?: boolean,
  className?: string,
}

const Nft: FC<Props> = ({
  data, price, washTrading, className,
}) => (
  <div className={cn(styles.singleNft, className)}>
    <Image className={styles.nftImage} src={data.imageSrc} name={data.name} alt={data.name} />
    <div className={styles.nameWrap}>
      <div className={styles.top}>
        {getNftName(String(data.id), data.name)}
      </div>
      <div className={styles.collectionName}>{data.collectionName}</div>
      {washTrading && <WashTrading />}
    </div>
    {price && (
      <div className={styles.price}>
        {!isEmpty(price.cryptoPrice) && (
          <div className={styles.eth}>
            {getERC20AmountWithSymbol(price.cryptoPrice || 0, price.symbol)}
          </div>
        )}
        {!isEmpty(price.usdPrice) && (
          <div className={styles.usd}>
            ${roundNumber(price.usdPrice || 0, 2)}
          </div>
        )}
      </div>
    )}
  </div>
);

Nft.defaultProps = {
  price: null,
  washTrading: undefined,
  className: '',
};

export default Nft;
