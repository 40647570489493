import { FC } from 'react';

import { INftDetails } from 'shared/modules/analyze/Scan/interfaces';

import Nft from './Nft';
import NftsList from './NftsList';
import styles from './styles.module.scss';
import { INft } from '../../interfaces/common.interfaces';

interface Props {
  data: INftDetails[];
  usdPrice?: string | number;
  cryptoPrice?: string | number | null,
  symbol?: string,
}

const Nfts: FC<Props> = ({
  data, usdPrice, cryptoPrice, symbol,
}) => {
  const isSingle = data.length === 1;
  return (
    <div className={styles.wrap}>
      {isSingle
        ? (
          <Nft
            data={data[0] as unknown as INft}
            price={(usdPrice || cryptoPrice) ? { usdPrice, cryptoPrice, symbol } : undefined}
          />
        )
        : <NftsList data={data} price={(usdPrice || cryptoPrice) ? { usdPrice, cryptoPrice, symbol } : undefined} />}
    </div>
  );
};

export default Nfts;
