export const getShadowRoot = (): HTMLElement | null | undefined => {
  if (typeof document !== 'undefined') {
    return document.getElementById('web3-antivirus-host')?.shadowRoot?.getElementById('web3-antivirus');
  }

  return null;
};

export const getExtensionVersion = (): string => '0.15.4';

export const getValueFromSessionChromeStorage = <TValue = Record<string, any>>(): Promise<TValue | null> => Promise.resolve(null);
export const setValueFromSessionChromeStorage = async (): Promise<void> => Promise.resolve();
