import cn from 'classnames';
import { memo, ReactNode } from 'react';

import { IconArrowRight } from 'shared/constants/icons.constants';

import styles from './styles.module.scss';

interface PropsRow {
  info: ReactNode
  data: ReactNode
  onClick?: () => void
}

const Wrap = ({ children, onClick }: { children: ReactNode, onClick?: () => void }) => (onClick
  ? <button onClick={onClick} className={cn(styles.row, styles.button)}>{children}</button> : <div className={styles.row}>{children}</div>);

const Row = ({ info, data, onClick }: PropsRow) => (
  <Wrap onClick={onClick}>
    <div className={styles.info}>
      {info}
    </div>
    <div className={styles.data}>
      {data}
      {onClick && <IconArrowRight className={styles.rightIcon} />}
    </div>
  </Wrap>
);

export default memo(Row);
