import { FC } from 'react';

import Socials from 'shared/components/Socials';
import { TSocials } from 'shared/components/Socials/interfaces';

import Overview from './Overview';
import styles from './styles.module.scss';
import { IDEXPair, MintingData } from '../../Scan/interfaces';
import AdditionalInfo from '../../components/AdditionalInfo';

export interface Props {
  price?: number | string | null
  minting?: MintingData
  socials?: TSocials;
  dexPairs?: IDEXPair | null;
  address: string;
}

const Token: FC<Props> = ({
  price,
  minting,
  socials,
  dexPairs,
  address,
}) => (
  <div className={styles.wrapper}>
    <Socials data={socials} />
    <Overview address={address} price={price} circulatingSupply={minting?.cap} totalSupply={minting?.total} />
    {dexPairs && <AdditionalInfo dexPairs={[dexPairs]} />}
  </div>
);

Token.defaultProps = {
  price: undefined,
  minting: undefined,
};

export default Token;
