import cn from 'classnames';
import { memo } from 'react';

import BigValue from 'shared/components/BigValue';
import ChartWithData from 'shared/components/ChartWithData';
import { EXTENSION_ERC20_CHART_API } from 'shared/constants/check-nft.constants';
import { formatNumberWithComas } from 'shared/helpers/big-number.helpers';
import { isEmpty } from 'shared/helpers/common.helpers';
import { withTranslation } from 'shared/helpers/i18n.helpers';

import styles from './styles.module.scss';

interface Props {
  price?: number | string | null;
  transfersCount?: number | string | null;
  circulatingSupply?: number | string | null;
  totalSupply?: string | number | null,
  address: string;
}

const Overview = ({
  price, transfersCount, circulatingSupply, totalSupply, address,
}: Props) => {
  const hasInfo = !isEmpty(transfersCount) || !isEmpty(price) || !isEmpty(circulatingSupply) || !isEmpty(totalSupply);

  return (
    <div className={styles.wrap}>
      <div className={styles.title}>{withTranslation('Overview')}</div>
      {hasInfo && (
        <div className={styles.info}>
          {!isEmpty(price) && (
            <div className={cn(styles.item)}>
              <div className={styles.label}>{withTranslation('Price')}</div>
              <div className={styles.value}>${price && formatNumberWithComas(price, 8)}</div>
            </div>
          )}
          {!isEmpty(transfersCount) && (
            <div className={styles.item}>
              <div className={styles.label}>{withTranslation('Transfers')}</div>
              <div className={styles.value}>{transfersCount && formatNumberWithComas(transfersCount, 0)}</div>
            </div>
          )}
          {!isEmpty(circulatingSupply) && (
            <div className={styles.item}>
              <div className={styles.label}>{withTranslation('Circulating supply')}</div>
              <div className={styles.value}><BigValue value={String(circulatingSupply)} /></div>
            </div>
          )}
          {!isEmpty(totalSupply) && (
            <div className={styles.item}>
              <div className={styles.label}>{withTranslation('Total supply')}</div>
              <div className={styles.value}>
                <BigValue value={String(totalSupply)} />
              </div>
            </div>
          )}
        </div>
      )}
      <ChartWithData
        fieldsForChartMap={{ bar: 'transfers', line: 'amount' }}
        apiUrl={EXTENSION_ERC20_CHART_API.replace('[contractAddress]', String(address))}
        title={withTranslation('Token trading activity')}
        barsLabel={withTranslation('Transfers')}
        linesLabel={withTranslation('Transfer amount')}
      />
    </div>
  );
};

export default memo(Overview);
