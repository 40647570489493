/* eslint-disable import/no-restricted-paths */
import { APP_CONFIG } from 'shared/constants/common.constants';
import { executeGetRequest, executeSendRequest } from 'shared/helpers/axios.helper';

const { useUserToken } = APP_CONFIG.IS_WEBAPP
  ? require('webapp/src/contexts/user.context') : require('extension/context/user-token.context');

export const useRequestWithToken = (): {
  get: typeof get,
  post: typeof post,
  put: typeof put,
  hasToken: boolean,
} => {
  const token: string = useUserToken();
  const headers = { Authorization: `Bearer ${token || ''}` };

  const get = executeGetRequest('get', { headers });
  const post = executeSendRequest('post', { headers });
  const put = executeSendRequest('put', { headers });

  return {
    get, post, hasToken: Boolean(token), put,
  };
};
