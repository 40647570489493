import cn from 'classnames';
import pluralize from 'pluralize';
import { memo } from 'react';

import { APP_CONFIG, REQUEST_OPEN_TRACING_DIAGRAM_PAGE } from 'shared/constants/common.constants';
import { CUSTOM_CHAINS } from 'shared/constants/custom-chain.constants';
import { COMMUNICATION_MESSAGES } from 'shared/constants/extension-webapp-communication.constants';
import { IconExternalLink } from 'shared/constants/icons.constants';
import { withTranslation } from 'shared/helpers/i18n.helpers';
import { TraceWithRisk } from 'shared/interfaces/fetch.interfaces';
import { Communicator } from 'shared/services/communicator/webapp-communicator';

import styles from './styles.module.scss';

interface Props {
  data: TraceWithRisk[];
  chain?: CUSTOM_CHAINS;
}

const Trace = ({ data, chain }: Props) => {
  // medium or higher
  const detectsCount = (data || []).filter((item) => item.totalRisk >= 2).length;

  const handleButtonClick = async () => {
    if (!APP_CONFIG.IS_WEBAPP) {
      const { sendRuntimeMessage } = await import('shared/helpers/browser-api.helpers');
      sendRuntimeMessage({ message: REQUEST_OPEN_TRACING_DIAGRAM_PAGE, trace: data, chain });
    } else {
      const communicator = new Communicator('w3a-extension', 'w3a-webapp');
      communicator.sendMessageToWindow({ message: COMMUNICATION_MESSAGES.OPEN_TRACE, payload: data });
    }
  };

  return (
    <button className={cn(styles.traceBtn)} onClick={handleButtonClick}>
      <span className={styles.text}>{withTranslation('Transaction simulation')}</span>
      <div className={styles.risksWrap}>
        <div className={styles.count}>{detectsCount} {withTranslation(pluralize('detect', detectsCount))}</div>
        <IconExternalLink className={styles.iconExternal} />
      </div>
    </button>
  );
};

export default memo(Trace);
