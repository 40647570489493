/* eslint-disable max-len */
export const IconHierarchy = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M3.33325 11.1667C3.05992 11.1667 2.83325 10.94 2.83325 10.6667V6C2.83325 5.72667 3.05992 5.5 3.33325 5.5C3.60659 5.5 3.83325 5.72667 3.83325 6V10.6667C3.83325 10.94 3.60659 11.1667 3.33325 11.1667Z" fill="#292D32" />
    <path d="M3.49992 6.16683C2.02659 6.16683 0.833252 4.9735 0.833252 3.50016C0.833252 2.02683 2.02659 0.833496 3.49992 0.833496C4.97325 0.833496 6.16658 2.02683 6.16658 3.50016C6.16658 4.9735 4.97325 6.16683 3.49992 6.16683ZM3.49992 1.8335C2.57992 1.8335 1.83325 2.58016 1.83325 3.50016C1.83325 4.42016 2.57992 5.16683 3.49992 5.16683C4.41992 5.16683 5.16658 4.42016 5.16658 3.50016C5.16658 2.58016 4.41992 1.8335 3.49992 1.8335Z" fill="#292D32" />
    <path d="M3.49992 1.8335C2.57992 1.8335 1.83325 2.58016 1.83325 3.50016C1.83325 4.42016 2.57992 5.16683 3.49992 5.16683C4.41992 5.16683 5.16658 4.42016 5.16658 3.50016C5.16658 2.58016 4.41992 1.8335 3.49992 1.8335Z" fill="#292D32" />
    <path d="M3.33325 15.1665C1.95325 15.1665 0.833252 14.0465 0.833252 12.6665C0.833252 11.2865 1.95325 10.1665 3.33325 10.1665C4.71325 10.1665 5.83325 11.2865 5.83325 12.6665C5.83325 14.0465 4.71325 15.1665 3.33325 15.1665ZM3.33325 11.1665C2.50659 11.1665 1.83325 11.8398 1.83325 12.6665C1.83325 13.4932 2.50659 14.1665 3.33325 14.1665C4.15992 14.1665 4.83325 13.4932 4.83325 12.6665C4.83325 11.8398 4.15992 11.1665 3.33325 11.1665Z" fill="#292D32" />
    <path d="M3.33325 11.1665C2.50659 11.1665 1.83325 11.8398 1.83325 12.6665C1.83325 13.4932 2.50659 14.1665 3.33325 14.1665C4.15992 14.1665 4.83325 13.4932 4.83325 12.6665C4.83325 11.8398 4.15992 11.1665 3.33325 11.1665Z" fill="#292D32" />
    <path d="M12.6667 15.1665C11.2867 15.1665 10.1667 14.0465 10.1667 12.6665C10.1667 11.2865 11.2867 10.1665 12.6667 10.1665C14.0467 10.1665 15.1667 11.2865 15.1667 12.6665C15.1667 14.0465 14.0467 15.1665 12.6667 15.1665ZM12.6667 11.1665C11.8401 11.1665 11.1667 11.8398 11.1667 12.6665C11.1667 13.4932 11.8401 14.1665 12.6667 14.1665C13.4934 14.1665 14.1667 13.4932 14.1667 12.6665C14.1667 11.8398 13.4934 11.1665 12.6667 11.1665Z" fill="#292D32" />
    <path d="M12.6667 11.1665C11.8401 11.1665 11.1667 11.8398 11.1667 12.6665C11.1667 13.4932 11.8401 14.1665 12.6667 14.1665C13.4934 14.1665 14.1667 13.4932 14.1667 12.6665C14.1667 11.8398 13.4934 11.1665 12.6667 11.1665Z" fill="#292D32" />
    <path d="M12.1133 11.1933C11.9067 11.1933 11.7133 11.0667 11.64 10.86C11.1533 9.46 9.83332 8.51999 8.34666 8.51999C8.33999 8.51999 8.33999 8.51999 8.33332 8.51999L6.04666 8.52667C6.03999 8.52667 6.03999 8.52667 6.03332 8.52667C4.57332 8.52667 3.29999 7.54 2.93332 6.12666C2.86666 5.86 3.02666 5.58666 3.29332 5.51999C3.55999 5.45333 3.83332 5.61333 3.89999 5.88C4.15332 6.85333 5.02666 7.53332 6.03332 7.53332H6.03999L8.32666 7.52667C8.33332 7.52667 8.33999 7.52667 8.34666 7.52667C10.2533 7.52667 11.9533 8.73333 12.58 10.54C12.6733 10.8 12.5333 11.0867 12.2733 11.1733C12.22 11.18 12.1667 11.1933 12.1133 11.1933Z" fill="#292D32" />
  </svg>
);
