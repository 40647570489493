import cn from 'classnames';
import { FC } from 'react';

import { Image } from 'shared/components';
import { getERC20AmountWithSymbol, getUSDPriceWithFallback } from 'shared/helpers/big-number.helpers';
import { isEmpty } from 'shared/helpers/common.helpers';
import { INftDetails } from 'shared/modules/analyze/Scan/interfaces';

import styles from './styles.module.scss';

interface Props {
  data: INftDetails[];
  price?: {
    usdPrice?: string | number | null,
    symbol?: string,
    cryptoPrice?: string | number | null,
  } | null}

const DISPLAYED_COUNT = 9;

const NftsList: FC<Props> = ({ data, price }) => {
  const displayedNfts = data.slice(0, DISPLAYED_COUNT);
  const hidedCount = data.length - DISPLAYED_COUNT;

  return (
    <div className={styles.nftsList}>
      <div className={styles.main}>
        <div className={styles.list}>
          {displayedNfts.map(({ imageSrc, name }, index) => (
            <Image
              key={imageSrc}
              className={cn(
                styles.nftImage, styles.item, index === 0 ? styles.firstImage : '',
              )}
              name={name}
              src={imageSrc}
              alt={name}
            />
          ))}
          {hidedCount > 0 && <div className={cn(styles.count, styles.item)}>+{hidedCount}</div>}
        </div>
        <div className={styles.collectionName}>{data[0].collectionName}</div>
      </div>
      {price && (
        <div className={styles.price}>
          {!isEmpty(price.cryptoPrice) && (
            <div className={styles.eth}>
              {getERC20AmountWithSymbol(price.cryptoPrice || 0, price.symbol)}
            </div>
          )}
          {!isEmpty(price.usdPrice) && (
            <div className={styles.usd}>
              {getUSDPriceWithFallback(price.usdPrice || 0, 2)}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default NftsList;
