import cn from 'classnames';
import { memo } from 'react';
import { Table, TableCellProps } from 'semantic-ui-react';

import styles from '../styles.module.scss';

type Props = TableCellProps & {
  className?: string
  pointer?: boolean,
}

const TableCell = ({
  className, children, pointer, ...props
}: Props) => (
  <Table.Cell
    verticalAlign="middle"
    className={cn(styles.cell,
      { [styles.pointer]: pointer },
      className)}
    {...props}
  >
    {children}
  </Table.Cell>
);

TableCell.defaultProps = {
  className: '',
  pointer: false,
};

export default memo(TableCell);
