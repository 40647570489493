/* eslint-disable @typescript-eslint/ban-ts-comment */
import cn from 'classnames';
import { memo } from 'react';
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts';

import { DATE_FORMAT } from 'shared/constants/global.constants';
import { THEME } from 'shared/constants/theme.constants';
import {
  abbreviateNumber, addPercentToNumber, formatNumberWithComas,
} from 'shared/helpers/big-number.helpers';
import { getDateWithFormat } from 'shared/helpers/time.helpers';
import { useTheme } from 'shared/hooks/theme.hooks';

import { ChartNoData } from './ChartNoData';
import Labels from './Labels';
import { ChartData } from './interfaces';
import styles from './styles.module.scss';
import { Skeleton } from '../Skeleton';

const CustomTooltip = ({
  active, payload, lineLabel, barsLabel,
}: { active: boolean, payload?: { value: string, payload: {x: string} }[], lineLabel: string, barsLabel: string }) => {
  if (active && payload && payload.length) {
    const barsValue = payload[0]?.value;
    const lineValue = payload[1]?.value;
    const dateMilliseconds = payload[0]?.payload?.x;

    return (
      <div className={styles.tooltip}>
        <div className={styles.row}>
          {barsLabel}: {formatNumberWithComas(barsValue || 0, 0)}
        </div>
        <div className={styles.row}>
          {lineLabel}: {formatNumberWithComas(lineValue || 0, 0)}
        </div>
        {dateMilliseconds && (
          <div className={cn(styles.row, styles.date)}>
            {getDateWithFormat(new Date(Number(dateMilliseconds)), DATE_FORMAT.MONTH_DAY_YEAR)}
          </div>
        )}
      </div>
    );
  }

  return null;
};

const formatXAxis = (date: string) => getDateWithFormat(new Date(Number(date)), DATE_FORMAT.MONTH_DAY) || '';
const TICK_ABBREVIATION_FROM = 10 ** 3;

interface Props {
  barsLabel: string;
  linesLabel: string;
  linesTickPrefix?: string;
  data?: ChartData;
  isLoading: boolean;
}

const getMaxTick = (maxDomain: number): number => {
  if (maxDomain < 10) {
    return maxDomain + 1;
  }
  return addPercentToNumber(maxDomain, 10);
};

const BarLineChart = ({
  barsLabel, linesLabel, data, isLoading, linesTickPrefix,
}: Props) => {
  const { theme } = useTheme();
  const noData = !isLoading && !data?.length;
  const hasLineData = data?.some(({ line }) => line);
  const maxBarDomain = Math.max(...(data || []).map(({ bar }) => bar));
  const maxBarTick = maxBarDomain ? getMaxTick(maxBarDomain) : undefined;
  const maxLineDomain = Math.max(...(data || []).map(({ line }) => line));
  const maxLineTick = maxLineDomain ? getMaxTick(maxLineDomain) : undefined;

  return (
    <>
      <Labels barsLabel={barsLabel} linesLabel={linesLabel} />
      <div className={styles.wrap}>
        {noData && <ChartNoData />}
        {!noData && isLoading && <Skeleton className={styles.loader} />}
        {!noData && !isLoading && (
          <ResponsiveContainer width="100%" height={150}>
            <ComposedChart
              data={data}
              barCategoryGap={-1}
            >
              <CartesianGrid
                vertical={false}
                horizontalCoordinatesGenerator={(props) => (props.height ? [0, 56] : [])}
                strokeWidth={0.5}
                stroke={theme === THEME.DARK ? '#3D3D3D' : 'rgba(0, 0, 0, 0.20)'}
              />
              <XAxis
                padding={{ left: 0, right: 0 }}
                name="x"
                tickFormatter={formatXAxis}
                dataKey="x"
                tickLine={false}
                axisLine
                interval="preserveStartEnd"
                minTickGap={20}
              />
              <YAxis
                dx={-10}
                yAxisId="barY"
                tickLine={false}
                axisLine={false}
                dataKey="bar"
                mirror
                ticks={maxBarTick ? [0, maxBarTick] : undefined}
                tickCount={1}
                tickFormatter={(value) => String(abbreviateNumber(value || 0, TICK_ABBREVIATION_FROM))}
                domain={maxBarTick ? [0, maxBarTick] : undefined}
              />
              <YAxis
                dx={10}
                yAxisId="lineY"
                axisLine={false}
                dataKey="line"
                orientation="right"
                mirror
                ticks={maxLineTick ? [0, maxLineTick] : undefined}
                domain={maxLineTick ? [0, maxLineTick] : undefined}
                tickCount={hasLineData ? 1 : undefined}
                tickFormatter={(value) => `${linesTickPrefix || ''}${String(abbreviateNumber(value || 0, TICK_ABBREVIATION_FROM))}`}
                className={styles.rightYAxis}
                tick={hasLineData}
              />
              {/* @ts-ignore */}
              <Tooltip cursor={false} content={<CustomTooltip barsLabel={barsLabel} lineLabel={linesLabel} />} />
              <Bar
                yAxisId="barY"
                dataKey="bar"
                fill={theme === THEME.DARK ? 'rgba(255, 255, 255, 0.15)' : 'rgba(0, 0, 0, 0.10)'}
                activeBar={{ fill: theme === THEME.DARK ? 'rgba(255, 255, 255, 0.4)' : 'rgba(0, 0, 0, 0.25)' }}
              />
              <Line yAxisId="lineY" activeDot={false} dot={false} type="linear" dataKey="line" stroke="#00A867" />
            </ComposedChart>
          </ResponsiveContainer>
        )}
      </div>
    </>
  );
};

export default memo(BarLineChart);
