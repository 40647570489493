import cn from 'classnames';
import { memo } from 'react';
import { Pagination as SUPagination } from 'semantic-ui-react';
import { PaginationProps } from 'semantic-ui-react/dist/commonjs/addons/Pagination/Pagination';

import { ArrowDownIcon } from 'shared/constants/icons.constants';

import styles from './styles.module.scss';

type Props = PaginationProps & {
  className?: string
  wrapClassName?: string
}

const Pagination = ({
  wrapClassName, activePage, totalPages, ...props
}: Props) => (
  <div
    className={cn(
      styles.wrapper,
      wrapClassName,
      { [styles.isFirstPage]: activePage === 1, [styles.isLastPage]: activePage === totalPages },
    )}
  >
    <SUPagination
      firstItem={null}
      lastItem={null}
      prevItem={{
        content: <ArrowDownIcon
          className={cn(styles.icon, { [styles.disabled]: activePage === 1 })}
        />,
        icon: true,
      }}
      nextItem={{
        content: <ArrowDownIcon
          className={cn(styles.icon, styles.rightIcon, { [styles.disabled]: activePage === totalPages })}
        />,
        icon: true,
      }}
      activePage={activePage}
      totalPages={totalPages}
      {...props}
    />
  </div>

);

Pagination.default = {
  className: '',
  wrapClassName: '',
};

export default memo(Pagination);
