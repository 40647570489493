import cn from 'classnames';
import { memo } from 'react';
import { Table as SUTable, TableProps } from 'semantic-ui-react';

import styles from './styles.module.scss';

type Props = TableProps & {
  className?: string
  wrapClassName?: string
}

const Table = ({
  children, wrapClassName, ...props
}: Props) => (
  <div className={cn(styles.root, wrapClassName)}>
    <SUTable
      {...props}
    >
      {children}
    </SUTable>
  </div>
);

Table.defaultProps = {
  className: '',
  wrapClassName: '',
};

export default memo(Table);
