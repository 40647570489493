import { APP_CONFIG } from 'shared/constants/common.constants';
import { withTranslation } from 'shared/helpers/i18n.helpers';

export enum FAIR_LEVEL {
  HIGH = 'Fair level high',
  MEDIUM = 'Fair level medium',
  LOW = 'Fair level low',
}

export const FAIR_LEVEL_LABEL = {
  [FAIR_LEVEL.HIGH]: 'high',
  [FAIR_LEVEL.MEDIUM]: 'medium',
  [FAIR_LEVEL.LOW]: 'low',
};

export enum TYPE_TOKEN_ACTION {
  MAKE_OFFER = 'Make an offer',
  PLACE_A_BID = 'Place a bid',
}

export const FAIR_LEVEL_TOOLTIPS = {
  [FAIR_LEVEL.HIGH]: APP_CONFIG.IS_WEBAPP ? 'fair level up to 15%' : withTranslation('fair_level_high'),
  [FAIR_LEVEL.MEDIUM]: APP_CONFIG.IS_WEBAPP ? 'fair level from 15 to 30%' : withTranslation('fair_level_medium'),
  [FAIR_LEVEL.LOW]: APP_CONFIG.IS_WEBAPP ? 'fair level over 30%' : withTranslation('fair_level_low'),
};
