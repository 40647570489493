import { APP_CONFIG } from 'shared/constants/common.constants';
import { withTranslation } from 'shared/helpers/i18n.helpers';

/* eslint-disable max-len */
export enum TECHNICAL_RISKS {
  METAMORPHIC = 'METAMORPHIC',
  OVERSUPPLY_MINTING = 'OVERSUPPLY_MINTING',
  TRANSFER_AMOUNT_LIMIT = 'TRANSFER_AMOUNT_LIMIT',
  WASH_TRADING = 'WASH_TRADING',
  BURNABLE_TOKEN = 'BURNABLE_TOKEN',
  SCAM_ADDRESS = 'SCAM_ADDRESS',
  EMPTY_PAID_CONTRACT = 'EMPTY_PAID_CONTRACT',
  HONEYPOT = 'HONEYPOT',
  SELF_DESTRUCTION = 'SELF_DESTRUCTION',
  OWNER_PERMISSIONS = 'OWNER_PERMISSIONS',
  UNSANCTIONED_TOKEN_BURN = 'UNSANCTIONED_TOKEN_BURN',
  IMPOSSIBLE_APPROVE = 'IMPOSSIBLE_APPROVE',
  OWNER_APPROVE = 'OWNER_APPROVE',
  SCAM_NAME = 'SCAM_NAME',
  APPROVE_RESTRICTION = 'APPROVE_RESTRICTION',
  TRANSFER_RESTRICTION = 'TRANSFER_RESTRICTION',
  CONTRACT_METHODS_RESTRICTION = 'CONTRACT_METHODS_RESTRICTION',
  NO_DEX_PAIRS = 'NO_DEX_PAIRS',
  VOLATILE_TOKEN_LIQUIDITY = 'VOLATILE_TOKEN_LIQUIDITY',
  UNVERIFIED_CONTRACT = 'UNVERIFIED_CONTRACT',
}

const { IS_WEBAPP } = APP_CONFIG;

export const TECHNICAL_RISKS_LABELS = {
  [TECHNICAL_RISKS.OVERSUPPLY_MINTING]: 'Oversupply minting',
  [TECHNICAL_RISKS.TRANSFER_AMOUNT_LIMIT]: 'Anti-whale restrictions',
  [TECHNICAL_RISKS.WASH_TRADING]: 'Wash trading',
  [TECHNICAL_RISKS.BURNABLE_TOKEN]: 'Burnable token',
  [TECHNICAL_RISKS.SCAM_ADDRESS]: 'Scam address',
  [TECHNICAL_RISKS.EMPTY_PAID_CONTRACT]: 'Suspected phishing contract',
  [TECHNICAL_RISKS.HONEYPOT]: 'Honeypot scam',
  [TECHNICAL_RISKS.SELF_DESTRUCTION]: 'Self-destruction',
  [TECHNICAL_RISKS.OWNER_PERMISSIONS]: 'Unapproved asset transfer',
  [TECHNICAL_RISKS.UNSANCTIONED_TOKEN_BURN]: 'Unsanctioned token burn',
  [TECHNICAL_RISKS.IMPOSSIBLE_APPROVE]: 'Blocked token approval',
  [TECHNICAL_RISKS.OWNER_APPROVE]: 'Unsanctioned token approval',
  [TECHNICAL_RISKS.SCAM_NAME]: 'Scam token',
  [TECHNICAL_RISKS.METAMORPHIC]: 'Metamorphic contract',
  [TECHNICAL_RISKS.TRANSFER_RESTRICTION]: 'Transfer restriction',
  [TECHNICAL_RISKS.APPROVE_RESTRICTION]: 'Approve restriction',
  [TECHNICAL_RISKS.CONTRACT_METHODS_RESTRICTION]: 'Contract methods restriction',
  [TECHNICAL_RISKS.NO_DEX_PAIRS]: 'No DEX pair',
  [TECHNICAL_RISKS.VOLATILE_TOKEN_LIQUIDITY]: 'Volatile token liquidity',
  [TECHNICAL_RISKS.UNVERIFIED_CONTRACT]: 'Unverified contract',
};

const getTranslationForTechnicalRisks = (key: string): string => withTranslation(`TECHNICAL_RISK_${key}`);

export const TECHNICAL_RISKS_INFO = {
  [TECHNICAL_RISKS.OVERSUPPLY_MINTING]: IS_WEBAPP ? 'This contract allows for token minting beyond the maximum supply limit. It can negatively affect the token price.' : withTranslation(TECHNICAL_RISKS.OVERSUPPLY_MINTING),
  [TECHNICAL_RISKS.TRANSFER_AMOUNT_LIMIT]: IS_WEBAPP ? 'The contract restricts the number of token transactions if there is an excessive amount of specific tokens on your balance.' : getTranslationForTechnicalRisks(TECHNICAL_RISKS.TRANSFER_AMOUNT_LIMIT),
  [TECHNICAL_RISKS.WASH_TRADING]: IS_WEBAPP ? 'The price and demand for this asset may be artificially inflated.' : getTranslationForTechnicalRisks(TECHNICAL_RISKS.WASH_TRADING),
  [TECHNICAL_RISKS.BURNABLE_TOKEN]: IS_WEBAPP ? 'This contract has the ability to burn tokens, which can affect their price.' : getTranslationForTechnicalRisks(TECHNICAL_RISKS.BURNABLE_TOKEN),
  [TECHNICAL_RISKS.SCAM_ADDRESS]: IS_WEBAPP ? 'This is a scam address that imitates a 0x0..00 address and can carry out a variety of harmful actions.' : getTranslationForTechnicalRisks(TECHNICAL_RISKS.SCAM_ADDRESS),
  [TECHNICAL_RISKS.EMPTY_PAID_CONTRACT]: IS_WEBAPP ? 'This is a phishing contract that will send your ETH tokens to scammers.' : getTranslationForTechnicalRisks(TECHNICAL_RISKS.EMPTY_PAID_CONTRACT),
  [TECHNICAL_RISKS.HONEYPOT]: IS_WEBAPP ? 'This is a scam token. You will not be able to transfer or sell it.' : getTranslationForTechnicalRisks(TECHNICAL_RISKS.HONEYPOT),
  [TECHNICAL_RISKS.SELF_DESTRUCTION]: IS_WEBAPP ? 'This function allows the contract owner to destroy the contract, which could result in unpredictable outcomes.' : getTranslationForTechnicalRisks(TECHNICAL_RISKS.SELF_DESTRUCTION),
  [TECHNICAL_RISKS.OWNER_PERMISSIONS]: IS_WEBAPP ? 'Despite you being the token holder after the purchase, the contract owner will be able to transfer it without your consent.' : getTranslationForTechnicalRisks(TECHNICAL_RISKS.OWNER_PERMISSIONS),
  [TECHNICAL_RISKS.UNSANCTIONED_TOKEN_BURN]: IS_WEBAPP ? 'Despite you being the token holder after the purchase, the contract owner will be able to burn it without your consent.' : getTranslationForTechnicalRisks(TECHNICAL_RISKS.UNSANCTIONED_TOKEN_BURN),
  [TECHNICAL_RISKS.IMPOSSIBLE_APPROVE]: IS_WEBAPP ? 'The contract won\'t let you grant token approvals, thus preventing you from selling tokens on DEXs.' : getTranslationForTechnicalRisks(TECHNICAL_RISKS.IMPOSSIBLE_APPROVE),
  [TECHNICAL_RISKS.OWNER_APPROVE]: IS_WEBAPP ? 'Despite you being the token holder after the purchase, the contract owner will be able to grant approvals to it without your consent.' : getTranslationForTechnicalRisks(TECHNICAL_RISKS.OWNER_APPROVE),
  [TECHNICAL_RISKS.SCAM_NAME]: IS_WEBAPP ? 'This is a possible scam token exploiting the original token\'s address in its name.' : getTranslationForTechnicalRisks(TECHNICAL_RISKS.SCAM_NAME),
  [TECHNICAL_RISKS.METAMORPHIC]: IS_WEBAPP ? 'The contract owner can change the contract\'s code, potentially introducing malicious logic.' : getTranslationForTechnicalRisks(TECHNICAL_RISKS.METAMORPHIC),
  [TECHNICAL_RISKS.TRANSFER_RESTRICTION]: IS_WEBAPP ? 'The contract owner can restrict your ability to make token transfers.' : getTranslationForTechnicalRisks(TECHNICAL_RISKS.TRANSFER_RESTRICTION),
  [TECHNICAL_RISKS.APPROVE_RESTRICTION]: IS_WEBAPP ? 'The contract owner can restrict your ability to grant token approvals.' : getTranslationForTechnicalRisks(TECHNICAL_RISKS.APPROVE_RESTRICTION),
  [TECHNICAL_RISKS.CONTRACT_METHODS_RESTRICTION]: IS_WEBAPP ? 'The contract owner can restrict some methods of this contract (other than transfers and approvals).' : getTranslationForTechnicalRisks(TECHNICAL_RISKS.CONTRACT_METHODS_RESTRICTION),
  [TECHNICAL_RISKS.NO_DEX_PAIRS]: IS_WEBAPP ? 'This token has no DEX pair, so it is impossible to sell it.' : getTranslationForTechnicalRisks(TECHNICAL_RISKS.NO_DEX_PAIRS),
  [TECHNICAL_RISKS.VOLATILE_TOKEN_LIQUIDITY]: IS_WEBAPP ? 'The liquidity of this token\'s DEX pair is very weak, which may have a negative impact on the token price when selling.' : getTranslationForTechnicalRisks(TECHNICAL_RISKS.VOLATILE_TOKEN_LIQUIDITY),
  [TECHNICAL_RISKS.UNVERIFIED_CONTRACT]: IS_WEBAPP ? 'This contact is not open-source, so its code can\'t be verified. It can contain various unknown mechanisms, including malicious logic.' : getTranslationForTechnicalRisks(TECHNICAL_RISKS.UNVERIFIED_CONTRACT),
};
