import cn from 'classnames';
import {
  FC, useEffect, useRef, useState,
} from 'react';

import stubIcon from 'shared/assets/images/collection/nft-stub.svg';
import Skeleton from 'shared/components/Skeleton/Skeleton';
import { getImageUrl } from 'shared/helpers/image.helpers';

import styles from './styles.module.scss';

type Props = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> &
{ stub?: string, wrapClassName?: string, imgClassName?: string }

const TokenImage: FC<Props> = ({
  alt,
  src,
  stub = stubIcon,
  className = '',
  wrapClassName = '',
  imgClassName = '',
  ...props
}) => {
  const [hasError, setHasError] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const ref = useRef<HTMLImageElement>(null);

  const onLoad = () => setLoaded(true);

  useEffect(() => {
    if (ref.current && ref.current.complete) {
      onLoad();
    }
  });

  const imageSrc = (hasError || !src) ? getImageUrl(stub) : src;

  useEffect(() => {
    setHasError(false);
  }, [src]);

  return (
    <div className={cn(styles.wrap, className, wrapClassName)}>
      {!loaded && <Skeleton className={styles.skeleton} />}
      <img
        alt={alt}
        src={imageSrc}
        onError={() => setHasError(true)}
        onLoad={onLoad}
        className={cn(className, imgClassName)}
        {...props}
      />
    </div>
  );
};

TokenImage.defaultProps = {
  stub: stubIcon,
};

export default TokenImage;
