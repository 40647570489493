import { FC, useMemo, memo } from 'react';

import alertIcon from 'shared/assets/images/icons/blacklist-circle.svg';
import verifiedIcon from 'shared/assets/images/icons/verified-shield.svg';
import BigValue from 'shared/components/BigValue';
import Risks from 'shared/components/Risks';
import Tabs from 'shared/components/Tabs';
import WrapHeader from 'shared/components/assetHeader/WrapHeader';
import { CUSTOM_CHAINS } from 'shared/constants/custom-chain.constants';
import { DATE_FORMAT } from 'shared/constants/global.constants';
import {
  TOKEN_TYPES, TOKEN_TYPES_LABELS,
} from 'shared/constants/token.constants';
import { withTranslation } from 'shared/helpers/i18n.helpers';
import { getImageUrl } from 'shared/helpers/image.helpers';
import { getDateWithFormat } from 'shared/helpers/time.helpers';
import { Tab } from 'shared/interfaces/common.interfaces';
import { TraceWithRisk } from 'shared/interfaces/fetch.interfaces';

import Token from './Token';
import styles from './styles.module.scss';
import ContractsInfo from '../Scan/components/ContractsInfo';
import { IDEXPair, TokenData } from '../Scan/interfaces';

interface Props {
  data: TokenData;
  dexPairs?: IDEXPair | null;
  trace?: TraceWithRisk[],
  chain?: CUSTOM_CHAINS,
}

enum TABS_TEXTS {
  RISKS = 'DETECTS',
  CONTRACTS = 'CONTRACTS',
  TOKEN = 'TOKEN',
}

const TokenInfo: FC<Props> = ({
  data, dexPairs, trace, chain,
}) => {
  const { isAddressVerified } = data.info;
  const TABS: Tab[] = useMemo(
    () => {
      const isCustomChain = Boolean(chain);

      return ([
        {
          text: TABS_TEXTS.RISKS,
          Component: () => (
            <Risks
              warnings={data.info.warnings}
              skipEmptyTechnicalRisks={isCustomChain}
              {...data.risks}
            />
          ),
        },
        ...(isCustomChain ? [] : [{
          text: TABS_TEXTS.CONTRACTS,
          Component: () => (
            <ContractsInfo
              mainContract={data.info.contractsInfo.main}
              relatedContracts={data.info.contractsInfo.related}
              trace={trace}
            />
          ),
        }]),
        ...(isCustomChain ? [] : [{
          text: TABS_TEXTS.TOKEN,
          hasRisk: false,
          Component: () => (
            <Token
              price={data.info.priceUSD}
              minting={data.info.minting}
              socials={data.info.socials}
              dexPairs={dexPairs}
              address={data.info.address}
            />
          ),
        }]),
      ]);
    }, [data, isAddressVerified],
  );

  const getAdditionalInfo = () => {
    const { marketCapUSD, createdAt } = data.info;

    if (!createdAt && !marketCapUSD) return undefined;
    const additionalInfo = [];

    if (marketCapUSD) {
      additionalInfo.push({
        name: withTranslation('Mkt cap'),
        value: <>$<BigValue value={marketCapUSD} maxValue={10 ** 3} className={styles.bigValue} postfix="" /></>,
      });
    }

    if (createdAt) {
      additionalInfo.push({
        name: withTranslation('Deployed'),
        value: getDateWithFormat(createdAt, DATE_FORMAT.MONTH_YEAR) as string,
      });
    }

    return additionalInfo;
  };

  const getLabels = () => {
    const {
      isProxy, isVerified, audits, hasRisk,
    } = data.info;
    const labels: {
      text: string;
      isWarn?: boolean;
    }[] = [{ text: TOKEN_TYPES_LABELS[chain === CUSTOM_CHAINS.BNB ? TOKEN_TYPES.BEP20 : TOKEN_TYPES.ERC20] }];
    if (isProxy) labels.push({ text: 'Proxy' });
    if (isVerified) labels.push({ text: withTranslation('W3A whitelist') });
    if (hasRisk) labels.push({ text: withTranslation('W3A blacklist'), isWarn: true });
    if (audits && Object.values(audits).length > 0) labels.push({ text: withTranslation('Audit passed') });

    return labels.length ? labels : undefined;
  };

  return (
    <>
      <WrapHeader
        name={data.info.name}
        isAddressVerified={isAddressVerified}
        labels={getLabels()}
        imageUrl={data.info.imageUrl}
        additionalInfo={getAdditionalInfo()}
        icon={(data.info.hasRisk || data.info.isVerified) ? getImageUrl(data.info.hasRisk ? alertIcon : verifiedIcon) : ''}
        symbol={data.info.symbol}
        address={data.info.address}
        chain={chain}
      />
      <Tabs data={TABS} />
    </>
  );
};

export default memo(TokenInfo);
