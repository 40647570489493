import {
  createContext, FC, PropsWithChildren, useContext, useEffect, useState,
} from 'react';
import { COMMUNICATION_MESSAGES } from 'shared/constants/extension-webapp-communication.constants';

import { useCommunicator } from './communicator.context';

type UserContextType = {
  token: string | null;
}

const initialContextState = {
  token: null,
};

export const UserContext = createContext<UserContextType>(initialContextState);

export const useUser = (): UserContextType => useContext(UserContext);
export const useUserToken = (): string | null => useUser().token;

export const useUserData = (): {
  token: string | null;
} => {
  const [userToken, setUserToken] = useState<string | null>(null);
  const communicator = useCommunicator();

  useEffect(() => {
    communicator.addMessageHandler(COMMUNICATION_MESSAGES.PING, () => {
      communicator.sendMessageToWindow({ message: COMMUNICATION_MESSAGES.GET_USER_TOKEN });
    });

    communicator.addMessageHandler(COMMUNICATION_MESSAGES.SEND_USER_TOKEN, (messageData) => {
      setUserToken(messageData || []);
    });

    communicator.sendMessageToWindow({ message: COMMUNICATION_MESSAGES.PING });

  }, [setUserToken]);

  return { token: userToken };
};

export const UserContextProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { token } = useUserData();
  return (
    <UserContext.Provider value={{ token }}>
      {children}
    </UserContext.Provider>
  );
};
