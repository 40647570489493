import cn from 'classnames';
import { FC, memo } from 'react';
import { Popup as SPopup, StrictPopupProps } from 'semantic-ui-react';
import 'semantic-ui-css/components/popup.min.css';

import { APP_CONFIG } from 'shared/constants/common.constants';
import { getShadowRoot } from 'shared/helpers/extension.helpers';

import styles from './styles.module.scss';

export type Props = StrictPopupProps & {
  styleType?: 'white' | 'diff' | 'tooltip'
  className?: string
  position?: string
  root?: HTMLElement
}

const getRoot = APP_CONFIG.IS_WEBAPP ? () => (typeof document !== 'undefined' ? document.body : getShadowRoot) : getShadowRoot;

const Popup: FC<Props> = ({
  styleType = 'white', className, root, position, ...props
}) => (
  <SPopup
    className={cn(styles[styleType], className)}
    mountNode={root || getRoot()}
    position={position}
    {...props}
    {...{ style: { zIndex: 99999999999999999999 } }}
  />
);

Popup.defaultProps = {
  className: '',
  styleType: 'white',
  position: 'bottom center',
  root: undefined,
};

export default memo(Popup);
