import ethIcon from 'shared/assets/images/icons/eth-icon.svg';
import { RISK_TYPE } from 'shared/constants/risks.constants';
import { PAYMENT_TOKENS } from 'shared/constants/token.constants';
import {
  fromHexToString, fromWei, fromWeiWithoutFormat, getPriceByAmount,
} from 'shared/helpers/big-number.helpers';
import { getImageUrl } from 'shared/helpers/image.helpers';
import { TransactionETHTransferAnalysis } from 'shared/interfaces/analyze.interfaces';

import { getDangerMessages, getMaxRiskContract } from '../helpers/data.helpers';
import { MessageData, SwapDetails } from '../interfaces';

export const getDirectTransferData = (data: TransactionETHTransferAnalysis, transactionValue: string, toAddress: string): {
  risk: RISK_TYPE;
  swapDetails: SwapDetails;
  dangerMessages: MessageData[];
} => {
  const ethWei = transactionValue ? fromHexToString(`0x${transactionValue.replace('0x', '')}`) : '0';
  const ethAmount = transactionValue ? fromWei(ethWei, 18) : '0';
  const { risk } = getMaxRiskContract(data.transactionRiskGroup);
  const ethPriceUSD = data.ethToUSDCoeff;

  const swapDetails: SwapDetails = {
    loss: [{
      isToken: true,
      item: {
        amount: ethAmount,
        symbol: PAYMENT_TOKENS.ETH,
        name: 'Ethereum',
        image: getImageUrl(ethIcon),
        priceUSD: (ethPriceUSD && transactionValue) ? getPriceByAmount(fromWeiWithoutFormat(ethWei) as string, ethPriceUSD) : null,
      },
    }],
    income: [],
    to: {
      address: toAddress,
      isWallet: !(data.toIsAContract),
    },
  };

  return ({
    risk, swapDetails, dangerMessages: getDangerMessages(data.detectorsMessageDescriptor || []),
  });
};
