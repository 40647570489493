import criticalIcon from 'shared/assets/images/report/high.png';
import lowIcon from 'shared/assets/images/report/low.png';
import middleIcon from 'shared/assets/images/report/medium.png';
import { RISK_TYPE } from 'shared/constants/risks.constants';
import { getImageUrl } from 'shared/helpers/image.helpers';

export const iconSrcByRisk = {
  [RISK_TYPE.LOW]: getImageUrl(lowIcon),
  [RISK_TYPE.MIDDLE]: getImageUrl(middleIcon),
  [RISK_TYPE.CRITICAL]: getImageUrl(criticalIcon),
};
