import { FC, useMemo } from 'react';

import { RecipientAddress } from 'shared/components/RecipientAddress';
import Separator from 'shared/components/SwapInfo/Separator';
import { CUSTOM_CHAINS } from 'shared/constants/custom-chain.constants';
import { DATE_FORMAT } from 'shared/constants/global.constants';
import { RISK_TYPE } from 'shared/constants/risks.constants';
import { isEmpty } from 'shared/helpers/common.helpers';
import { withTranslation } from 'shared/helpers/i18n.helpers';
import { getDateWithFormat } from 'shared/helpers/time.helpers';
import { Web3ContractEntity } from 'shared/interfaces/analyze.interfaces';
import { ProtocolRisks } from 'shared/modules/analyze/Scan/ScanningResult/interfaces';
import {
  ApprovesDetails, MessageData, TransactionGasDetails,
} from 'shared/modules/analyze/Scan/interfaces';

import ERC20 from './ERC20';
import { IRecipient } from './Recipient/interfaces';
import styles from './styles.module.scss';
import Note from '../../Scan/components/Note';
import AdditionalInfo from '../../components/AdditionalInfo';
import Report from '../../components/Report';
import Nfts from '../components/Nfts';

interface Props {
  usdPrice?: number;
  recipient: IRecipient
  approves: ApprovesDetails[];
  isRevoke?: boolean;
  risk: RISK_TYPE;
  risks: ProtocolRisks[]
  handleContractSelect: (tokenAddress: string, type: Web3ContractEntity.type) => void;
  detects: MessageData[];
  gasDetails?: TransactionGasDetails | null;
  chain?: CUSTOM_CHAINS;
}

const getRecipientLabels = (recipient: IRecipient): { name: string, value: string }[] => {
  const labels = [];

  if (!isEmpty(recipient.transactionsCount)) {
    labels.push({ name: withTranslation('Txn'), value: String(recipient.transactionsCount) });
  }

  if (!isEmpty(recipient.contractCreationDate)) {
    labels.push({
      name: withTranslation('Created'),
      value: getDateWithFormat(
        recipient.contractCreationDate as string, DATE_FORMAT.MONTH_DAY_YEAR,
      ) as string,
    });
  }

  if (!isEmpty(recipient.nftsCount)) {
    labels.push({ name: withTranslation('NFTs owned'), value: String(recipient.nftsCount) });
  }

  return labels;
};

const Approve: FC<Props> = ({
  usdPrice, recipient, approves, isRevoke, risk, detects, handleContractSelect, risks, gasDetails, chain,
}) => {
  const isERC20 = useMemo(() => !approves[0]?.isNft, [approves]);

  return (
    <div className={styles.wrap}>
      <div className={styles.header}>
        <Report
          risk={risk}
          label={withTranslation(isRevoke ? 'Revoke access' : 'Approve access')}
          risks={isRevoke ? [] : risks}
          handleContractSelect={handleContractSelect}
          detects={detects}
        />
      </div>
      <div className={styles.block}>
        <div className={styles.title}>{withTranslation(isRevoke ? 'Revoke details' : 'Value at risk')}</div>
        {isERC20 ? approves.map((approve) => (
          <ERC20
            key={approve.address}
            name={approve.name || 'No name'}
            value={approve.approvedAsset}
            imageSrc={approve.imageSrc}
            usdPrice={usdPrice || approve.usdPrice}
            isHoneypot={approve.isHoneypot}
          />
        )) : <Nfts data={approves} usdPrice={usdPrice} />}
        <Separator />
        <RecipientAddress
          imageSrc={recipient.imageSrc}
          name={recipient.name || (recipient.isRecipient ? 'Wallet' : 'Contract')}
          address={recipient.address}
          isWallet={recipient.isRecipient}
          message={isRevoke
            ? ''
            : `${withTranslation('This address will receive access to your tokens and the ability to manage them')}.`}
          labels={getRecipientLabels(recipient)}
          chain={chain}
        />
      </div>
      {gasDetails && <AdditionalInfo gasDetails={gasDetails} />}
      <Note />
    </div>
  );
};

export default Approve;
