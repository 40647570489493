import cn from 'classnames';
import { memo } from 'react';

import ChartWithData from 'shared/components/ChartWithData';
import { EXTENSION_COLLECTION_CHART_API } from 'shared/constants/check-nft.constants';
import { IconExternalLink } from 'shared/constants/icons.constants';
import { formatNumberWithComas, getPercentFromValues } from 'shared/helpers/big-number.helpers';
import { isEmpty } from 'shared/helpers/common.helpers';
import { withTranslation } from 'shared/helpers/i18n.helpers';
import { getCollectionUrl } from 'shared/helpers/url.helpers';

import styles from './styles.module.scss';

interface Props {
  sales?: number,
  quantity?: number,
  owners?: number,
  mintingCap?: string | number | null,
  mintingTotal?: string | number | null,
  collectionId?: number;
  description?: string;
  address: string;
}

const Overview = ({
  sales, owners, quantity, mintingCap, mintingTotal, collectionId, description, address,
}: Props) => {
  const hasInfo = !isEmpty(sales) || !isEmpty(owners) || !isEmpty(quantity);

  return (
    <div className={styles.wrap}>
      <div className={styles.title}>{withTranslation('Overview')}</div>
      {hasInfo && (
        <div className={styles.info}>
          {!isEmpty(sales) && (
            <div className={cn(styles.item, styles.sales)}>
              <div className={styles.label}>{withTranslation('Sales')}</div>
              <div className={styles.value}>{sales && formatNumberWithComas(sales, 0)}</div>
            </div>
          )}
          {!isEmpty(owners) && (
            <div className={styles.item}>
              <div className={styles.label}>{withTranslation('Holders')}</div>
              <div className={styles.value}>{owners && formatNumberWithComas(owners, 0)}</div>
            </div>
          )}
          {!isEmpty(quantity) && (
            <div className={styles.item}>
              <div className={styles.label}>{withTranslation('Tokens')}</div>
              <div className={styles.value}>{quantity && formatNumberWithComas(quantity, 0)}</div>
            </div>
          )}
        </div>
      )}
      {Boolean(mintingCap && mintingTotal) && (
        <div className={cn(styles.mintedWrap)}>
          <div className={styles.label}>{withTranslation('Minted')}</div>
          <div className={styles.progressBar}>
            <div className={styles.progress} style={{ width: `${getPercentFromValues(mintingTotal || 0, mintingCap || 0)}%` }} />
          </div>
          <div className={styles.count}>
            <b>{formatNumberWithComas(mintingTotal || 0, 0)}</b>
            <span className={styles.gray}> / {formatNumberWithComas(mintingCap || 0, 0)}</span>
          </div>
        </div>
      )}
      {collectionId && (
        <ChartWithData
          fieldsForChartMap={{ bar: 'transfers', line: 'sales' }}
          apiUrl={EXTENSION_COLLECTION_CHART_API.replace('[collectionId]', String(collectionId))}
          title={withTranslation('Collection trading activity')}
          barsLabel={withTranslation('Transfers')}
          linesLabel={withTranslation('Sales')}
        />
      )}
      {description && <div className={styles.description}>{description}</div>}
      {address && collectionId && (
        <a
          className={styles.collectionLink}
          target="_blank"
          rel="noreferrer"
          href={getCollectionUrl(address, String(collectionId))}
        >
          <>{withTranslation('More on CheckNFT')} <IconExternalLink /></>
        </a>
      )}
    </div>
  );
};

export default memo(Overview);
