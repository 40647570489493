import cn from 'classnames';
import { memo } from 'react';

import { RISK_TYPE } from 'shared/constants/risks.constants';

import { IconPercent } from './constants';
import styles from './styles.module.scss';

interface Props {
  risk: RISK_TYPE;
  isIncreased: boolean;
  disabled?: boolean;
}

const GasPaymentBar = ({ risk, isIncreased, disabled }: Props) => (
  <div
    className={cn(styles.wrap, styles[risk.toLowerCase()], { [styles.increase]: isIncreased, [styles.disabled]: disabled })}
  >
    <div className={styles.circle}>{IconPercent}</div>
  </div>
);

export default memo(GasPaymentBar);
