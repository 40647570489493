type MessageAction = string;
type MessagePayload = any;
type MessageHandler = (payload: MessagePayload) => Promise<void> | void;
type SendMessage = { message: string, payload?: MessagePayload }

export class Communicator {

  private receiverName: string;

  private messageHandlers: Record<MessageAction, MessageHandler[]>;

  constructor(eventName: string, receiverName: string) {
    this.receiverName = receiverName;
    this.messageHandlers = {};
    if (typeof document !== 'undefined') {
      document.addEventListener(eventName, this.handleMessage.bind(this));
    }
  }

  private handleMessage(event: CustomEventInit): void {
    if (event.detail) {
      const { message, payload } = event.detail;
      if (message in this.messageHandlers) {
        const handlers = this.messageHandlers[message];
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        handlers.forEach((listener) => listener(payload));
      }
    }
  }

  public addMessageHandler(action: MessageAction, handler: MessageHandler): void {
    const handlers = this.messageHandlers[action] || [];
    handlers.push(handler);
    this.messageHandlers[action] = handlers;
  }

  public removeMessageListener(action: MessageAction, listener: MessageHandler): void {
    const index = this.messageHandlers[action]?.indexOf(listener);
    if (index !== -1) {
      this.messageHandlers[action].splice(index, 1);
    }
  }

  public sendMessageToWindow(message: SendMessage): void {
    if (typeof document !== 'undefined') {
      document.dispatchEvent(new CustomEvent(this.receiverName, { detail: message }));
    }
  }

}
