import { W3A_DECISION_EVENT } from '../constants/common.constants';

export const getDateByTimeStamp = (timestamp: number): string => new Date(timestamp).toISOString();

export const getQueryStringParams = (url: string): Record<string, string> => {
  const query = url.slice(url.indexOf('?') + 1);

  return (query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
      .split('&')
      .reduce((params: Record<string, string>, param) => {
        const [key, value] = param.split('=');
        params[key] = (value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '');
        return params;
      }, {})
    : {});
};

export const copyText = async (text: string): Promise<void> => {
  await navigator.clipboard.writeText(text);
};

export const groupBy = <T>(
  array: T[], predicate: (value: T, index: number, array: T[]) => string): {
  [key: string]: T[];
} => array.reduce((acc, value, index, arr) => {
  (acc[predicate(value, index, arr)] ||= []).push(value);
  return acc;
}, {} as { [key: string]: T[] });

export const getNftName = (id: string, name?: string): string => (name ? `${name}` : `#${id}`);

export const isEmpty = (value: string | number | null | undefined | boolean): boolean => value === null || value === undefined;
export const formatStringWithDash = (value: number | string | null | undefined): string => (isEmpty(value) ? '-' : String(value));

export const sendCustomMessage = (message: string): void => {
  const documentEvent = new CustomEvent(W3A_DECISION_EVENT, {
    detail: message,
  });
  if (typeof document !== 'undefined') {
    document.dispatchEvent(documentEvent);
  }
};

export function fieldsAreContainedInObject<T extends Record<string, unknown>>(obj: T, fields: (keyof T)[]): boolean {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key) && !fields.includes(key as keyof T)) {
      return false;
    }
  }
  // eslint-disable-next-line no-prototype-builtins
  return fields.every((field) => obj.hasOwnProperty(field));
}

export const getFirstLetters = (str: string): string => {
  const matches = str.match(/\b(\w)/g);
  const acronym = (matches || [])?.join('');
  return acronym;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getMaxEnumValue(enumObject: any): string {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  const enumValues: string[] = Object.keys(enumObject).map((key) => enumObject[key]);
  return enumValues.reduce((maxValue, value) => (value > maxValue ? value : maxValue), enumValues[0]);
}

export const capitalizeString = (str: string): string => {
  const capitalizedString = str.charAt(0).toUpperCase() + str.slice(1);
  return capitalizedString;
};

export const removeEmptyFieldsFromObj = (obj: Record<string, any>): Record<string, any> => Object.fromEntries(Object.entries(obj)
  .filter(([_, v]) => v !== undefined));
