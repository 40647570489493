import { SuspiciousActivityData } from 'shared/components/Risks/SuspiciousActivity/interfaces';
import { TECHNICAL_RISKS } from 'shared/components/Risks/TechnicalRisks/constants';
import { TechnicalRisksData } from 'shared/components/Risks/TechnicalRisks/interfaces';
import { VulnerableCodeDetector, VulnerableCodeDetectorsData } from 'shared/components/Risks/VulnerableCodeDetectors/interfaces';
import { RisksData } from 'shared/components/Risks/interfaces';
import { withTranslation } from 'shared/helpers/i18n.helpers';
import {
  ContractDetectorsDescriptor,
  Web3SuspiciousActivityEntity,
} from 'shared/interfaces/analyze.interfaces';

import { CODE_DETECTORS_INFO_DESCRIPTIONS, CODE_DETECTORS_INFO_LABELS } from '../Scan/constants';

const getContractTechnicalRisks = (strictCheck: boolean, detectors?: ContractDetectorsDescriptor): TechnicalRisksData => ({
  main: {
    [TECHNICAL_RISKS.METAMORPHIC]: strictCheck ? Boolean(detectors?.metamorphic) : detectors?.metamorphic,
    [TECHNICAL_RISKS.BURNABLE_TOKEN]: strictCheck ? Boolean(detectors?.burnableTokens) : detectors?.burnableTokens,
    [TECHNICAL_RISKS.OVERSUPPLY_MINTING]: strictCheck ? Boolean(detectors?.ownerPermission?.mint) : detectors?.ownerPermission?.mint,
    [TECHNICAL_RISKS.TRANSFER_AMOUNT_LIMIT]: strictCheck
      ? Boolean(detectors?.balanceLock?.transferAmountLimit) : detectors?.balanceLock?.transferAmountLimit,
    [TECHNICAL_RISKS.WASH_TRADING]: strictCheck
      ? Boolean(detectors?.washTradingPrc && detectors?.washTradingPrc > 30)
      : detectors?.washTradingPrc ? detectors?.washTradingPrc > 30 : undefined,
    [TECHNICAL_RISKS.SCAM_NAME]: strictCheck
      ? Boolean(detectors?.scamName) : detectors?.scamName,
    [TECHNICAL_RISKS.EMPTY_PAID_CONTRACT]: strictCheck
      ? Boolean(detectors?.slither?.some((check) => check === 'empty_payable'))
      : detectors?.slither?.some((check) => check === 'empty_payable'),
    [TECHNICAL_RISKS.HONEYPOT]: strictCheck ? Boolean(detectors?.honeypot?.found) : detectors?.honeypot?.found,
    [TECHNICAL_RISKS.SELF_DESTRUCTION]: strictCheck ? Boolean(
      detectors?.slither?.some((check) => check === 'suicidal') || detectors?.bytecode?.suicidal,
    ) : detectors?.slither?.some((check) => check === 'suicidal') || detectors?.bytecode?.suicidal,
    [TECHNICAL_RISKS.OWNER_PERMISSIONS]: strictCheck ? Boolean(detectors?.ownerPermission?.transfer) : detectors?.ownerPermission?.transfer,
    [TECHNICAL_RISKS.UNSANCTIONED_TOKEN_BURN]: strictCheck ? Boolean(detectors?.ownerPermission?.burn) : detectors?.ownerPermission?.burn,
    [TECHNICAL_RISKS.IMPOSSIBLE_APPROVE]: strictCheck
      ? Boolean(detectors?.balanceLock?.impossibleApprove) : detectors?.balanceLock?.impossibleApprove,
    [TECHNICAL_RISKS.OWNER_APPROVE]: strictCheck
      ? Boolean(detectors?.ownerPermission?.approve) : detectors?.ownerPermission?.approve,
    [TECHNICAL_RISKS.TRANSFER_RESTRICTION]: strictCheck
      ? Boolean(detectors?.slither?.some((check) => check === 'transfer_pausable'))
      : detectors?.slither?.some((check) => check === 'transfer_pausable'),
    [TECHNICAL_RISKS.APPROVE_RESTRICTION]: strictCheck
      ? Boolean(detectors?.slither?.some((check) => check === 'approve_pausable'))
      : detectors?.slither?.some((check) => check === 'approve_pausable'),
    [TECHNICAL_RISKS.CONTRACT_METHODS_RESTRICTION]: Boolean(
      detectors?.slither?.some((check) => check === 'other_pausable'),
    ),
    [TECHNICAL_RISKS.NO_DEX_PAIRS]: strictCheck ? Boolean(detectors?.honeypot?.noDEXPairs) : detectors?.honeypot?.noDEXPairs,
    [TECHNICAL_RISKS.VOLATILE_TOKEN_LIQUIDITY]: strictCheck
      ? Boolean(detectors?.honeypot?.liquidityDrop) : detectors?.honeypot?.liquidityDrop,
    [TECHNICAL_RISKS.SCAM_ADDRESS]: strictCheck ? Boolean(detectors?.zeroAddress) : detectors?.zeroAddress,
    [TECHNICAL_RISKS.UNVERIFIED_CONTRACT]: detectors?.externalVerification === false,
  },
});

const getContractSuspiciousActivities = (
  contractData: { address?: string, name?: string, isVerified?: boolean },
  suspiciousActivities: Web3SuspiciousActivityEntity[],
  hardcodedAddressesList?: string[],
  allContracts?: { address: string, name?: string, verified?: boolean }[],
): SuspiciousActivityData => {
  const activities = suspiciousActivities?.filter((activity) => (activity.address === contractData.address) && activity.name) || [];

  const suspiciousActivitiesData = activities.map((activity) => ({
    text: activity.name as string,
    date: activity.timestamp || null,
    tooltip: activity.tooltip,
  }));

  const hardcodedAddresses = hardcodedAddressesList || [];
  const hardcodedAddressesData = hardcodedAddresses
    .map((address) => {
      const hardcodedData = suspiciousActivities?.filter((activity) => activity.address === address);
      if (hardcodedData.length) {
        const hardcodedContractData = allContracts?.find((contract) => contract.address === address);
        return {
          activitiesList: hardcodedData.map((activity) => ({
            text: activity.name as string,
            date: activity.timestamp || null,
            tooltip: activity.tooltip,
          })),
          address,
          description: `${withTranslation('A hardcoded address found in the contract has been involved in')}:`,
          contractName: hardcodedContractData?.name,
          isVerified: hardcodedContractData?.verified,
        };
      }

      return null;
    }).filter((value) => value);

  const suspiciousActivity = suspiciousActivitiesData.length ? {
    address: contractData.address,
    contractName: contractData.name,
    description: `${withTranslation('This address has been involved in')}:`,
    activitiesList: suspiciousActivitiesData,
    isVerified: contractData.isVerified,
  } : undefined;

  const result = [
    ...suspiciousActivity ? [suspiciousActivity] : [],
    ...hardcodedAddressesData,
  ];

  return result as SuspiciousActivityData;
};

const getContractVulnerableCodeDetectors = (
  detectors: string[],
  contractDetectors?: ContractDetectorsDescriptor,
): VulnerableCodeDetectorsData => {
  let vulnerableCodeDetectors = [];
  const slitherDetectors = detectors.reduce((acc, detector) => {
    const name = CODE_DETECTORS_INFO_LABELS[detector];
    if (name) {
      return [...acc, { name, info: CODE_DETECTORS_INFO_DESCRIPTIONS[detector] }];
    }
    return acc;
  }, [] as VulnerableCodeDetector);

  vulnerableCodeDetectors = [...slitherDetectors];

  if (contractDetectors?.balanceLock?.tradingCooldown) {
    const detectName = 'trading_cooldown';
    const name = CODE_DETECTORS_INFO_LABELS[detectName];
    const info = CODE_DETECTORS_INFO_DESCRIPTIONS[detectName];
    vulnerableCodeDetectors.push({ name, info });
  }

  if (contractDetectors?.hardcodedAddresses) {
    const detectName = 'hardcoded_logic';
    const name = CODE_DETECTORS_INFO_LABELS[detectName];
    const info = CODE_DETECTORS_INFO_DESCRIPTIONS[detectName];
    vulnerableCodeDetectors.push({ name, info });
  }

  if (contractDetectors?.bytecode?.txOrigin) {
    const detectName = 'tx-origin';
    const name = CODE_DETECTORS_INFO_LABELS[detectName];
    const info = CODE_DETECTORS_INFO_DESCRIPTIONS[detectName];
    vulnerableCodeDetectors.push({ name, info });
  }

  return { main: slitherDetectors };
};

export const getContractRisks = (
  contractData: { address?: string, name?: string, isVerified?: boolean },
  suspiciousActivities: Web3SuspiciousActivityEntity[],
  strictCheck = true,
  contractDetectors?: ContractDetectorsDescriptor,
  allContracts?: { address: string, name?: string, verified?: boolean }[],
  disableCheck?: boolean,
): { count: number; risks: RisksData } => {
  const technicalRisksData = getContractTechnicalRisks(
    strictCheck, disableCheck ? ({}) as ContractDetectorsDescriptor : contractDetectors,
  );
  const technicalRisksCount = Object.values(technicalRisksData.main).filter((risk) => risk).length;

  const suspiciousActivityData = disableCheck
    ? [] : getContractSuspiciousActivities({
      name: contractData?.name,
      address: contractData?.address,
      isVerified: contractData?.isVerified,
    }, suspiciousActivities, contractDetectors?.hardcodedAddressesList || [], allContracts);
  const suspiciousActivityCount = suspiciousActivityData.length;

  const vulnerableCodeDetectorsData = disableCheck
    ? { main: [] } : getContractVulnerableCodeDetectors(contractDetectors?.slither || [], contractDetectors);
  const vulnerableCodeDetectorsCount = vulnerableCodeDetectorsData.main.length;

  const count = technicalRisksCount
    + suspiciousActivityCount
    + vulnerableCodeDetectorsCount;

  return ({
    count,
    risks: {
      technicalRisks: { count: technicalRisksCount, data: technicalRisksData },
      suspiciousActivity: { count: suspiciousActivityCount, data: suspiciousActivityData },
      vulnerableCodeDetectors: { count: vulnerableCodeDetectorsCount, data: vulnerableCodeDetectorsData },
    },
  });
};
