import { useState } from 'react';

import { Button } from 'shared/components';

import { Alert } from './components';
import styles from './styles.module.scss';

const Widget = (): React.ReactElement => {
  const [isVisible, setVisibility] = useState(false);

  return (
    <div className={styles.widget}>
      <Button
        data-testid="openAlert"
        onClick={() => {
          setVisibility(!isVisible);
        }}
      >
        {isVisible ? 'Close Alert' : 'Open Alert'}
      </Button>

      {isVisible && <Alert />}
    </div>
  );
};

export default Widget;
