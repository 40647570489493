import BN from 'bignumber.js';

import { PAYMENT_TOKENS, TOKEN_LABELS } from 'shared/constants/token.constants';

import { isEmpty } from './common.helpers';

BN.config({ EXPONENTIAL_AT: [-50, 100] });
export const fromWeiWithoutFormat = (value: number | string | null | undefined, decimals = 18): string | number | null | undefined => {
  if (value !== undefined && value !== null) {
    return new BN(value).dividedBy(10 ** decimals).toString(10);
  }

  return value;
};

export const fromWei = (value: number | string, decimals = 18, round = 4): string => {
  const result = fromWeiWithoutFormat(value, decimals);

  if (result && decimals > 0) {
    const minimumValueDisplayedOnUi = new BN(1).div(10 ** round).toString(10);
    if (new BN(result).isLessThan(minimumValueDisplayedOnUi)) {
      return `<${minimumValueDisplayedOnUi}`;
    }
  }

  if (result !== undefined && result !== null) {
    return new BN(result).decimalPlaces(round, BN.ROUND_FLOOR).toString();
  }

  return '';
};

export const roundNumber = (value: string | number, decimal = 1): string => new BN(value).decimalPlaces(decimal).toString();

export const DEFAULT_PRICE_FORMAT = {
  decimalSeparator: '.',
  groupSeparator: ',',
  groupSize: 3,
};
export const formatNumber = (number: number | string): string => new BN(number).toFormat(DEFAULT_PRICE_FORMAT);

export const formatNumberWithComas = (value: string | number, decimals = 2): string => (
  Number(value) !== 0 ? formatNumber(roundNumber(value, decimals)) : Number(value).toFixed(decimals)
);

export const formatNumberWithMinValue = (value: string | number, decimal = 2): string => {
  const minimumValueDisplayedOnUi = new BN(1).div(10 ** decimal).toString(10);
  if (new BN(value).isLessThan(minimumValueDisplayedOnUi)) {
    return `<${minimumValueDisplayedOnUi}`;
  }

  return formatNumberWithComas(value, decimal);
};

// value2 > value1
export const getPercentFromValues = (value1: string | number, value2: string | number): number => (
  new BN(value1).div(new BN(value2)).multipliedBy(100).toNumber()
);

// value2 > value1
export const getPercentDiffFromValues = (value1: string | number, value2: string | number): number => (
  new BN(value2).minus(new BN(value1)).abs().div(new BN(value2))
    .multipliedBy(100)
    .toNumber()
);

export const getPercentDiff = (value1: string | number, value2: string | number): number => (
  new BN(value2).isGreaterThan(new BN(value1)) ? -getPercentDiffFromValues(value2, value1) : getPercentDiffFromValues(value1, value2));

const suffixes = ['', 'K', 'M', 'B', 'T', 'Qa', 'Qi', 'Sx', 'Sp', 'Oc', 'No', 'Dc',
  'Ud', 'Dd', 'Td', 'Qad', 'Qid', 'Sxd', 'Spd', 'Ocd', 'Nod', 'Vg', 'Uvg'];

export const MAX_VALUE_FOR_ABBREVIATE = 10 ** 9;

export const abbreviateNumber = (value: number | string, minValue = MAX_VALUE_FOR_ABBREVIATE): number | string => {
  const number = Number(value);
  if (Math.abs(number) < minValue) return number;

  const sign = number < 0 ? '-' : '';
  const absNumber = Math.abs(number);

  // eslint-disable-next-line no-bitwise
  const tier = Math.log10(absNumber) / 3 | 0;
  if (tier === 0) return `${absNumber}`;

  const postfix = suffixes[tier];
  const scale = 10 ** (tier * 3);

  const scaled = absNumber / scale;
  const formatted = new BN(scaled).decimalPlaces(1, 1).toString();

  return `${sign}${formatted}${postfix}`;
};

export const fromHexToString = (value: string): string => new BN(value).toString();
export const fromStringToHex = (value: string): string => new BN(value).toString(16);

export const getPriceByAmount = (amount: string, unitPrice: string | number): string => new BN(amount).multipliedBy(unitPrice).toString();

export const getUSDPriceWithFallback = (value: string | number | null, decimals = 2): string => (
  isEmpty(value) ? '-' : `$${formatNumberWithComas(value || 0, decimals)}`
);

export const sumNumbers = (num1: number | string, num2: number | string): string => new BN(num1).plus(new BN(num2)).toString();
export const multiplyNumbers = (num1: number | string, num2: number | string): string => new BN(num1).multipliedBy(new BN(num2)).toString();
export const subtractNumbers = (num1: number | string, num2: number | string): string => new BN(num1).minus(new BN(num2)).toString();
export const divideNumbers = (num1: number | string, num2: number | string): string => {
  if (Number(num2)) {
    return new BN(num1).dividedBy(new BN(num2)).toString();
  }

  return '0';
};

export const getERC20AmountWithSymbol = (amount: number | string, symbol = PAYMENT_TOKENS.ETH as string): string => {
  if (!amount) return symbol;

  return (symbol === PAYMENT_TOKENS.ETH
    ? `${TOKEN_LABELS[PAYMENT_TOKENS.ETH]}${amount}` : `${formatNumberWithMinValue(amount, 4)} ${symbol}`);
};

export const getNumberPrefix = (value: number): string => {
  if (value > 0) return '+';
  if (value < 0) return '-';
  return '';
};

export const addPercentToNumber = (value: number | string | BN, percent: number): number => {
  const bigValue = new BN(value);
  const percentage = bigValue.times(percent).dividedBy(100);
  const result = bigValue.plus(percentage);

  return new BN(result.toFixed(0)).toNumber();
};
