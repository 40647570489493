/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import cn from 'classnames';
import pluralize from 'pluralize';
import { memo, useState } from 'react';

import { CUSTOM_CHAINS } from 'shared/constants/custom-chain.constants';
import { ArrowDownIcon } from 'shared/constants/icons.constants';
import { RISK_OPTIONS, RISK_TYPE } from 'shared/constants/risks.constants';
import { isEmpty } from 'shared/helpers/common.helpers';
import { withTranslation } from 'shared/helpers/i18n.helpers';
import { Web3ContractEntity } from 'shared/interfaces/analyze.interfaces';
import { TraceWithRisk } from 'shared/interfaces/fetch.interfaces';

import AuditedContracts from './AuditedContracts';
import Detects from './Detects';
import { iconSrcByRisk } from './constants';
import styles from './styles.module.scss';
import { ProtocolRisks } from '../../Scan/ScanningResult/interfaces';
import { MessageData } from '../../Scan/interfaces';

interface Props {
  risk: RISK_TYPE;
  itemsCount?: number | null;
  label?: string;
  risks: ProtocolRisks[]
  handleContractSelect?: (tokenAddress: string, type: Web3ContractEntity.type) => void;
  trace?: TraceWithRisk[];
  detects: MessageData[];
  chain?: CUSTOM_CHAINS;
}

const Report = ({
  risk, itemsCount, risks, handleContractSelect, trace, detects, label, chain,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const onContractSelect = (
    data: ProtocolRisks,
  ) => handleContractSelect && handleContractSelect(data.contract?.address || '', data.contract?.type as Web3ContractEntity.type);

  const mappedDetects = risk === RISK_TYPE.LOW ? [] : detects.map((detect) => {
    const detectRisk = risks.find((riskItem) => detect.address && riskItem?.contract?.address === detect.address);
    if (detectRisk) {
      return ({
        onClick: () => onContractSelect(detectRisk),
        ...detect,
      });
    }
    return detect;
  });

  const canOpen = detects.length > 0 || risks.length > 0;

  return (
    <div
      className={cn(styles.wrap, styles[risk.toLowerCase()], { [styles.open]: isOpen, [styles.disabled]: !canOpen })}
      onClick={(isOpen || !canOpen) ? undefined : () => setIsOpen(!isOpen)}
    >
      <img className={styles.icon} src={iconSrcByRisk[risk]} alt="" />
      <div className={styles.top} onClick={(isOpen || canOpen) ? () => setIsOpen(!isOpen) : undefined}>
        <div className={styles.header}>
          {!isEmpty(label) && <div className={styles.label}>{label}</div>}
          {!isEmpty(itemsCount) && (
            <div className={styles.count}>
              {itemsCount} {withTranslation(pluralize('item', itemsCount || 0))} {withTranslation('analyzed')}
            </div>
          )}
          {canOpen && <ArrowDownIcon className={styles.arrow} data-testid="toggleReportList" />}
        </div>
        <div className={cn(styles.risk)}>
          {RISK_OPTIONS[risk].title}
        </div>
      </div>
      {!mappedDetects.length
       && risk !== RISK_TYPE.CRITICAL
       && <div className={styles.description}>{withTranslation('W3A found no critical risks')}</div>}
      <Detects data={mappedDetects} isOpen={isOpen} />
      {(Boolean(risks.length) || trace)
       && (
         <AuditedContracts
           trace={trace}
           isOpen={isOpen}
           risks={risks}
           handleContractSelect={onContractSelect}
           chain={chain}
         />
       )}
      <div className={styles.gradient} />
    </div>
  );
};

export default memo(Report);
