/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-namespace */
/* eslint-disable max-classes-per-file */

import { DANGER_MESSAGES } from 'shared/constants/detectors.constants';

import { DetectorsMessageDescriptorItem } from './alert.interfaces';
import {
  AnalysisDescriptor, AnalyzeResult, Service3Descriptor, Trace,
} from './fetch.interfaces';
import { Transaction } from './transaction.interfaces';

export enum ProjectAnalysisStatus {
  /**
     * We found URL in Blacklist Blockchain Project table.
     */
  Dangerous = 'dangerous',
  /**
     * We found URL in Whitelist Blockchain Project table.
     */
  Validated = 'validated',
  /**
     * We didn't find URL in table, but analyzer found something weird.
     */
  Suspicious = 'suspicious',
  /**
     * 1st and 2nd cases didn't return anything.
     */
  Neutral = 'neutral',
}

export interface SuspiciousAnalysisPayloadDTO {
  nearestURL: string;
  levensteinDistance: number;
}

export interface ValidatedAnalysisPayloadDTO {
  name: string;
  category: string;
  subcategory: string;
}

export class ValidatedAnalysisDescriptorDTO {

  status: ProjectAnalysisStatus.Validated;

  payload: ValidatedAnalysisPayloadDTO;

}

export class SuspiciousAnalysisDescriptorDTO {

  status: ProjectAnalysisStatus.Suspicious;

  payload: SuspiciousAnalysisPayloadDTO;

}

export class BaseAnalysisDescriptorDTO {

  status: ProjectAnalysisStatus;

  host?: string;

}

export type AnalysisDescriptorDTO =
  | ValidatedAnalysisDescriptorDTO
  | SuspiciousAnalysisDescriptorDTO
  | BaseAnalysisDescriptorDTO;

export declare type CollectionStatisticDTO = {
  numOfOwners: number;
  numOfTokens: number;
  marketCapUSD: number;
  marketCapETH: string;
  floorPriceUSD: number;
  floorPriceETH: number | null;
  sales: number;
};

export interface Service4Analysis {
  hasApprove: boolean;
  approveMethod: string; // hex
  hasMint: boolean;
  mintMethod: string; // hex
  hasBurn: boolean;
  burnMethod: string; // hex
  hasPause: boolean;
  pauseMethod: string; // hex
  hasTransfer: boolean;
  transferMethod: string; // hex
  cap: string; // hex
  totalSupply: string; // hex
}

export interface Service5Analysis {
  transferNotExist?: boolean;
  balanceFieldNotFound?: boolean;
  balanceLocked?: boolean;
}

export type Service5Descriptor = AnalyzeResult<Service5Analysis>;

export declare type ContractCodeAnalysisDTO = {
  name: string;
  verified: boolean;
  service1: {
    detectors: {
      check: string;
      impact: string;
    }[]
  };
  service2: any;
  service3: Service3Descriptor;
  service4: AnalyzeResult<Service4Analysis>;
  service5: Service5Descriptor;
  service6: BalanceLockAnalysisGroup;
  service7: OwnerPermissionsAnalysisGroup;
};

export declare type ContractScamAnalysisDTO = {
  service1: any;
  service2: any;
};

export interface Socials {
  email: string;
  site: string;
  blog: string;
  twitter: string;
  gitHub: string;
  discord: string;
  reddit: string;
  telegram: string;
  linkedIn: string;
  facebook: string;
  slack: string;
  ticketing: string;
  opensea: string;
  uniswap: string;
  whitepaper: string;
  weChat: string;
  bitcointalk: string;
  coinMarketCap: string;
  coinGecko: string;
}

export interface OwnerPermissionsDetectorsDescriptor {
  mint: boolean;

  burn: boolean;

  transfer: boolean;

  approve: boolean;
}

export enum HoneypotReason {
  Unknown = 0,
  /**
   * There are no transfer w/o valid errors.
   */
  TransferNotWork = 1,
  /**
   * Transfer has big fee
   */
  BigFee = 2,
  /**
   * UniSwap sale has big fee.
   */
  AmountOutChange = 3,
  /**
   * UniSwap purchase has big fee.
   */
  BigUniSwapFee = 4,
  /**
   * There is no UniSwap transfer w/o errors.
   */
  UniSwapToTransferNotWork = 5,
  /**
   * If there are no working transfers, but all errors are in whitelist.
   */
  TransfersDoesNotWorkBySystemReason = 6
}

export interface BalanceLockDetectorsDescriptor {
  transferAmountLimit: boolean;

  honeypots: boolean;

  honeypotsReason: HoneypotReason;

  tradingCooldown: boolean;

  impossibleApprove: boolean;

  transferRestriction: boolean;
}

export class HoneypotDetectorDescriptor {

  found: boolean;

  reason: HoneypotReason;

  saleTax: number;

  buyTax: number;

  noDEXPairs: boolean;

  liquidityDrop: boolean;

}

export interface ByteCodeDescriptor {
  otherBlacklist: boolean;

  otherWhitelist: boolean;

  suicidal: boolean;

  txOrigin: boolean;
}

export interface ContractDetectorsDescriptor {
  metamorphic: boolean;

  slither: string[];

  ownerPermission: OwnerPermissionsDetectorsDescriptor;

  balanceLock: BalanceLockDetectorsDescriptor;

  scamName: boolean;

  hardcodedAddresses: boolean;

  unpopularCollection: boolean;

  hardcodedAddressesList: string[] | null;

  honeypot: HoneypotDetectorDescriptor | null;

  externalVerification: boolean;

  burnableTokens: boolean;

  washTradingPrc: number | null;

  zeroAddress: boolean;

  bytecode: ByteCodeDescriptor | null;
}

export declare type Web3ContractEntityDTO = {
  id: number;
  createdAt: string;
  updatedAt: string;
  address: string;
  type: Web3ContractEntity.type;
  network: Web3ContractEntity.network;
  codeAnalysisStatus: Web3ContractEntity.codeAnalysisStatus;
  codeAnalysisResult: any;
  scamAnalysisStatus: Web3ContractEntity.scamAnalysisStatus;
  scamAnalysisResult: any;
  whitelist: boolean;
  projectId: number;
  name: string;
  symbol: string;
  imgURL: string;
  decimals: number | null;
  securityLevel: SECURITY_LEVEL;
  socials: Socials;
  lastPriceETH: string | null;
  lastPriceUSD: string | null;
  detectors: ContractDetectorsDescriptor;
  riskGroup: RiskGroup;
  riskScore: number | null;
  riskConfidenceReduction: boolean;
  numOfTransactions: number;
  cap: string;
  totalSupply: string;
  isProxy: boolean;
  hasInteraction: boolean;

  domainName?: string
  publicName?: string;
  contractOwnerDomainName?: string;
  contractOwnerAddress?: string;
  contractCreatorDomainName?: string;
  contractCreatorAddress?: string;

  verified?: boolean;
  audits?: Web3ContractAuditEntity[]
  circulatingSupply?: string;
  marketCapUSD?: number;
  implementationAddress?: string;
};

export namespace Web3ContractEntity {
  export enum type {
    ERC721,
    ERC1155,
    ERC20
  }
  export enum network {
    ETHEREUM = 'Ethereum'
  }
  export enum codeAnalysisStatus {
    NOT_ANALYZED = 'NotAnalyzed',
    ANALYZING = 'Analyzing',
    ANALYZED = 'Analyzed',
    ERROR_DURING_ANALYZING = 'ErrorDuringAnalyzing',
    ANALYZING_TIMEOUT = 'AnalyzingTimeout',
    REPEAT_ANALYSIS = 'RepeatAnalysis'
  }
  export enum scamAnalysisStatus {
    NOT_ANALYZED = 'NotAnalyzed',
    ANALYZING = 'Analyzing',
    ANALYZED = 'Analyzed',
    ERROR_DURING_ANALYZING = 'ErrorDuringAnalyzing',
    ANALYZING_TIMEOUT = 'AnalyzingTimeout',
    REPEAT_ANALYSIS = 'RepeatAnalysis'
  }
}

export enum OperationContractType {
  ERC721 = Web3ContractEntity.type.ERC721,
  ERC20 = Web3ContractEntity.type.ERC20,
}

export enum SECURITY_LEVEL {
  NO_DATA,
  WHITELIST,
  BLACKLIST,
}

export declare type TransferMethodDescriptor = {
  contractAddress: string;
  from: string;
  to: string;
  tokenId: string;
  amount: number;
  value: string;
  projectId: number;
  operationType?: OperationContractType;
};

export declare type ApproveMethodDescriptor = {
  contractAddress: string;
  to: string;
  value: string | null;
  approveType?: 'all' | 'target' | 'targetAll' | null,
};

export declare type TransactionOperationsDescriptor = {
  eth: string;
  receivedETH: string;
  ethToUSDCoeff: string | null;
  from: Array<TransferMethodDescriptor>;
  to: Array<TransferMethodDescriptor>;
  approves: Array<ApproveMethodDescriptor>;
};

export declare type CollectionDescriptor = {
  id: number;
  name: string;
  logo: string;
  isVerified: boolean;
  contractAddress: string;
  firstEventAt: string;
  statistic: CollectionStatisticDTO;
  banner?: string;
  description?: string;
  address: string;
};

export declare type AttributeEntity = {
  id: number;
  createdAt: string;
  updatedAt: string;
  tokenId: number;
  collectionId: number;
  displayType: string;
  traitType: string;
  numberValue: number;
  value: string;
  token: TokenEntity;
  collection: CollectionEntity;
};

export declare type Web3ProjectEntity = {
  id: number;
  createdAt: string;
  updatedAt: string;
  name: string;
  description: string;
  slug: string;
  numOfAccounts: number;
  previewURL: string | null;
  previewPath: string | null;
  socials?: Socials;
};

export type TokenEntity = {
  id: number;
  createdAt: string;
  updatedAt: string;
  externalId: string;
  collectionId: number;
  contractAddress: string;
  contractId: number;
  name: string;
  description: string;
  unlockableContent: boolean;
  isEditableMetadata: boolean;
  quantity: number;
  previewUrl: string;
  animationUrl: string;
  fileType: string;
  url: string;
  croppedPreviewURL: string;
  previewStatus: boolean;
  storageType: TokenEntity.storageType;
  mintedAt: string;
  metaUrl: string;
  externalUrl: string;
  attributesCount: number;
  collection: CollectionEntity;
  contract: ContractEntity;
  ownerAccounts: Array<string>;
  attributes: Array<AttributeEntity>;
  collectionName: string;
  creatorAccountAddress: string;
  creatorAccountName: string;
  dailyPriceGrowth: string;
  weeklyPriceGrowth: string;
  monthlyPriceGrowth: string;
  totalPriceGrowth: string;
  statRarityScore: string;
  statisticStatus: number;
  lastEventAt: string;
  isWashTrading: boolean;
};

export declare type CategoryEntity = {
  id: number;
  createdAt: string;
  updatedAt: string;
  name: string;
};

export declare type AccountEntity = {
  id: number;
  createdAt: string;
  updatedAt: string;
  name: string;
  address: string;
  avatarUrl: string;
};

export declare type CollectionEntity = {
  id: number;
  createdAt: string;
  updatedAt: string;
  name: string;
  description: string;
  logo: string;
  creatorAccountId: number;
  ownerFee: number;
  protocolFee: number;
  termsAndConditionsUrl: string;
  totalSupply: number;
  totalSupplyEdition: number;
  categoryId: number;
  marketPlaceAPIUrl: string;
  marketPlaceCollectionName: string;
  marketPlaceCollectionDescription: string;
  discordUrl: string;
  externalUrl: string;
  mediumUsername: string;
  telegramUrl: string;
  twitterUsername: string;
  instagramUsername: string;
  wikiUrl: string;
  imageUrl: string;
  featuredImageUrl: string;
  largeImageUrl: string;
  bannerImageUrl: string;
  oneDayVolume: string;
  oneDayChange: string;
  oneDaySales: number;
  oneDayAveragePrice: string;
  sevenDayVolume: string;
  sevenDayChange: string;
  sevenDaySales: number;
  sevenDayAveragePrice: number;
  thirtyDayVolume: string;
  thirtyDayChange: string;
  thirtyDaySales: number;
  thirtyDayAveragePrice: string;
  totalVolume: string;
  totalSales: number;
  numOwners: number;
  averagePrice: string;
  marketCap: string;
  floorPrice: string;
  statsUpdatedAt: string;
  category: CategoryEntity;
  creatorAccount: AccountEntity;
};

export declare type ContractEntity = {
  id: number;
  createdAt: string;
  updatedAt: string;
  address: string;
  creatorAccountId: number;
  type: ContractEntity.type;
  network: ContractEntity.network;
  totalSupply: number;
  totalSupplyEdition: number;
  creatorAccount: AccountEntity;
  analysisStatus: number;
  analysisResult: any;
  scamAnalysisStatus: number;
  scamAnalysisResult: any;
  tokenOfferStatus: number;
  lastTokenOfferAt: string;
  firstTokenOfferAt: string;
};
export namespace ContractEntity {
  export enum type {
    ERC721 = 'ERC721',
    ERC1155 = 'ERC1155'
  }
  export enum network {
    ETHEREUM = 'ethereum',
    MATIC = 'matic'
  }
}

export namespace TokenEntity {
  export enum storageType {
    DECENTRALIZED = 'decentralized',
    CENTRALIZED = 'centralized',
  }
}

export interface BaseEntity {
  id: number;
  createdAt: string;
  updatedAt: string;
}

export interface Web3ContractAuditEntity extends BaseEntity {
  address: string;
  vulnerabilities: string[];
  auditor: string;
  auditUrl: string;
  auditedAt: string;
}

export interface BalanceLimitAnalysis {
  transferNotExist?: boolean;
  balanceFieldNotFound?: boolean;
  balanceLocked?: boolean;
}
export interface SimpleBalanceAnalysis {
  methodNotExist?: boolean;
  actionIsNotSupported?: boolean;
  actionAllowed?: boolean;
}
export type SimpleBalanceAnalysisDescriptor = AnalyzeResult<SimpleBalanceAnalysis>;

export interface BalanceApproveLockAnalysis extends SimpleBalanceAnalysis {
  methodNotExist?: boolean;
  approveNotAllowed?: boolean;
}
export type BalanceApproveLockAnalysisDescriptor = AnalyzeResult<BalanceApproveLockAnalysis>;

export interface BalanceLockAnalysisGroup {
  transferManyTokens: SimpleBalanceAnalysisDescriptor;
  transferFewTokens: SimpleBalanceAnalysisDescriptor;
  approveTokens: BalanceApproveLockAnalysisDescriptor;
  severalTransfers: SimpleBalanceAnalysisDescriptor;
}

export interface OwnerMintPermissionsAnalysis extends SimpleBalanceAnalysis {
  capNotFound?: boolean;
}

export type OwnerMintPermissionsAnalysisDescriptor = AnalyzeResult<OwnerMintPermissionsAnalysis>;

export interface OwnerPermissionsAnalysisGroup {
  transfer: SimpleBalanceAnalysisDescriptor;
  burn: SimpleBalanceAnalysisDescriptor;
  mint: OwnerMintPermissionsAnalysisDescriptor;
}

export interface WashTradingStatistic {
  tokenId: number;
  numOfWashTradings: number;
  numOfSales: number;
}

export interface ApproveRecipientDescriptor {
  address: string;
  name: string;
  avatarURL: string;
  numOfTokens: number;
  volumeUSD: number;
}

export interface ApprovedTokensVolumeDescriptor {
  volumeUSD: number;
  volumeETH: string;
}

export interface TransactionGasDescriptor {
  currentGasPriceGWEI: number;
  avgGasPriceGWEI: number;

  transactionGas: string;

  currentGasCostGWEI: string;
  currentGasCostUSD: number;

  avgGasCostGWEI: string;
  avgGasCostUSD: number;

  gasOverpaymentGWEI: string;
  gasOverpaymentUSD: number;
}

export interface TokenPricePredictionEntity extends BaseEntity {
  collectionId: number;
  tokenId: number;
  // eslint-disable-next-line camelcase
  m_price_predict: number;
}

export enum RiskGroup {
  /**
   * Neutral
   */
  N = 0,
  /**
   * Low
   */
  L = 1,
  /**
   * Medium
   */
  M = 2,
  /**
   * High
   */
  H = 3,
  /**
   * Severe
   */
  S = 4,
}

export interface Web3SuspiciousActivityEntity extends BaseEntity {
  source: string;

  address: string;

  timestamp?: string;

  type: string;

  description?: string;

  risk?: string;

  name?: string;

  tooltip?: string;
}

export enum TransactionRequestMethod {
  Approve = 0,
  Revoke = 1,
  Other = 2,
  ETHTransfer = 3,
  CreateContract = 4,
  ApproveV2 = 5,
}

export interface ETHTransferDetectors {
  /**
   * Checks the % of transfered ETH of the user.
   */
  transferAmount: RiskGroup;

  /**
   * Check if the TO address has suspicious activity.
   */
  suspiciousActivity: RiskGroup;

  /**
   * Check if the TO address is PA fake address.
   */
  poisoningAttack: RiskGroup;

  dangerousWebsite: RiskGroup;

  walletDrainer: RiskGroup;
}

export interface TransactionDetectors {
  zeroAddress: boolean;

  /**
   * Check if the user calls transactions on Dangerous website.
   */
  dangerousWebsite: RiskGroup;

  /**
   * Check if user approves some amount of tokens on suspicious accounts.
   */
  suspiciousApprove: RiskGroup;

  /**
   * Check if operations has big % of Wash Trading.
   */
  washTrading: RiskGroup;

  /**
   * Finds contracts w/ max risk.
   */
  dangerousContract: RiskGroup;

  /**
   * Checks if user transfers some % of ERC20 tokens to suspicious address.
   */
  directERC20Transfer: RiskGroup;

  phishingSwap: RiskGroup;

  bigETHTransfer: RiskGroup;

  poisoningAttack: RiskGroup;

  suspiciousDeployer: RiskGroup;

  walletDrainer: RiskGroup;

}

export type MetaDataEntity = Omit<TokenEntity, 'collectionId'>;

export interface ContractDEXDescriptor {
  address: string;

  numOfPairs: number;
}

export interface ContractView {
  main: {
    viewContract: Web3ContractEntityDTO;
    riskContract: Web3ContractEntityDTO;
  }
  related?: Web3ContractEntityDTO[]
}

export interface ContractViews {
  protocol?: ContractView;
  erc20List?: ContractView[];
  collectionsList?: ContractView[];
}

export interface TraceDescriptorWithImplementations extends Trace {

  isImplementation: boolean;

}

export interface TraceSimulations extends TraceDescriptorWithImplementations {
  implementations: TraceDescriptorWithImplementations[];

  isProxy: boolean;
}

export interface DetectorsMessageAnalysis {
  message?: string;

  address?: string;

  title?: string;

  name: DANGER_MESSAGES;
}

export type AnalyzeTransactionResponse = {
  activeProjectId: number;
  projects: Array<Web3ProjectEntity>;
  tokens: Array<TokenEntity>;
  collections: Array<CollectionDescriptor>;
  siteAnalysis: AnalysisDescriptorDTO;
  traceOperations: TransactionOperationsDescriptor;
  trace: Array<Trace>;
  contracts: Array<Web3ContractEntityDTO>;
  audits: Array<Web3ContractAuditEntity>;
  washTradingStatistics: Array<WashTradingStatistic>;
  approveRecipients: Array<ApproveRecipientDescriptor>;
  approvedTokensVolume: ApprovedTokensVolumeDescriptor;
  transactionGas: TransactionGasDescriptor | null;
  transactionRisk: number | null;
  tokensFairPrice: TokenPricePredictionEntity[] | null;
  transactionRiskGroup: RiskGroup;
  suspiciousActivities: Web3SuspiciousActivityEntity[];
  transactionType: TransactionRequestMethod | null;
  detectors: TransactionDetectors;
  contractDEXs: ContractDEXDescriptor[] | null;
  contractViews?: ContractViews;
  simulationTraces?: TraceSimulations[];
  manualId?: string | null;

  detectorsMessageDescriptor: DetectorsMessageAnalysis[];
}

export type TransactionETHTransferAnalysis = {
  transactionType: TransactionRequestMethod.ETHTransfer;

  transactionRiskGroup: RiskGroup;

  toIsAContract: boolean;

  detectors: ETHTransferDetectors;

  ethToUSDCoeff: string | null;

  manualId?: string | null;
  detectorsMessageDescriptor: DetectorsMessageAnalysis[];

  transaction: Transaction;
}

export type AnalyzeTransactionResponseType = AnalyzeTransactionResponse | TransactionETHTransferAnalysis;

export interface AnalyzeApproveRequestItem {
  contractAddress: string;

  spenderAddress: string;

  amount: string;

  walletAddress: string;

  isSpenderWallet?: boolean;

}

export class ApproveDetectors {

  dangerousWebsite: RiskGroup;

  suspiciousApprove: RiskGroup;

  suspiciousAddress: RiskGroup;

  zeroAddress: RiskGroup;

  poisoningAttack: RiskGroup;

  suspiciousDeployers: RiskGroup;

  walletDrainer: RiskGroup;

  newlyCreatedSite: RiskGroup;

}

export interface AnalyzeApproveResponse {
  detectors: ApproveDetectors;

  riskGroup: RiskGroup;

  contracts: Web3ContractEntityDTO[];

  approves: AnalyzeApproveRequestItem[];

  siteAnalysis: AnalysisDescriptor;

  suspiciousDeployers: string[];

  websiteCreationDate: Date;

  transactionType: TransactionRequestMethod.ApproveV2;

  detectorsMessageDescriptor: DetectorsMessageDescriptorItem[];

  totalRiskAdditionalCount: number;

  manualId: string;

  suspiciousActivities: Web3SuspiciousActivityEntity[];

  projects: Web3ProjectEntity[];

  ensToAddressMap: Record<string, string>;

}
