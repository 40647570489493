import { BigNumber } from 'bignumber.js';
import cn from 'classnames';
import { FC, memo } from 'react';

import { Popup } from 'shared/components/Popup';
import { MAX_VALUE_FOR_ABBREVIATE, abbreviateNumber, formatNumberWithComas } from 'shared/helpers/big-number.helpers';

import styles from './styles.module.scss';

type Props = {
  value: string | number
  className?: string
  maxValue?: number
  postfix?: string
}

const BigValue: FC<Props> = ({
  value, className, maxValue = MAX_VALUE_FOR_ABBREVIATE, postfix = '+',
}) => {
  const isBig = new BigNumber(value).isGreaterThan(maxValue);

  return isBig ? (
    <Popup
      styleType="white"
      position="bottom left"
      className={styles.popup}
      trigger={(
        <span
          className={cn(styles.wrap, styles.bigValue, className)}
        >{abbreviateNumber(Number(value), maxValue)}{postfix}
        </span>
      )}
    >
      {formatNumberWithComas(value)}
    </Popup>
  ) : (
    <span
      className={cn(styles.wrap, className)}
    >{formatNumberWithComas(value)}
    </span>
  );
};

BigValue.defaultProps = {
  className: '',
  postfix: '+',
};

export default memo(BigValue);
