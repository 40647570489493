import { memo } from 'react';

import Detect from './Detect';
import styles from './styles.module.scss';
import { MessageData } from '../../../Scan/interfaces';

interface Props {
  data: Array<MessageData & { onClick?: () => void; }>;
  isOpen: boolean;
}

const Detects = ({ data, isOpen }: Props) => {
  if (data.length === 0) return null;

  return (
    <div className={styles.wrap}>
      {data.map((message) => (
        <Detect
          isOpen={isOpen}
          key={message.title}
          title={message.title || ''}
          description={message.message}
          onClick={message.onClick}
        />
      ))}
    </div>
  );
};

export default memo(Detects);
