import cn from 'classnames';
import { memo } from 'react';

import contractIcon from 'shared/assets/images/icons/contract-icon.svg';
import walletIcon from 'shared/assets/images/icons/wallet-icon.svg';
import { CUSTOM_CHAINS } from 'shared/constants/custom-chain.constants';
import { getImageUrl } from 'shared/helpers/image.helpers';

import styles from './styles.module.scss';
import { Address } from '../Address';
import { BubbleMessage } from '../BubbleMessage';
import { Image } from '../Image';
import { Label } from '../Label';

interface Props {
  name?: string
  address: string;
  isWallet?: boolean
  message?: string;
  labels?: { name: string, value: string }[];
  imageSrc?: string;
  chain?: CUSTOM_CHAINS;
}

const RecipientAddress = ({
  isWallet, address, name, message, labels, imageSrc, chain,
}: Props) => (
  <>
    <div className={styles.wrap}>
      <Image
        src={imageSrc || getImageUrl(isWallet ? walletIcon : contractIcon)}
        className={cn(styles.icon, { [styles.customIcon]: !imageSrc })}
        name={name}
        alt={name ?? ''}
        isRound
      />
      <div className={styles.value}>
        {name && <span className={styles.name}>{name}</span>}
        <Address
          address={address}
          withChainIcon={false}
          cropAddress={false}
          className={styles.address}
          chain={chain}
        />
        {Boolean(labels?.length) && (
          <div className={styles.labels}>
            {labels?.map((label) => <Label key={name} className={styles.label} {...label} />)}
          </div>
        )}
      </div>
    </div>
    {message && <BubbleMessage className={styles.message} text={message} />}
  </>
);

export default memo(RecipientAddress);
