import cn from 'classnames';
import { FC } from 'react';

import styles from './styles.module.scss';

interface Props {
  className?: string
  maskClassName?: string
  loaderClassName?: string
}
const Loader: FC<Props> = ({ className, maskClassName, loaderClassName }) => (
  <div className={cn(styles.wrap, className)}>
    <div className={cn(styles.loader, loaderClassName)}>
      <div className={cn(styles.mask, maskClassName)} />
    </div>
  </div>
);

Loader.defaultProps = {
  className: '',
  maskClassName: '',
  loaderClassName: '',
};

export default Loader;
