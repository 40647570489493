import cn from 'classnames';
import { memo } from 'react';

import GasPaymentBar from 'shared/components/GasPaymentBar';
import { GasIcon } from 'shared/constants/icons.constants';
import { formatNumberWithComas, getUSDPriceWithFallback } from 'shared/helpers/big-number.helpers';
import { withTranslation } from 'shared/helpers/i18n.helpers';

import { getRiskByOverpaymentPercent } from './helpers';
import styles from './styles.module.scss';
import { TransactionGasDetails } from '../../Scan/interfaces';
import Row from '../AdditionalInfo/Row';

interface Props {
  data: TransactionGasDetails
}

const GasDetails = ({ data }: Props) => {
  const overpaymentPercent = data.gasOverpaymentPercent || 0;
  const { risk, isIncreased } = getRiskByOverpaymentPercent(overpaymentPercent);

  const info = (
    <div className={styles.info}>
      <GasIcon className={styles.gasIcon} />
      <div className={styles.message}>
        {withTranslation('Gas fee')}: {overpaymentPercent > 0
          ? (
            <>
              <b>{formatNumberWithComas(overpaymentPercent, 1)}%
                {' '}{withTranslation('higher')}
              </b> {withTranslation('than average')}
            </>
          ) : <b>{withTranslation('is reasonable')}</b>}
      </div>
    </div>
  );

  const gasData = (
    <div className={cn(styles.data)}>
      <GasPaymentBar risk={risk} isIncreased={isIncreased} />
      <div className={cn(styles.price)}>
        {getUSDPriceWithFallback(data.gasPriceUSD, 2)}
      </div>
    </div>
  );

  return (
    <Row
      info={info}
      data={gasData}
    />
  );
};

export default memo(GasDetails);
