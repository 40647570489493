export const STORAGE_THEME = 'theme';
export const STORAGE_USER_ID = 'w3a-userId';
export const STORAGE_ANALYZED_CONTRACTS = 'analyzedContracts';
export const STORAGE_WHITELIST_FOR_WEB3_GUARD = 'whitelistForWeb3Guard';
export const STORAGE_IS_TURN_ON_WEB3_GUARD = 'isTurnOnWeb3Guard';
export const STORAGE_INSTALL_CHECKED = 'installChecked';
export const STORAGE_TOKEN = 'userToken';
export const LAST_SEEN_POISONING = 'lastSeenPoisoningAttackAt';
export const LAST_SEEN_POISONING_ID = 'lastSeenPoisoningAttackID';
export const ROUND_REFERRAL_CHECKED_ID = 'roundReferralCheckedId';
export const ROUND_REFERRAL_WIN_CONTACTED_ID = 'roundReferralWinContactedID';
export const CLOSED_BANNERS_IDS = 'closedBannersIds';

export const SESSION_WHITELIST = 'sessionWhitelist';
export const STORAGE_IS_PAUSED = 'storagePause';

export const STORAGE_ALERTS = 'storageAlerts';
export const STORAGE_ALERT_ADDRESSES = 'storageAlertAddresses';
export const STORAGE_LAST_UPDATE = 'storageLastUpdate';
export const STORAGE_APP_CONFIG = 'appConfig';
