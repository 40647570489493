import cn from 'classnames';
import { memo } from 'react';

import unanalyzedIcon from 'shared/assets/images/icons/etherscan-unanalyzed.svg';
import unverifiedIcon from 'shared/assets/images/icons/etherscan-unverified.svg';
import verifiedIcon from 'shared/assets/images/icons/etherscan-verified.svg';
import { SCAN_BY_CHAIN } from 'shared/constants/blockchain.constants';
import { CUSTOM_CHAINS } from 'shared/constants/custom-chain.constants';
import { withTranslation } from 'shared/helpers/i18n.helpers';
import { getImageUrl } from 'shared/helpers/image.helpers';
import { getEtherscanAddressUrl } from 'shared/helpers/url.helpers';

import styles from './styles.module.scss';

interface Props {
  className?: string
  isVerified?: boolean
  address?: string
  chain?: CUSTOM_CHAINS
}

const getText = (isVerified?: boolean, chain?: CUSTOM_CHAINS): string => {
  if (isVerified === undefined) return withTranslation('Unanalyzed contract');
  return isVerified
    ? `${withTranslation('Verified by')} ${chain ? SCAN_BY_CHAIN[chain] : 'Etherscan'}`
    : `${('Not verified by')} ${chain ? SCAN_BY_CHAIN[chain] : 'Etherscan'}`;
};

const getIcon = (isVerified?: boolean): string => {
  if (isVerified === undefined) return unanalyzedIcon;
  return isVerified ? verifiedIcon : unverifiedIcon;
};

const EtherscanVerification = ({
  isVerified, className, address, chain,
}: Props) => {
  const text = getText(isVerified, chain);

  return (
    <div className={cn(styles.wrap, className)}>
      <img src={getImageUrl(getIcon(isVerified))} alt="" />
      {address ? (
        <a href={getEtherscanAddressUrl(address, chain)} target="_blank" rel="noreferrer" className={styles.link}>
          {text}
        </a>
      ) : (
        <span className={styles.text}>
          {text}
        </span>
      )}
    </div>
  );
};

export default memo(EtherscanVerification);
