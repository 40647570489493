import { memo } from 'react';

import { APP_CONFIG } from 'shared/constants/common.constants';
import { withTranslation } from 'shared/helpers/i18n.helpers';

import styles from './styles.module.scss';

const Note = () => (
  <div className={styles.wrap}>
    {APP_CONFIG.IS_WEBAPP
      ? 'The information given is not meant to be advice for financial, legal, tax, accounting, or investment purposes.'
      : withTranslation('ext_remark')}
  </div>
);

export default memo(Note);
