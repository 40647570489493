import { isNull } from 'lodash';

import { CUSTOM_CHAINS } from 'shared/constants/custom-chain.constants';
import { IMAGE_BY_CHAIN } from 'shared/constants/icon.constants';
import { RISK_TYPE } from 'shared/constants/risks.constants';
import { MIN_ERC_20_LENGTH_FOR_APPROVE_ALL } from 'shared/constants/token.constants';
import { getRiskTypeFromRisk } from 'shared/helpers/analyze.helpers';
import {
  fromHexToString, fromWei, getPriceByAmount, fromWeiWithoutFormat,
} from 'shared/helpers/big-number.helpers';
import { isEmpty } from 'shared/helpers/common.helpers';
import { withTranslation } from 'shared/helpers/i18n.helpers';
import {
  ProjectAnalysisStatus, TransactionOperationsDescriptor, Web3ContractEntity, Web3ContractEntityDTO,
} from 'shared/interfaces/analyze.interfaces';
import {
  CustomChainComplexTransactionAnalysis,
  CustomChainETHTransactionAnalysis,
} from 'shared/interfaces/custom-chain.interfaces';
import { TraceWithRisk } from 'shared/interfaces/fetch.interfaces';

import { getWebsiteAlertByStatus } from './common.helpers';
import {
  getMaxRiskContract, getDangerMessages, getTransactionGasDetails, getContractsRisksMap,
  getRisks,
  getTraceWithRisks,
  getTransactionsDetails,
} from './data.helpers';
import { IRecipient } from '../../TokenActions/Approve/Recipient/interfaces';
import { getViewContractsWithInfoMap } from '../../helpers/view.helpers';
import { ProtocolRisks } from '../ScanningResult/interfaces';
import { NA_TOKEN_LABEL } from '../constants';
import {
  SwapDetails, MessageData, ApprovesDetails, TransactionDetailsData, TokenData,
} from '../interfaces';
import { DataForRenderAnalyze } from '../interfaces/analyze.interfaces';
import { DataForRenderApprove } from '../interfaces/approves.interfaces';

export const getDirectTransferData = (
  data: CustomChainETHTransactionAnalysis,
  transactionValue: string,
  toAddress: string,
  chain: CUSTOM_CHAINS,
): {
  risk: RISK_TYPE;
  swapDetails: SwapDetails;
  dangerMessages: MessageData[];
} => {
  const nativeCoinWei = transactionValue ? fromHexToString(`0x${transactionValue.replace('0x', '')}`) : '0';
  const nativeCoinAmount = transactionValue ? fromWeiWithoutFormat(nativeCoinWei, 18) : '0';
  const { risk } = getMaxRiskContract(data.riskGroup);
  const nativeCoinPriceUSD = data.nativeCoinToUSDCoeff;

  const swapDetails: SwapDetails = {
    loss: [{
      isToken: true,
      item: {
        amount: nativeCoinAmount,
        symbol: chain,
        name: chain,
        image: IMAGE_BY_CHAIN[chain],
        priceUSD: (nativeCoinPriceUSD && transactionValue)
          ? getPriceByAmount(fromWeiWithoutFormat(nativeCoinWei) as string, nativeCoinPriceUSD) : null,
      },
    }],
    income: [],
    to: {
      address: toAddress,
      isWallet: !(data.toIsAContract),
    },
  };

  return ({
    risk, swapDetails, dangerMessages: getDangerMessages(data.detectorsMessageDescriptor || []),
  });
};

const getWarningMessage = (isRecipient: boolean): string => (isRecipient
  ? 'W3A has detected suspicious activity associated with this wallet. Act carefully and make sure it is not a scam.'
  : 'W3A has detected suspicious activity associated with this contract address. Act carefully and make sure it is not a scam.');

export const getApproveRecipient = (data: CustomChainComplexTransactionAnalysis): IRecipient => {
  const recipientAddress = data.traceOperations.approves[0]?.to || '';
  const recipientData = data.approveRecipients.find((recipient) => recipient.address === recipientAddress);
  const contractData = data.contracts.find((contract) => contract.address === recipientAddress);
  // const projectData = data.projects.find((project) => project.id === contractData?.projectId);
  const isRecipient = !contractData;

  const hasWarning = data.suspiciousActivities.some((activity) => activity.address === recipientAddress);
  const warning = hasWarning ? getWarningMessage(isRecipient) : '';
  const recipient: IRecipient = {
    name: recipientData?.name || contractData?.name || null,
    address: recipientAddress,
    // if approve to recipient then can't be verified
    isAddressVerified: isRecipient ? undefined : contractData?.detectors?.externalVerification,
    transactionsCount: contractData?.numOfTransactions,
    contractCreationDate: contractData?.createdAt,
    nftsCount: isRecipient ? recipientData?.numOfTokens : undefined,
    isRecipient,
    warning,
    imageSrc: contractData?.imgURL,
  };

  return recipient;
};

export const getCustomChainApproves = (data: CustomChainComplexTransactionAnalysis): ApprovesDetails[] => {
  const {
    traceOperations, contracts,
  } = data;

  const details = (traceOperations.approves || []).reduce((acc, operation) => {
    const hasRisk = !contracts.find((contract) => operation.to === contract.address);
    const address = operation.to;

    // TODO: add when tokens are available
    // const nftsData = tokens.filter((token) => token.contractAddress === operation.contractAddress);
    // const collectionData = (data.collections || []).find((collection) => collection.contractAddress === operation.contractAddress);

    // if (nftsData.length) {
    //   const nfts = nftsData.map((nftData) => {
    //     const imageSrc = tokenService.getPreviewURL(
    //       {
    //         previewURL: nftData.url,
    //         croppedPreviewURL: nftData.croppedPreviewURL,
    //         animatedPreviewURL: nftData.animationUrl,
    //         size: ImageSize.Size560,
    //         defaultPreviewURL: nftData.url,
    //       },
    //       true,
    //     );
    //     return {
    //       isNft: true,
    //       id: nftData.externalId,
    //       hasRisk,
    //       address,
    //       approvedAsset: getNftName(nftData.externalId, nftData.name),
    //       imageSrc,
    //       collectionName: collectionData?.name,
    //     };
    //   });

    //   return [...acc, ...nfts];
    // }

    const contractData = (contracts || []).find((contract) => contract.address === operation.contractAddress);

    if (operation.value === null) {
      let approvedAsset = '';
      let name = '';
      let imageSrc = '';
      let isNft = false;

      if (!isEmpty(contractData?.type)) {
        if (contractData?.type === Web3ContractEntity.type.ERC20) {
          approvedAsset = `All of your ${contractData?.symbol || 'Tokens'}`;
          name = contractData?.symbol || '';
        }
        if ([Web3ContractEntity.type.ERC1155, Web3ContractEntity.type.ERC721].includes(contractData?.type as Web3ContractEntity.type)) {
          // TODO: add collection info
          approvedAsset = `All of your ${'Collection'} NFTs`;
          name = '';
          imageSrc = '';
          isNft = true;
        }
      }

      return [...acc, {
        hasRisk,
        address,
        approvedAsset,
        name,
        imageSrc,
        isNft,
      }];
    }

    if (contractData) {
      const isAllAssets = String(operation.value).length >= MIN_ERC_20_LENGTH_FOR_APPROVE_ALL;
      const tokenSymbol = contractData.symbol || NA_TOKEN_LABEL;
      const imageSrc = contractData.imgURL;

      return [...acc, {
        hasRisk,
        address,
        name: contractData?.name || NA_TOKEN_LABEL,
        approvedAsset: isAllAssets
          ? `${withTranslation('All of your')} ${tokenSymbol}`
          : `${fromWei(operation.value || 0, isNull(contractData?.decimals) ? 18 : Number(contractData?.decimals))} ${tokenSymbol}`,
        imageSrc,
        isHoneypot: contractData?.detectors?.honeypot?.found,
      }];

    }

    return acc;
  }, [] as ApprovesDetails[]);

  return details;
};

export const getTransactionMainDetails = (
  data: CustomChainComplexTransactionAnalysis,
  projectAddress: string,
  siteUrl?: string,
  chain?: CUSTOM_CHAINS,
): {
  transactionDetails: TransactionDetailsData;
  trace: TraceWithRisk[];
  viewContractsMap: Map<string, TokenData>;
  risks: ProtocolRisks[],
} => {

  const { contractRisksMap, contractMap } = getContractsRisksMap<Web3ContractEntityDTO>(data.contracts, data.suspiciousActivities, false);

  const viewContractsMap = getViewContractsWithInfoMap(
    contractRisksMap, projectAddress, [], [], [], [], data.contractViews, siteUrl, chain,
  );
  const risks = getRisks(projectAddress,
    viewContractsMap, [], [], [], data.contractViews, data.detectors, siteUrl, chain);
  const trace = getTraceWithRisks(data.simulationTraces || [], contractMap, contractRisksMap);
  const transactionDetails = getTransactionsDetails(
    contractMap, [], [], [], data.contracts, [], data.suspiciousActivities,
    data.traceOperations as unknown as TransactionOperationsDescriptor,
    [], data.detectorsMessageDescriptor, data.traceOperations.nativeCoin,
    data.traceOperations.receivedNativeCoin, data.traceOperations.nativeCoinToUSDCoeff, data.detectors,
    chain,
  );

  return {
    transactionDetails, trace, viewContractsMap, risks,
  };
};

export const getCustomChainApproveRenderData = (
  data: CustomChainComplexTransactionAnalysis, projectAddress?: string, siteUrl?: string,
): DataForRenderApprove => ({
  usdPrice: 0,
  websiteAlert: getWebsiteAlertByStatus(data.siteAnalysis.risk
    ? ProjectAnalysisStatus.Dangerous
    : ProjectAnalysisStatus.Validated, siteUrl),
  recipient: getApproveRecipient(data),
  approves: getCustomChainApproves(data),
  gasDetails: getTransactionGasDetails(data.transactionGas),
  risk: getRiskTypeFromRisk(data.transactionRiskGroup),
  transactionMainDetails: getTransactionMainDetails(data, projectAddress || '', siteUrl),
});

export const getCustomChainAnalyzeRenderData = (
  data: CustomChainComplexTransactionAnalysis,
  projectAddress?: string,
  siteUrl?: string,
  chain?: CUSTOM_CHAINS,
): DataForRenderAnalyze => ({
  ...getMaxRiskContract(data.transactionRiskGroup),
  transactionMainDetails: getTransactionMainDetails(data, projectAddress as string, siteUrl, chain),
  gasDetails: getTransactionGasDetails(data.transactionGas),
});
