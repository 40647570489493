/* eslint-disable no-continue */
/* eslint-disable no-restricted-syntax */

import _, { isNull } from 'lodash';

import ethIcon from 'shared/assets/images/icons/eth-icon.svg';
import questionIcon from 'shared/assets/images/icons/question-round.svg';
import { RisksData } from 'shared/components/Risks/interfaces';
import { SwapPart, SwapToken, SwapNft } from 'shared/components/SwapInfo/interfaces';
import { CUSTOM_CHAINS } from 'shared/constants/custom-chain.constants';
import { DANGER_MESSAGES_DATA } from 'shared/constants/detectors.constants';
import { IMAGE_BY_CHAIN } from 'shared/constants/icon.constants';
import { RISK_TYPE } from 'shared/constants/risks.constants';
import {
  MIN_ERC_20_LENGTH_FOR_APPROVE_ALL, TOKEN_TYPES_LABELS, TOKEN_TYPES, PAYMENT_TOKENS,
} from 'shared/constants/token.constants';
import { getRiskTypeFromRisk } from 'shared/helpers/analyze.helpers';
import {
  fromWei, fromWeiWithoutFormat, getPriceByAmount, getPercentDiffFromValues,
} from 'shared/helpers/big-number.helpers';
import { getNftName, isEmpty } from 'shared/helpers/common.helpers';
import { getCurrentLanguage, withTranslation } from 'shared/helpers/i18n.helpers';
import { getImageUrl } from 'shared/helpers/image.helpers';
import { DetectorsMessageDescriptorItem } from 'shared/interfaces/alert.interfaces';
import {
  AnalyzeTransactionResponse,
  CollectionDescriptor,
  ContractDEXDescriptor,
  ContractViews,
  DetectorsMessageAnalysis,
  OperationContractType,
  RiskGroup,
  SECURITY_LEVEL,
  TokenEntity,
  TokenPricePredictionEntity,
  TraceSimulations,
  TransactionDetectors,
  TransactionGasDescriptor,
  TransactionOperationsDescriptor,
  TransferMethodDescriptor, Web3ContractEntity, Web3ContractEntityDTO,
  Web3ProjectEntity,
  Web3SuspiciousActivityEntity,
} from 'shared/interfaces/analyze.interfaces';
import { TraceWithRisk, Trace } from 'shared/interfaces/fetch.interfaces';
import { getContractRisks } from 'shared/modules/analyze/helpers/contract.helpers';
import { ImageSize } from 'shared/services/token/shared/enums';
import { tokenService } from 'shared/services/token/token.service';

import { getRisksDataEmpty, getViewContractsWithInfoMap } from '../../helpers/view.helpers';
import { IHighlightAlert, ProtocolRisks } from '../ScanningResult/interfaces';
import {
  AnalysisStatusesForRandom,
  ANALYSIS_STATUSES,
  COLLECTION_ALERT_STUB_LABEL,
  NA_COLLECTION_LABEL,
  NA_TOKEN_LABEL,
  STATUS_DURATION_SECONDS,
  TOKEN_ALERT_STUB_LABEL,
  TOKEN_WARNING_MESSAGES,
  WARNING_MESSAGES,
  WARNING_MESSAGES_DATA,
} from '../constants';
import {
  ApprovesDetails, IDEXPair, MessageData, SwapDetails, TokenData, TransactionDetailsData, TransactionGasDetails,
} from '../interfaces';
import { DataForRenderAnalyze } from '../interfaces/analyze.interfaces';

export const getStatuses = (
  callback: (status: AnalysisStatusesForRandom) => void,
): void => {
  const statuses: Array<AnalysisStatusesForRandom> = [
    ANALYSIS_STATUSES.TRACING,
    ANALYSIS_STATUSES.SEARCHING,
    ANALYSIS_STATUSES.SCANNING,
  ];

  const statusPromise = async (value: AnalysisStatusesForRandom) => new Promise((resolve) => {
    const { from, to }: { from: number; to: number } = STATUS_DURATION_SECONDS[value];
    const timeout = (Math.random() * (to - from) + from) * 1000;

    setTimeout(() => {
      callback(value);
      resolve(null);
    }, timeout);
  });

  // eslint-disable-next-line no-void
  void statuses.reduce(
    // eslint-disable-next-line no-void
    (acc: Promise<unknown>, value: AnalysisStatusesForRandom) => acc.then(() => void statusPromise(value)),
    Promise.resolve().catch(() => null),
  );
};

export const getMaxRiskContract = (
  data: RiskGroup,
): { risk: RISK_TYPE; } => ({ risk: getRiskTypeFromRisk(data) });

export const getApproves = (
  tokens: TokenEntity[],
  contracts: Web3ContractEntityDTO[],
  traceOperations: TransactionOperationsDescriptor,
  collections: CollectionDescriptor[],
): ApprovesDetails[] => {

  const details = (traceOperations.approves || []).reduce((acc, operation) => {
    const hasRisk = !contracts.find((contract) => operation.to === contract.address);
    const address = operation.to;

    const nftsData = tokens.filter((token) => token.contractAddress === operation.contractAddress);
    const collectionData = (collections || []).find((collection) => collection.contractAddress === operation.contractAddress);

    if (nftsData.length) {
      const nfts = nftsData.map((nftData) => {
        const imageSrc = tokenService.getPreviewURL(
          {
            previewURL: nftData.url,
            croppedPreviewURL: nftData.croppedPreviewURL,
            animatedPreviewURL: nftData.animationUrl,
            size: ImageSize.Size560,
            defaultPreviewURL: nftData.url,
          },
          true,
        );
        return {
          isNft: true,
          id: nftData.externalId,
          hasRisk,
          address,
          approvedAsset: getNftName(nftData.externalId, nftData.name),
          imageSrc,
          collectionName: collectionData?.name,
        };
      });

      return [...acc, ...nfts];
    }

    const contractData = (contracts || []).find((contract) => contract.address === operation.contractAddress);

    if (operation.value === null) {
      let approvedAsset = '';
      let name = '';
      let imageSrc = '';
      let isNft = false;

      if (!isEmpty(contractData?.type)) {
        if (contractData?.type === Web3ContractEntity.type.ERC20) {
          approvedAsset = `All of your ${contractData?.symbol || 'Tokens'}`;
          name = contractData?.symbol || '';
        }
        if ([Web3ContractEntity.type.ERC1155, Web3ContractEntity.type.ERC721].includes(contractData?.type as Web3ContractEntity.type)) {
          approvedAsset = `All of your ${collectionData?.name || 'Collection'} NFTs`;
          name = collectionData?.name || '';
          imageSrc = collectionData?.logo || '';
          isNft = true;
        }
      }

      return [...acc, {
        hasRisk,
        address,
        approvedAsset,
        name,
        imageSrc,
        isNft,
        isHoneypot: contractData?.detectors?.honeypot?.found,
      }];
    }

    if (contractData) {
      const isAllAssets = String(operation.value).length >= MIN_ERC_20_LENGTH_FOR_APPROVE_ALL;
      const tokenSymbol = contractData.symbol || NA_TOKEN_LABEL;
      const imageSrc = contractData.imgURL;

      return [...acc, {
        hasRisk,
        address,
        name: contractData?.name || NA_TOKEN_LABEL,
        approvedAsset: isAllAssets
          ? `${withTranslation('All of your')} ${tokenSymbol}`
          : `${fromWei(operation.value || 0, isNull(contractData?.decimals) ? 18 : Number(contractData?.decimals))} ${tokenSymbol}`,
        imageSrc,
        isHoneypot: contractData?.detectors?.honeypot?.found,
      }];

    }

    return acc;
  }, [] as ApprovesDetails[]);

  return details;
};

const getRisksCountFromRisksData = (data: RisksData) => data.technicalRisks.count
+ data.suspiciousActivity.count
+ data.vulnerableCodeDetectors.count;

export const getRisks = (
  projectAddress: string,
  viewContractsMap: Map<string, TokenData>,
  collections: CollectionDescriptor[],
  projects: Web3ProjectEntity[],
  tokens: TokenEntity[],
  contractViews?: ContractViews,
  detectors?: TransactionDetectors,
  siteUrl?: string,
  chain?: CUSTOM_CHAINS,
): ProtocolRisks[] => {

  const getProjectsRisks = () => {
    const { viewContract, riskContract } = contractViews?.protocol?.main || {};

    if (!viewContract || !riskContract) {
      return [];
    }

    const projectData = projects.find(
      (project) => (project.id === viewContract.projectId),
    );

    // because we don't have project on other chains
    const isVerified = chain ? viewContract.securityLevel === SECURITY_LEVEL.WHITELIST : viewContract?.projectId !== null
     && viewContract?.projectId !== -1;

    const isToken = tokens.some((token) => token.contractAddress === projectAddress);
    const isCollection = collections.some((collection) => collection.contractAddress === projectAddress);

    if (isToken || isCollection) {
      return [];
    }

    const isERC20 = viewContract.type === Web3ContractEntity.type.ERC20;

    const name = isERC20 ? viewContract.name : projectData?.name;

    const risksData = viewContractsMap.get(viewContract.address)
    || getRisksDataEmpty(Boolean(contractViews?.protocol?.related?.length));
    const isAddressVerified = viewContract?.detectors?.externalVerification;

    const riskItem: ProtocolRisks = {
      risk: isAddressVerified ? RISK_TYPE.LOW : RISK_TYPE.CRITICAL,
      isVerified,
      name: name || siteUrl || window.location.hostname,
      risksCount: isVerified ? 0 : getRisksCountFromRisksData(risksData.risks),
      data: risksData.risks,
      contract: viewContract,
      label: viewContract.type === Web3ContractEntity.type.ERC20 ? TOKEN_TYPES_LABELS[
        chain === CUSTOM_CHAINS.BNB ? TOKEN_TYPES.BEP20 : TOKEN_TYPES.ERC20] : undefined,
      imageSrc: projectData?.previewURL || viewContract?.imgURL,
      isAddressVerified,
      isProject: true,
    };

    return [riskItem];
  };

  const collectionRisks = (contractViews?.collectionsList || []).filter(
    ({ main }) => {
      const hasCollectionContract = collections.find(
        (collection) => collection.contractAddress === main?.viewContract.address,
      );

      return hasCollectionContract || (main?.viewContract.type === Web3ContractEntity.type.ERC1155
    || main?.viewContract.type === Web3ContractEntity.type.ERC721);
    },
  ).reduce((acc: ProtocolRisks[], { main: { viewContract, riskContract }, related }) => {
    if (!viewContract || !riskContract) {
      return acc;
    }

    const collectionData = collections.find(
      (collection) => collection.contractAddress === viewContract.address,
    );

    const isVerified = viewContract.securityLevel === SECURITY_LEVEL.WHITELIST;
    const risksData = viewContractsMap.get(viewContract.address) || getRisksDataEmpty(Boolean(related?.length));

    const name = collectionData?.name || COLLECTION_ALERT_STUB_LABEL;

    const riskItem: ProtocolRisks = {
      risk: riskContract?.detectors?.externalVerification ? RISK_TYPE.LOW : RISK_TYPE.CRITICAL,
      name,
      risksCount: getRisksCountFromRisksData(risksData.risks),
      data: risksData.risks,
      contract: viewContract,
      isAddressVerified: riskContract?.detectors?.externalVerification,
      label: withTranslation('Collection'),
      isVerified,
      imageSrc: collectionData?.logo,
      riskContractAddress: riskContract?.address,
    };

    return [...acc, riskItem];
  }, []);

  // don't include erc20 if direct transfer
  const isDirectTransfer = Boolean(detectors?.directERC20Transfer);
  const erc20Risks = (contractViews?.erc20List || [])
    .filter(({ main }) => !isDirectTransfer && main?.viewContract.type === Web3ContractEntity.type.ERC20)
    .reduce((acc: ProtocolRisks[], { main: { viewContract, riskContract }, related }) => {

      const name = viewContract?.name || TOKEN_ALERT_STUB_LABEL;
      const isVerified = viewContract?.securityLevel === SECURITY_LEVEL.WHITELIST;

      const risksData = viewContractsMap.get(viewContract.address) || getRisksDataEmpty(Boolean(related?.length));

      const alert: ProtocolRisks = {
        risk: riskContract?.detectors?.externalVerification ? RISK_TYPE.LOW : RISK_TYPE.CRITICAL,
        name,
        risksCount: getRisksCountFromRisksData(risksData.risks),
        label: TOKEN_TYPES_LABELS[chain === CUSTOM_CHAINS.BNB ? TOKEN_TYPES.BEP20 : TOKEN_TYPES.ERC20],
        data: risksData.risks,
        contract: viewContract,
        isVerified,
        isAddressVerified: riskContract.detectors?.externalVerification,
        imageSrc: viewContract?.imgURL,
        riskContractAddress: riskContract?.address,
      };

      return [...acc, alert];
    }, []);

  return [...getProjectsRisks(), ...collectionRisks, ...erc20Risks];
};

export const getAlerts = (data: AnalyzeTransactionResponse,
  projectAddress: string,
  urlAlert: IHighlightAlert | null,
  siteUrl?: string): { alerts: IHighlightAlert[]; hasSimulationAlert: boolean } => {
  const getProjectsAlert = () => {
    const contractData = data.contracts.find(
      (contractAnalyze) => contractAnalyze.address === projectAddress,
    );

    if (!contractData) {
      return [];
    }
    const isToken = data.tokens.some((token) => token.contractAddress === projectAddress);
    const isCollection = data.collections.some((collection) => collection.contractAddress === projectAddress);

    if (isToken || isCollection) {
      return [];
    }

    const projectData = data.projects.find(
      (project) => (project.id === contractData.projectId),
    );

    const name = contractData.type === Web3ContractEntity.type.ERC20 ? contractData.name : projectData?.name;

    const alert: IHighlightAlert = {
      risk: contractData?.detectors?.externalVerification ? RISK_TYPE.LOW : RISK_TYPE.CRITICAL,
      contract: {
        verified: contractData?.detectors?.externalVerification,
        name: name ?? siteUrl ?? window.location.hostname,
      },
    };

    return [alert];
  };

  const collectionsAlerts = (data.collections || []).reduce((acc: IHighlightAlert[], collection) => {
    const isIncluded = data.traceOperations.to.some((traceOperation) => traceOperation.contractAddress === collection.contractAddress);

    if (!isIncluded) { return acc; }

    const contractData = data.contracts.find(
      (contractAnalyze) => contractAnalyze?.address === collection.contractAddress,
    );

    if (!contractData) {
      return acc;
    }

    const alert: IHighlightAlert = {
      risk: contractData?.detectors?.externalVerification ? RISK_TYPE.LOW : RISK_TYPE.CRITICAL,
      contract: {
        verified: contractData?.detectors?.externalVerification,
        name: collection.name || NA_COLLECTION_LABEL,
      },
    };

    return [alert, ...acc];
  }, []);

  const erc20Alerts = (data.contracts || [])
    .filter((contractAnalyze) => contractAnalyze.type === Web3ContractEntity.type.ERC20)
    .reduce((acc: IHighlightAlert[], contractAnalyze) => {
      const isIncluded = data.traceOperations.to.some(
        (traceOperation) => traceOperation.contractAddress === contractAnalyze.address,
      );

      if (!isIncluded) { return acc; }

      const alert: IHighlightAlert = {
        risk: contractAnalyze?.detectors?.externalVerification ? RISK_TYPE.LOW : RISK_TYPE.CRITICAL,
        contract: {
          verified: contractAnalyze?.detectors?.externalVerification,
          name: contractAnalyze.name || NA_TOKEN_LABEL,
        },
      };

      return [...acc, alert];
    }, []);

  const approvedAlerts = getApproves(data.tokens, data.contracts, data.traceOperations, data.collections)
    .map(({ hasRisk, approvedAsset }) => ({
      risk: hasRisk ? RISK_TYPE.CRITICAL : RISK_TYPE.LOW,
      text: `Approved assets: ${approvedAsset}`,
    }));

  const hasSimulationAlert = data.contracts.some(
    (contractAnalyze) => contractAnalyze.riskGroup >= RiskGroup.M,
  );
  const simulationAlert = hasSimulationAlert ? [{ risk: RISK_TYPE.CRITICAL, simulation: [] }] : [];

  const alerts = [
    ...getProjectsAlert(), ...collectionsAlerts, ...erc20Alerts, ...approvedAlerts, ...urlAlert ? [urlAlert] : [], ...simulationAlert,
  ];

  return { alerts, hasSimulationAlert };
};

export const getSimulationAlertStatus = (data: AnalyzeTransactionResponse): boolean => data.contracts.some(
  (contractAnalyze) => contractAnalyze.riskGroup >= RiskGroup.M,
);

export const getRisksSum = (data: RisksData): number => data.technicalRisks.count
  + data.suspiciousActivity.count
  + data.vulnerableCodeDetectors.count;

export const getOperationsData = (
  tokens: TokenEntity[],
  collections: CollectionDescriptor[],
  projects: Web3ProjectEntity[],
  contracts: Web3ContractEntityDTO[],
  tokensFairPrice: TokenPricePredictionEntity[] | null,
  operations: TransferMethodDescriptor[],
  suspiciousActivities: Web3SuspiciousActivityEntity[],
  chain?: CUSTOM_CHAINS,
): Array<SwapPart | null> => {

  const NFTContractTypes = [Web3ContractEntity.type.ERC721, Web3ContractEntity.type.ERC1155];
  const nftContracts = _.filter(contracts, (contract) => _.includes(NFTContractTypes, contract.type) === true);

  const mintTokens = _.filter(operations, (transfer) => {
    if (transfer.operationType === OperationContractType.ERC20) return false;
    const mintTokenWithMetadata = _.find(tokens, {
      contractAddress: transfer.contractAddress,
      externalId: transfer.tokenId ?? transfer.value,
    });

    if (_.isNil(mintTokenWithMetadata) === false && _.isNil(mintTokenWithMetadata?.id) === true) {
      return true;
    }

    const mintContract = _.find(nftContracts, { address: transfer.contractAddress });
    return _.isNil(mintContract) === false && _.isNil(mintTokenWithMetadata) === true;
  });

  let operationsData = operations.map((operation) => {
    const isMint = mintTokens.find(
      (token) => token.contractAddress === operation.contractAddress
      && (token.tokenId === operation.tokenId || token.value === operation.value),
    );

    if (isMint) return null;

    const isERC20OperationType = operation.operationType === OperationContractType.ERC20;

    const nftData = tokens.find(
      (token) => token.contractAddress === operation.contractAddress
      && (token.externalId === operation.tokenId || token.externalId === operation.value),
    );
    const contractData = contracts.find((contract) => contract.address === operation.contractAddress);
    const isHoneypot = contractData?.detectors?.honeypot?.found;

    if (nftData && !isERC20OperationType) {
      const image = tokenService.getPreviewURL(
        {
          previewURL: nftData.url,
          croppedPreviewURL: nftData.croppedPreviewURL,
          animatedPreviewURL: nftData.animationUrl,
          size: ImageSize.Size560,
        },
        false,
      );

      const collectionData = collections.find((collection) => operation.contractAddress === collection.contractAddress);
      const projectData = projects.find((project) => operation.projectId === project.id);
      const estimatedPriceETH = tokensFairPrice?.find(
        ({ collectionId, tokenId }) => collectionId === collectionData?.id && tokenId === nftData.id,
      )?.m_price_predict;
      const hasWarning = suspiciousActivities.find((activity) => activity.address === operation.contractAddress);
      const isWhiteList = contractData?.securityLevel === SECURITY_LEVEL.WHITELIST;

      const nft: SwapPart = {
        isToken: false,
        item: {
          address: nftData.contractAddress,
          id: nftData.externalId,
          name: nftData.name,
          image: image || collectionData?.logo || '',
          count: operation.amount || 1,
          description: collectionData?.name || NA_COLLECTION_LABEL,
          marketplaceIcon: projectData?.previewURL || getImageUrl(questionIcon),
          washTrading: nftData.isWashTrading,
          estimatedPriceETH,
          warning: hasWarning ? TOKEN_WARNING_MESSAGES.COLLECTION_SUSPICIOUS : '',
          isWhiteList,
          isHoneypot,
        },
      };

      return nft;
    }

    if (contractData) {
      const isERC20 = (contractData.type === Web3ContractEntity.type.ERC20) || isERC20OperationType;
      const amount = isERC20
        ? fromWeiWithoutFormat(operation.value, isEmpty(contractData?.decimals) ? 18 : Number(contractData?.decimals)) : null;
      const priceUSD = contractData?.lastPriceUSD ? getPriceByAmount(String(amount) || '0', contractData?.lastPriceUSD) : null;
      const taxPercent = !isEmpty(contractData?.detectors?.honeypot?.buyTax) || !isEmpty(contractData?.detectors?.honeypot?.saleTax) ? {
        buy: isEmpty(contractData?.detectors?.honeypot?.buyTax) ? null : (contractData?.detectors?.honeypot?.buyTax || 0),
        sell: isEmpty(contractData?.detectors?.honeypot?.buyTax) ? null : (contractData?.detectors?.honeypot?.saleTax || 0),
      } : null;

      const isWhiteList = contractData?.securityLevel === SECURITY_LEVEL.WHITELIST;
      let warning = '';

      if (isWhiteList) {
        const hasWarning = suspiciousActivities.find(
          (activity) => activity.address === operation.contractAddress && activity.name === 'Sanctions',
        );
        if (hasWarning) {
          warning = TOKEN_WARNING_MESSAGES.SANCTIONS_SUSPICIOUS;
        }
      } else {
        const hasWarning = suspiciousActivities.find(
          (activity) => activity.address === operation.contractAddress,
        );
        if (hasWarning) {
          warning = TOKEN_WARNING_MESSAGES.ERC_20_SUSPICIOUS;
        }
      }

      const description = `${chain === CUSTOM_CHAINS.BNB
        ? TOKEN_TYPES_LABELS[TOKEN_TYPES.BEP20]
        : TOKEN_TYPES_LABELS[TOKEN_TYPES.ERC20]} 
        ${isWhiteList ? ` • ${withTranslation('W3A Whitelist')}` : ''}${isHoneypot ? ' • Honeypot' : ''}`;

      const token: SwapToken = {
        name: contractData.name || NA_TOKEN_LABEL,
        symbol: contractData.symbol || NA_TOKEN_LABEL,
        image: contractData.imgURL,
        amount,
        priceUSD,
        warning,
        taxPercent,
        isWhiteList,
        description,
        isHoneypot,
      };

      return {
        isToken: true,
        item: token,
      };
    }

    return null;
  });

  const mintTokenOperations = mintTokens.map((operation) => {
    const nftData = tokens.find(
      (token) => token.contractAddress === operation.contractAddress
      && (token.externalId === operation.tokenId || token.externalId === operation.value),
    );

    const projectData = projects.find((project) => operation.projectId === project.id);
    const hasWarning = suspiciousActivities.find((activity) => activity.address === operation.contractAddress);
    const tokenId = operation.tokenId ?? operation.value;
    const collectionData = collections.find((collection) => operation.contractAddress === collection.contractAddress);

    const mintedNft: SwapPart = {
      isToken: false,
      item: {
        address: operation.contractAddress,
        id: tokenId,
        name: '',
        image: '',
        count: operation.amount || 1,
        marketplaceIcon: projectData?.previewURL || getImageUrl(questionIcon),
        description: `#${tokenId}`,
        warning: hasWarning ? TOKEN_WARNING_MESSAGES.COLLECTION_SUSPICIOUS : '',
        isMint: true,
        mintUrl: nftData?.metaUrl,
        collectionLogo: collectionData?.logo,
        collectionName: collectionData?.name,
      },
    };
    return mintedNft;
  });

  operationsData = [...operationsData, ...mintTokenOperations];

  const nftsData = operationsData.filter((operation) => operation && !operation.isToken);
  const isOneMarketplace = new Set(nftsData.map((operation) => (operation?.item as SwapNft).marketplaceIcon)).size === 1;

  if (isOneMarketplace) {
    operationsData = operationsData.map((operation) => {
      if (operation && !operation.isToken) {
        return ({
          ...operation,
          item: {
            ...operation.item,
            marketplaceIcon: undefined,
          },
        }) as { isToken: false, item: SwapNft };
      }

      return operation;
    });
  }

  return operationsData as Array<SwapPart | null>;
};

const getETHData = (amount: string, ethPriceUSD: string | null, chain?: CUSTOM_CHAINS): SwapPart | null => {
  const ethToken: SwapToken | null = Number(amount)
    ? {
      amount: fromWei(amount),
      symbol: chain || PAYMENT_TOKENS.ETH,
      name: chain || 'Ethereum',
      image: chain ? IMAGE_BY_CHAIN[chain] : getImageUrl(ethIcon),
      priceUSD: ethPriceUSD ? getPriceByAmount(fromWeiWithoutFormat(amount) as string, ethPriceUSD) : null,
    } : null;
  const ethData: SwapPart | null = ethToken ? { isToken: true, item: ethToken } : null;

  return ethData;
};

const getToAddressData = (traceOperations: TransactionOperationsDescriptor, contracts: Web3ContractEntityDTO[]) => {
  const toAddress = traceOperations.from[0]?.to;
  const contractData = contracts.find((contract) => contract.address === toAddress);

  const isWallet = !contractData;

  return ({
    address: toAddress,
    isWallet,
  });
};

const getDexPairs = (
  contractDEXs: ContractDEXDescriptor[] | null,
  contractAnalysesMap: Map<string, Web3ContractEntityDTO>,
): IDEXPair[] => {

  const pairs: IDEXPair[] = (contractDEXs || []).map((pair) => (
    {
      address: pair.address,
      pairsCount: pair.numOfPairs,
      name: contractAnalysesMap.get(pair.address)?.symbol || '',
    }));
  return pairs;
};

export const getSwapDetails = (
  contractsMap: Map<string, Web3ContractEntityDTO>,
  tokens: TokenEntity[],
  collections: CollectionDescriptor[],
  projects: Web3ProjectEntity[],
  contracts: Web3ContractEntityDTO[],
  tokensFairPrice: TokenPricePredictionEntity[] | null,
  suspiciousActivities: Web3SuspiciousActivityEntity[],
  traceOperations: TransactionOperationsDescriptor,
  contractDEXs: ContractDEXDescriptor[] | null,
  sendNativeCoin: string,
  receivedNativeCoin: string,
  nativeCountToUSDCoeff: string | null,
  detectors?: TransactionDetectors,
  chain?: CUSTOM_CHAINS,
): SwapDetails => {
  const ethFrom = getETHData(sendNativeCoin, nativeCountToUSDCoeff, chain);
  const ethTo = getETHData(receivedNativeCoin, nativeCountToUSDCoeff, chain);

  const isDirectTransfer = Boolean(detectors?.directERC20Transfer);
  const income = isDirectTransfer ? [] : getOperationsData(
    tokens, collections, projects, contracts, tokensFairPrice, traceOperations.to, suspiciousActivities, chain,
  ).filter((item) => item) as SwapPart[];
  const loss = getOperationsData(
    tokens, collections, projects, contracts, tokensFairPrice, traceOperations.from, suspiciousActivities,
    chain,
  ).filter((item) => item) as SwapPart[];
  const to = isDirectTransfer ? getToAddressData(traceOperations, contracts) : undefined;

  const dexPairs = getDexPairs(contractDEXs, contractsMap);

  return ({
    loss: [...(ethFrom ? [ethFrom] : []), ...loss],
    income: [...(ethTo ? [ethTo] : []), ...income],
    to,
    dexPairs,
  });
};

export const getDangerMessages = (
  detectors: DetectorsMessageAnalysis[],
): MessageData[] => {
  const currentLanguage = getCurrentLanguage();
  const isEngLang = currentLanguage === 'en-GB';
  const messages: MessageData[] = (detectors || []).reduce((acc, detector) => {
    const detectorData = DANGER_MESSAGES_DATA[detector.name];
    const title = (isEngLang ? detector.title : detectorData?.title) || detector.title || detectorData?.title;
    const message = (isEngLang ? detector.message : detectorData?.message) || detector.message || detectorData?.message;

    if (title || message) {
      return [...acc, {
        title,
        message,
        address: detector.address,
      }];
    }
    return acc;
  }, [] as MessageData[]);

  return messages;
};

const getWarningMessages = (traceOperations: TransactionOperationsDescriptor,
  contractAnalysesMap: Map<string, Web3ContractEntityDTO>): MessageData[] => {
  const messages = [];

  // NOTE: checking only contracts where user gets tokens due to these contracts can restrict some functionality
  for (const { contractAddress } of traceOperations.to) {
    const contractData = contractAnalysesMap.get(contractAddress);
    if (!contractData || contractData?.type !== Web3ContractEntity.type.ERC20) {
      continue;
    }

    if (contractData?.detectors?.balanceLock?.tradingCooldown) {
      messages.push(WARNING_MESSAGES_DATA[WARNING_MESSAGES.TRADING_COOLDOWN]);
    }

  }

  return messages;
};

export const getTraceWithRisks = (
  trace: TraceSimulations[],
  contractsAnalyzeMap: Map<string, Web3ContractEntityDTO>,
  contractRisksMap: Map<string, { count: number; risks: RisksData }>,
): TraceWithRisk[] => {
  const traceData = trace.map((traceItem: Trace) => {
    const contractAnalyzeData = contractsAnalyzeMap.get(traceItem.method?.address);
    const totalRisk = contractAnalyzeData?.riskGroup || RiskGroup.L;
    const contractRisks = contractRisksMap.get(traceItem.method?.address);
    const transactionsCount = contractAnalyzeData?.numOfTransactions;

    const createdAt = contractAnalyzeData?.createdAt;
    const contractName = contractAnalyzeData?.name;
    const isAddressVerified = contractAnalyzeData?.detectors?.externalVerification;
    const contractInfo = (createdAt || transactionsCount || contractName) ? {
      transactionsCount, createdAt, contractName, isAddressVerified,
    } : null;
    const implementationContract = contractAnalyzeData?.implementationAddress;

    return ({
      ...traceItem, totalRisk, contractRisks, contractInfo, implementationContract,
    });
  });

  return traceData;
};

export const getTransactionsDetails = (
  contractsMap: Map<string, Web3ContractEntityDTO>,
  tokens: TokenEntity[],
  collections: CollectionDescriptor[],
  projects: Web3ProjectEntity[],
  contracts: Web3ContractEntityDTO[],
  tokensFairPrice: TokenPricePredictionEntity[] | null,
  suspiciousActivities: Web3SuspiciousActivityEntity[],
  traceOperations: TransactionOperationsDescriptor,
  contractDEXs: ContractDEXDescriptor[] | null,
  detectorsMessageDescriptor: DetectorsMessageDescriptorItem[],
  sendNativeCoin: string,
  receivedNativeCoin: string,
  nativeCountToUSDCoeff?: string | number | null,
  detectors?: TransactionDetectors,
  chain?: CUSTOM_CHAINS,
): TransactionDetailsData => {
  const swap = getSwapDetails(contractsMap, tokens, collections,
    projects, contracts, tokensFairPrice, suspiciousActivities,
    traceOperations, contractDEXs, sendNativeCoin, receivedNativeCoin,
    nativeCountToUSDCoeff as string, detectors, chain);

  const dangerMessages = getDangerMessages(detectorsMessageDescriptor || []);

  return ({
    swap,
    permissionRequest: getApproves(tokens, contracts, traceOperations, collections),
    dangerMessages,
    warningMessages: getWarningMessages(traceOperations, contractsMap),
  });
};

export const getContractsRisksMap = <T extends { address: string, name?: string, detectors?: any }>(
  contracts: T[], suspiciousActivities: Web3SuspiciousActivityEntity[],
  strictRisksCheck = true,
): {
  contractMap: Map<string, T>;
  contractRisksMap: Map<string, {
    count: number;
    risks: RisksData;
  }>;
} => {
  const contractMap = new Map<string, T>();
  const contractRisksMap = new Map<string, { count: number; risks: RisksData }>();

  for (const contractData of contracts) {
    contractMap.set(contractData.address, contractData);
    const risk = getContractRisks(
      {
        address: contractData?.address,
        name: contractData?.name,
        isVerified: contractData?.detectors?.externalVerification,
      }, suspiciousActivities, strictRisksCheck, contractData?.detectors, contracts, false,
    );
    contractRisksMap.set(contractData.address, risk);
  }

  return { contractRisksMap, contractMap };
};

// const getViewMainContracts = (data: ContractViews): ContractViewDescriptor[] => {
//   const protocol = data.protocol?.main ? [data.protocol.main] : [];
//   const collections = data.collectionsList?.map(({ main }) => main) || [];
//   const erc20 = data.erc20List?.map(({ main }) => main) || [];

//   return [...protocol, ...collections, ...erc20];
// };

export const getTransactionMainDetails = (
  data: AnalyzeTransactionResponse,
  projectAddress: string,
  siteUrl?: string,
): {
  transactionDetails: TransactionDetailsData;
  trace: TraceWithRisk[];
  viewContractsMap: Map<string, TokenData>;
  risks: ProtocolRisks[],
} => {

  const { contractRisksMap, contractMap } = getContractsRisksMap<Web3ContractEntityDTO>(data.contracts, data.suspiciousActivities, true);

  const viewContractsMap = getViewContractsWithInfoMap(
    contractRisksMap, projectAddress,
    data.collections, data.audits, data.projects, data.tokens, data.contractViews, siteUrl,
  );
  const risks = getRisks(
    projectAddress, viewContractsMap, data.collections, data.projects,
    data.tokens, data.contractViews, data.detectors, siteUrl,
  );
  const trace = getTraceWithRisks(data.simulationTraces || [], contractMap, contractRisksMap);
  const transactionDetails = getTransactionsDetails(contractMap, data.tokens, data.collections,
    data.projects, data.contracts, data.tokensFairPrice, data.suspiciousActivities,
    data.traceOperations, data.contractDEXs, data.detectorsMessageDescriptor,
    data.traceOperations.eth,
    data.traceOperations.receivedETH, data.traceOperations.ethToUSDCoeff, data.detectors);

  return {
    transactionDetails, trace, viewContractsMap, risks,
  };
};

export const getTransactionGasDetails = (transactionGas?: TransactionGasDescriptor | null): TransactionGasDetails | null => {

  if (transactionGas) {
    const { avgGasCostUSD, currentGasCostUSD: gasPriceUSD } = transactionGas;
    const gasOverpaymentPercent = (
      gasPriceUSD
      && avgGasCostUSD
      && Number(gasPriceUSD) > Number(avgGasCostUSD)
    ) ? getPercentDiffFromValues(gasPriceUSD, avgGasCostUSD) : null;

    if (gasPriceUSD) {
      return ({
        gasOverpaymentPercent, gasPriceUSD,
      });
    }

    return null;
  }

  return null;
};

export const getAnalyzeRenderData = (
  data: AnalyzeTransactionResponse,
  projectAddress?: string,
  siteUrl?: string,
): DataForRenderAnalyze => ({
  ...getMaxRiskContract(data.transactionRiskGroup),
  transactionMainDetails: getTransactionMainDetails(data, projectAddress as string, siteUrl),
  gasDetails: getTransactionGasDetails(data.transactionGas),
});
