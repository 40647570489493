import cn from 'classnames';
import { memo } from 'react';

import alertIcon from 'shared/assets/images/icons/blacklist-circle.svg';
import unanalyzedVerifiedIcon from 'shared/assets/images/icons/etherscan-unanalyzed-white.svg';
import unanalyzedIcon from 'shared/assets/images/icons/etherscan-unanalyzed.svg';
import etherscanUnverifiedIcon from 'shared/assets/images/icons/etherscan-unverified-white.svg';
import unverifiedIcon from 'shared/assets/images/icons/etherscan-unverified.svg';
import etherscanVerifiedIcon from 'shared/assets/images/icons/etherscan-verified-white.svg';
import verifiedBlackIcon from 'shared/assets/images/icons/etherscan-verified.svg';
import verifiedIcon from 'shared/assets/images/icons/verified-shield-black.svg';
import verifiedWhiteIcon from 'shared/assets/images/icons/verified-shield.svg';
import { Popup } from 'shared/components';
import ChartWithData from 'shared/components/ChartWithData';
import { EXTENSION_CONTRACT_CHART_API } from 'shared/constants/check-nft.constants';
import { DATE_FORMAT } from 'shared/constants/global.constants';
import { THEME } from 'shared/constants/theme.constants';
import { PAYMENT_TOKENS, TOKEN_LABELS } from 'shared/constants/token.constants';
import { formatAddress } from 'shared/helpers/address.helpers';
import { formatNumberWithComas } from 'shared/helpers/big-number.helpers';
import { withTranslation } from 'shared/helpers/i18n.helpers';
import { getImageUrl } from 'shared/helpers/image.helpers';
import { getDateWithFormat } from 'shared/helpers/time.helpers';
import { getEtherscanAddressUrl } from 'shared/helpers/url.helpers';
import { useTheme } from 'shared/hooks/theme.hooks';
import { AuditsData, ContractInfo } from 'shared/modules/analyze/Scan/interfaces';

import ContractData from './ContractData';
import { EMPTY_STUB } from './constants';
import styles from './styles.module.scss';

const getVerificationLabel = (isVerified?: boolean): string => {
  if (isVerified === undefined) return withTranslation('Unanalyzed');
  return isVerified ? `${withTranslation('Verified')}` : `${('Unverified')}`;
};

const getLabels = (address: string, isAddressVerified?: boolean, isVerified?: boolean, audits?: AuditsData, hasRisk?: boolean) => {
  const labels = [];
  labels.push({ text: getVerificationLabel(isAddressVerified), link: getEtherscanAddressUrl(address) });
  if (isVerified) labels.push({ text: withTranslation('W3A whitelist') });
  if (hasRisk) labels.push({ text: withTranslation('W3A blacklist'), isWarn: true });
  if (audits && Object.values(audits).length > 0) labels.push({ text: withTranslation('Audit passed') });

  return labels.length ? labels : undefined;
};

interface Props extends ContractInfo {
  isOpen: boolean;
  withChart?: boolean;
}

const getContractIcon = (theme: THEME, isVerified?: boolean): string => {
  const isDark = theme === THEME.DARK;
  if (isVerified === undefined) return isDark ? unanalyzedIcon : unanalyzedVerifiedIcon;
  if (isVerified) {
    return isDark ? verifiedBlackIcon : etherscanVerifiedIcon;
  }
  return isDark ? unverifiedIcon : etherscanUnverifiedIcon;
};

const getIcon = (theme: THEME, hasRisk?: boolean, isVerified?: boolean): string => {
  if (isVerified) {
    return getImageUrl(theme === THEME.DARK ? verifiedWhiteIcon : verifiedIcon);
  }

  if (hasRisk) {
    return getImageUrl(alertIcon);
  }

  return '';
};

const Main = ({
  address, isOpen, audits, contractCreator, contractName,
  contractOwner, createdAt, domainName, hasRisk, isAddressVerified,
  isVerified, publicName, transactionsCount, withChart, implementationAddress,
}: Props) => {

  const { theme } = useTheme();
  const icon = getIcon(theme, hasRisk, isVerified);
  const labels = getLabels(address, isAddressVerified, isVerified, audits, hasRisk);

  return (
    <div className={cn(styles.wrap, { [styles.withChart]: withChart })}>
      <div className={styles.title}>
        <Popup
          position="top center"
          trigger={<span>{domainName || formatAddress(address)}</span>}
          content={address}
        />
        {icon && <img className={styles.icon} src={icon} alt="" />}
      </div>
      <div className={styles.labels}>
        <img
          className={styles.verifiedIcon}
          src={getImageUrl(getContractIcon(theme, isAddressVerified))}
          alt=""
        />
        {labels?.map(({ text, isWarn, link }) => {
          if (link) {
            return (
              <a key={text} href={link} target="__blank" rel="noopener noreferrer" className={cn(styles.link)}>
                {text}
              </a>
            );
          }
          return <span key={text} className={cn(styles.label, { [styles.withWarn]: isWarn })}>{text}</span>;
        })}
      </div>
      <div className={styles.mainInfo}>
        <div className={styles.item}>
          <div className={styles.label}>
            {withTranslation('Transactions')}
          </div>
          <div className={styles.value}>
            {transactionsCount ? formatNumberWithComas(transactionsCount) : EMPTY_STUB}
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.label}>
            {withTranslation('Created')}
          </div>
          <div className={styles.value}>
            {createdAt ? getDateWithFormat(createdAt, DATE_FORMAT.MONTH_YEAR) : EMPTY_STUB}
          </div>
        </div>
        <div className={styles.item}>
          <div className={styles.label}>
            {withTranslation('Public name')}
          </div>
          <div className={styles.value}>
            {publicName || EMPTY_STUB}
          </div>
        </div>
      </div>
      {withChart && (
        <ChartWithData
          fieldsForChartMap={{ bar: 'transactions', line: 'ethTransfers' }}
          apiUrl={EXTENSION_CONTRACT_CHART_API.replace('[contractAddress]', String(address))}
          title={withTranslation('Contract activity')}
          barsLabel={withTranslation('Number of transactions')}
          linesLabel={withTranslation('ETH transfers amount')}
          linesTickPrefix={TOKEN_LABELS[PAYMENT_TOKENS.ETH]}
        />
      )}
      {isOpen && (
        <ContractData
          address={address}
          contractName={contractName}
          contractCreator={contractCreator}
          contractOwner={contractOwner}
          audits={audits}
          implementationAddress={implementationAddress}
        />
      )}
    </div>
  );
};

export default memo(Main);
