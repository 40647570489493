import { THEME } from 'shared/constants/theme.constants';

import {
  IconDiscard, IconFacebook, IconGithub, IconLinkedin, IconOpensea,
  IconReddit, IconTelegram, IconTwitter, IconUniswap, IconWebsite, IconWeChat,
  IconWebsiteWhite,
  IconTwitterWhite,
  IconGithubWhite,
  IconDiscardWhite,
  IconRedditWhite,
  IconTelegramWhite,
  IconLinkedinWhite,
  IconFacebookWhite,
  IconOpenseaWhite,
  IconUniswapWhite,
  IconWeChatWhite,
} from './icons';

export enum SOCIALS {
  WEBSITE = 'WEBSITE',
  TWITTER = 'TWITTER',
  GITHUB = 'GITHUB',
  DISCORD = 'DISCORD',
  REDDIT = 'REDDIT',
  TELEGRAM = 'TELEGRAM',
  LINKEDIN = 'LINKEDIN',
  FACEBOOK = 'FACEBOOK',
  OPENSEA = 'OPENSEA',
  UNISWAP = 'UNISWAP',
  WE_CHAT = 'WE_CHAT',
}

export const SOCIAL_LABELS = {
  [SOCIALS.WEBSITE]: 'Website',
  [SOCIALS.TWITTER]: 'Twitter',
  [SOCIALS.GITHUB]: 'GitHub',
  [SOCIALS.DISCORD]: 'Discord',
  [SOCIALS.REDDIT]: 'Reddit',
  [SOCIALS.TELEGRAM]: 'Telegram',
  [SOCIALS.LINKEDIN]: 'Linkedin',
  [SOCIALS.FACEBOOK]: 'Facebook',
  [SOCIALS.OPENSEA]: 'Opensea',
  [SOCIALS.UNISWAP]: 'Uniswap',
  [SOCIALS.WE_CHAT]: 'WeChat',
};

export const getSocialIcon = (theme: THEME) => {
  const isWhite = theme !== THEME.DARK;

  return ({
    [SOCIALS.WEBSITE]: isWhite ? IconWebsite : IconWebsiteWhite,
    [SOCIALS.TWITTER]: isWhite ? IconTwitter : IconTwitterWhite,
    [SOCIALS.GITHUB]: isWhite ? IconGithub : IconGithubWhite,
    [SOCIALS.DISCORD]: isWhite ? IconDiscard : IconDiscardWhite,
    [SOCIALS.REDDIT]: isWhite ? IconReddit : IconRedditWhite,
    [SOCIALS.TELEGRAM]: isWhite ? IconTelegram : IconTelegramWhite,
    [SOCIALS.LINKEDIN]: isWhite ? IconLinkedin : IconLinkedinWhite,
    [SOCIALS.FACEBOOK]: isWhite ? IconFacebook : IconFacebookWhite,
    [SOCIALS.OPENSEA]: isWhite ? IconOpensea : IconOpenseaWhite,
    [SOCIALS.UNISWAP]: isWhite ? IconUniswap : IconUniswapWhite,
    [SOCIALS.WE_CHAT]: isWhite ? IconWeChat : IconWeChatWhite,
  });
};

export const getStubIcons = (theme: THEME): JSX.Element[] => (theme === THEME.DARK
  ? [IconWebsiteWhite, IconTwitterWhite, IconDiscardWhite]
  : [IconWebsite, IconTwitter, IconDiscard]);
