import cn from 'classnames';
import { memo } from 'react';

import warningBlackIcon from 'shared/assets/images/icons/double-lightning-black.png';
import warningIcon from 'shared/assets/images/icons/double-lightning.png';
import { APP_CONFIG } from 'shared/constants/common.constants';
import { THEME } from 'shared/constants/theme.constants';
import { useTheme } from 'shared/hooks/theme.hooks';

import styles from './styles.module.scss';

interface Props {
  text: string;
  className?: string;
  icon?: string | null;
}

const TailIcon = (
  <svg className={styles.tail} xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
    <path d="M3.99976 10.5C0 7.5 0 0 0 0C0 0 5.99976 6 11 6L3.99976 10.5Z" fill="#F3F3F3" />
  </svg>
);

const TokenWarning = ({ className, text, icon = warningIcon }: Props) => {
  const { theme } = useTheme();
  const iconSrc = theme === THEME.DARK ? warningBlackIcon : (icon || warningIcon);

  return (
    <div className={cn(className, styles.wrap)}>
      {TailIcon}
      <div className={styles.main}>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <img src={APP_CONFIG.IS_WEBAPP ? iconSrc?.src : iconSrc} alt="" />
        {text}
      </div>
    </div>
  );
};

export default memo(TokenWarning);
