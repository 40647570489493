import cn from 'classnames';
import { memo, useMemo } from 'react';

import { withTranslation } from 'shared/helpers/i18n.helpers';

import styles from './styles.module.scss';

interface Props {
  className?: string;
  hasDetect?: boolean | null;
}

const getIcon = (isChecked: boolean): JSX.Element => <div className={cn(styles.icon, { [styles.checked]: isChecked })} />;

const RiskDetect = ({ className, hasDetect }: Props) => {
  const hasData = hasDetect !== undefined && hasDetect !== null;

  const text = useMemo(() => {
    if (hasData) {
      return withTranslation(hasDetect ? 'Yes' : 'No');
    }

    return withTranslation('No data');
  }, [hasDetect, hasData]);

  return (
    <div className={cn(styles.detect, className)}>
      {hasData && getIcon(hasDetect)}
      <span className={styles.text}>{withTranslation(text)}</span>
    </div>
  );
};

export default memo(RiskDetect);
