import { FC } from 'react';

import questionIcon from 'shared/assets/images/icons/question-round.svg';
import TokenImage from 'shared/components/TokenImage';
import { getImageUrl } from 'shared/helpers/image.helpers';

import styles from './styles.module.scss';

interface Props {
  src?: string | null;
}

const MarketplaceIcon: FC<Props> = ({ src }) => (
  <TokenImage
    src={src || getImageUrl(questionIcon)}
    className={styles.marketplaceIcon}
    stub={questionIcon}
  />
);

MarketplaceIcon.defaultProps = {
  src: '',
};

export default MarketplaceIcon;
