import cn from 'classnames';
import { memo } from 'react';

import { roundNumber } from 'shared/helpers/big-number.helpers';
import { isEmpty } from 'shared/helpers/common.helpers';
import { withTranslation } from 'shared/helpers/i18n.helpers';

import styles from './styles.module.scss';
import { Popup } from '../Popup';

interface Props {
  data: {
    buy: number | null;
    sell: number | null;
  }
}

const TAX_PERCENT_HIGH_FROM = 15;

const TaxPercent = ({ data }: Props) => (
  <div className={styles.tokenTaxes}>
    {!isEmpty(data?.buy) && (
      <Popup
        content={`${withTranslation('This percentage indicates the commission you will pay on this purchase')}.
         ${withTranslation('More than')} ${TAX_PERCENT_HIGH_FROM}% ${withTranslation('is a high tax rate')}.`}
        trigger={(
          <span className={styles.tokenTax}>
            <span className={styles.taxName}>{withTranslation('Buy tax')}</span>
            <span className={cn(styles.taxPercent, { [styles.dangerPercent]: (data?.buy || 0) >= TAX_PERCENT_HIGH_FROM })}>
              {`${roundNumber(data?.buy || 0, 0)}%`}
            </span>
          </span>
        )}
      />
    )}
    {!isEmpty(data?.sell) && (
      <Popup
        content={`${withTranslation('This percentage indicates the commission you will pay when selling this token')}.
         ${withTranslation('More than')} ${TAX_PERCENT_HIGH_FROM}% ${withTranslation('is a high tax rate')},
         ${withTranslation('while anything beyond')} 50% ${withTranslation('is considered')}
         ${withTranslation('non')}-${withTranslation('tradable')}.`}
        trigger={(
          <span className={styles.tokenTax}>
            <span className={styles.taxName}>{withTranslation('Sell tax')}</span>
            <span className={cn(styles.taxPercent, { [styles.dangerPercent]: (data?.sell || 0) >= TAX_PERCENT_HIGH_FROM })}>
              {`${roundNumber(data?.sell || 0, 0)}%`}
            </span>
          </span>
        )}
      />
    )}
  </div>
);

export default memo(TaxPercent);
