import { memo } from 'react';

import Socials from 'shared/components/Socials';
import { TSocials } from 'shared/components/Socials/interfaces';

import styles from './styles.module.scss';
import Note from '../../components/Note';

interface Props {
  socials?: TSocials
}

const Protocol = ({ socials }: Props) => (
  <div className={styles.wrap}>
    <Socials data={socials} />
    <Note />
  </div>
);

export default memo(Protocol);
