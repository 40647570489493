import cn from 'classnames';
import { FC } from 'react';

import honeypotIcon from 'shared/assets/images/icons/honeypot.svg';
import verifiedIcon from 'shared/assets/images/icons/verified-shield-black.svg';
import verifiedWhiteIcon from 'shared/assets/images/icons/verified-shield.svg';
import { THEME } from 'shared/constants/theme.constants';
import { PAYMENT_TOKENS } from 'shared/constants/token.constants';
import {
  getERC20AmountWithSymbol, formatNumberWithMinValue,
} from 'shared/helpers/big-number.helpers';
import { getNftName } from 'shared/helpers/common.helpers';
import { withTranslation } from 'shared/helpers/i18n.helpers';
import { getImageUrl } from 'shared/helpers/image.helpers';
import { getImageByTheme } from 'shared/helpers/theme.helpers';
import { getTokenUrl } from 'shared/helpers/url.helpers';
import { useTheme } from 'shared/hooks/theme.hooks';
import { SwapDetails } from 'shared/modules/analyze/Scan/interfaces';

import MarketplaceIcon from './MarketplaceIcon';
import MintedToken from './MintedToken';
import Separator from './Separator';
import TaxPercent from './TaxPercent';
import WashTrading from './WashTrading';
import { SwapNft, SwapPart, SwapToken } from './interfaces';
import styles from './styles.module.scss';
import BigValue from '../BigValue';
import { Image } from '../Image';
import { Popup } from '../Popup';
import { RecipientAddress } from '../RecipientAddress';
import { TokenWarning } from '../TokenWarning';

interface Props {
  data: SwapDetails
  className?: string;
}

const honeypotPopup = (
  <Popup
    content={
      `${withTranslation('You will not be able to transfer or sell this token due to contract restrictions or high fees')}.`
    }
    trigger={(
      <img
        src={getImageUrl(honeypotIcon)}
        alt=""
        className={styles.honeypotIcon}
      />
    )}
    className={styles.popup}
  />
);

const renderToken = (token: SwapToken, theme: THEME) => (
  <div className={cn(styles.row, styles.token)} key={token.symbol}>
    <div className={styles.data}>
      <div className={styles.tokenInfo}>
        <Image className={styles.tokenImage} isRound src={token.image} alt={token.name} name={token.name} />
        <div className={styles.tokenData}>
          <span className={styles.name}>
            <span>{token.name}</span>
            {token.isWhiteList && (
              <img
                src={getImageByTheme(theme, verifiedIcon, verifiedWhiteIcon)}
                alt=""
                className={styles.verifiedIcon}
              />
            )}
            {token.isHoneypot
            && (
              honeypotPopup
            )}
          </span>
          {token.description && <div className={styles.description}>{token.description}</div>}
        </div>
      </div>
      <div className={cn(styles.value)}>
        <div className={styles.amount}>{getERC20AmountWithSymbol(token.amount || 0, token.symbol)}</div>
        {Boolean(token.priceUSD) && <div className={styles.usdPrice}>${formatNumberWithMinValue(token.priceUSD as string)}</div>}
      </div>
    </div>
    {token.taxPercent && (
      <TaxPercent data={token.taxPercent} />
    )}
    {Boolean(token.warning) && (
      <TokenWarning text={token.warning as string} className={styles.warning} />
    )}
  </div>
);

export const SwapNftRow = ({
  isMint, id, image, name, marketplaceIcon, isWhiteList,
  address, count, washTrading, description, estimatedPriceETH, warning, symbol, amount, usdPrice,
  theme, isHoneypot, collectionLogo, collectionName,
}: SwapNft): JSX.Element => (
  <div className={cn(styles.row, styles.nft)} key={id}>
    {isMint ? (
      <MintedToken data={{
        isMint,
        id,
        image,
        name,
        marketplaceIcon,
        isWhiteList,
        address,
        count,
        washTrading,
        description,
        estimatedPriceETH,
        warning,
        collectionLogo,
        collectionName,
      }}
      />
    )
      : (
        <div className={styles.data}>
          <div className={styles.nftInfo}>
            <div className={styles.nftImageWrap}>
              <Image className={styles.nftImage} src={image} alt={name} name={name} />
              {marketplaceIcon && <MarketplaceIcon src={marketplaceIcon} />}
            </div>
            <div className={styles.nameWrap}>
              <div className={styles.top}>
                <a
                  className={cn(styles.name, styles.link)}
                  target="_blank"
                  href={id ? getTokenUrl(address, id) : undefined}
                  rel="noreferrer"
                >
                  <span>{getNftName(id || '', name)}</span>
                </a>
                {isWhiteList && (
                  <img
                    src={getImageByTheme(theme || THEME.LIGHT, verifiedIcon, verifiedWhiteIcon)}
                    alt=""
                    className={styles.verifiedIcon}
                  />
                )}
                {count > 1 && <span className={styles.count}>x<BigValue value={count} maxValue={10 ** 3} /></span>}
                {isHoneypot && honeypotPopup}
              </div>
              <div className={styles.collectionName}><span>{description}</span></div>
              {Boolean(estimatedPriceETH) && !washTrading && (
                <div className={cn(styles.label, styles.estimatedPrice)}>
                  {withTranslation('Est')}. {withTranslation('price')}
                  <span className={styles.price}>
                    {getERC20AmountWithSymbol(formatNumberWithMinValue(estimatedPriceETH || 0, 4), PAYMENT_TOKENS.ETH)}
                  </span>
                </div>
              )}
              {washTrading && <WashTrading />}
            </div>
          </div>
          {Boolean(amount || usdPrice) && (
            <div className={cn(styles.value)}>
              <div className={styles.amount}>{getERC20AmountWithSymbol(amount || 0, symbol)}</div>
              {Boolean(usdPrice) && <div className={styles.usdPrice}>${formatNumberWithMinValue(usdPrice as string)}</div>}
            </div>
          )}
        </div>
      )}
    {Boolean(warning) && (
      <TokenWarning text={warning as string} className={styles.warning} />
    )}
  </div>
);

const SwapInfo: FC<Props> = ({ className, data }) => {
  const { theme } = useTheme();

  const renderData = (items: SwapPart[], isIncome: boolean) => {

    if (items.length === 0) {
      return (
        <div className={cn(styles.row)}>
          <div className={cn(styles.data, styles.empty)}>
            <Image isRound name="" className={styles.emptyStub} />
            <span className={styles.name}>{withTranslation(isIncome ? 'Nothing back' : 'Nothing')}</span>
          </div>
          <div className={cn(styles.value)} />
        </div>
      );
    }

    return (
      items.map(({ isToken, item }) => (isToken ? renderToken(item, theme) : <SwapNftRow {...item} theme={theme} />)));
  };

  const isSwap = !(data.to);

  return (
    <>
      <div className={cn(styles.wrap, className)}>
        {renderData(data.loss, false)}
        <Separator />
        {isSwap && renderData(data.income, true)}
        {!isSwap && (
          <RecipientAddress
            name={data.to?.isWallet ? 'Wallet' : 'Contract'}
            address={data.to?.address as string}
            isWallet={data.to?.isWallet}
            message={data.to?.message}
            labels={data.to?.labels}
          />
        )}
      </div>
    </>
  );
};

export default SwapInfo;
