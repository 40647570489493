import { memo } from 'react';

import DEXPair from './DEXPair';
import styles from './styles.module.scss';
import { IDEXPair, TransactionGasDetails } from '../../Scan/interfaces';
import GasDetails from '../GasDetails';

interface Props {
  gasDetails?: TransactionGasDetails | null;
  dexPairs?: IDEXPair[] | null;
}

const AdditionalInfo = ({ gasDetails, dexPairs }: Props) => ((gasDetails || Boolean(dexPairs?.length)) ? (
  <div className={styles.wrap}>
    {gasDetails && <GasDetails data={gasDetails} />}
    {Boolean(dexPairs?.length) && dexPairs?.map((dexPair) => <DEXPair key={dexPair.address} data={dexPair} />)}
  </div>
) : null);

export default memo(AdditionalInfo);
