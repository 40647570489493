import cn from 'classnames';
import { memo, useState } from 'react';

import { CheckedCircleIcon, CopyIcon } from 'shared/constants/icons.constants';
import { copyText } from 'shared/helpers/common.helpers';
import { withTranslation } from 'shared/helpers/i18n.helpers';

import styles from './styles.module.scss';
import { Popup } from '../Popup';

interface Props {
  text: string;
  className?: string;
}

const CopyButton = ({ text, className }: Props) => {
  const [isOpenCopyPopup, setIsOpenCopyPopup] = useState(false);

  const handleCopy = async () => {
    await copyText(text);
    setIsOpenCopyPopup(true);
    setTimeout(() => setIsOpenCopyPopup(false), 1000);
  };

  return (
    <Popup
      styleType="white"
      content={`${withTranslation('Copied')}!`}
      open={isOpenCopyPopup}
      position="bottom center"
      trigger={(
        <button onClick={handleCopy} className={cn(styles.copyBtn, className)}>
          {isOpenCopyPopup ? <CheckedCircleIcon /> : <CopyIcon className={styles.copyIcon} />}
        </button>
      )}
    />
  );
};

export default memo(CopyButton);
