export const POPUP_PROTOCOL = 'chrome-extension';
export const IMAGE_DOMAIN = 'https://origin.checknft.io';
export const TWITTER_LINK = 'https://twitter.com/web3_antivirus';
export const MEDIUM_LINK = 'https://medium.com/@w3a';
export const LINKEDIN_LINK = 'https://www.linkedin.com/company/web3-antivirus/';

export const LANDING_URL = 'https://web3antivirus.io';
export const WHITELIST_DOMAINS = [];

export const REPORT_A_BUG_URL = 'https://docs.google.com/forms/d/e/1FAIpQLSdKn6jo_MVAfL_BzMKDzdxeoj9KhH5DFRkzxXb8fPzVmvCiSw/viewform';

export const DATA_API_URL = 'https://api.checknft.io/';
