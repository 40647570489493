import cn from 'classnames';
import { memo } from 'react';

import { IconBack } from './constants';
import styles from './styles.module.scss';

interface Props {
  text?: string;
  handleBackClick: () => void;
  rightContent?: JSX.Element;
  className?: string;
}

const HeaderInner = ({
  handleBackClick, text, rightContent, className,
}: Props) => (
  <div className={cn(styles.wrap, className)}>
    <div className={styles.leftContent}>
      <button className={styles.btn} onClick={handleBackClick}>{IconBack}</button>
      {text && <div className={styles.text}>{text}</div>}
    </div>
    {rightContent && <div className={styles.rightContent}>{rightContent}</div>}
  </div>
);

export default memo(HeaderInner);
