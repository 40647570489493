import { APP_CONFIG } from '../constants/common.constants';

const {
  getExtensionTranslation,
}: { getExtensionTranslation: (key: string) => string} = APP_CONFIG.IS_WEBAPP
  ? { getExtensionTranslation: (key: string): string => key } : require('./extension-i18n.helpers');

const {
  getExtensionLanguage,
}: { getExtensionLanguage: () => string} = APP_CONFIG.IS_WEBAPP
  ? { getExtensionLanguage: (): string => 'en-GB' } : require('./extension-i18n.helpers');

export const withTranslation = (key: string): string => getExtensionTranslation(key) || key;
export const getCurrentLanguage = (): string => getExtensionLanguage();
