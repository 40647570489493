import {
  memo, useCallback, useEffect, useMemo, useState,
} from 'react';

import { Header } from 'shared/components/layouts';
import { CUSTOM_CHAINS } from 'shared/constants/custom-chain.constants';
import { RISK_OPTIONS, RISK_TYPE } from 'shared/constants/risks.constants';
import { Web3ContractEntity } from 'shared/interfaces/analyze.interfaces';
import ApproveWindow from 'shared/modules/windows/Approve';

import TokenInfo from '../../TokenInfo';
import CollectionInfo from '../CollectionInfo';
import TransactionInfo from '../TransactionInfo';
import HeaderWithTabs from '../components/HeaderWithTabs';
import { RESULT_SCREENS } from '../constants';
import { getDexPairByAddress } from '../helpers/common.helpers';
import {
  TokenData,
} from '../interfaces';
import { DataForRenderApprove } from '../interfaces/approves.interfaces';

interface Props<T> {
  data: T;
  siteUrl?: string;
  setButtonsRisk?: (risk: RISK_TYPE) => void,
  projectAddress?: string;
  getDataFunc: (data: T, projectAddress?: string, siteUrl?: string, chain?: CUSTOM_CHAINS) => DataForRenderApprove
  chain?: CUSTOM_CHAINS;
  isRevoke?: boolean;
}

const ApproveWrapper = <T, >({
  data, siteUrl, setButtonsRisk, projectAddress, getDataFunc, chain, isRevoke,
}: Props<T>) => {
  const [screen, setScreen] = useState<RESULT_SCREENS>(RESULT_SCREENS.SCANNING_RESULT);
  const [contractData, setContractData] = useState<TokenData | null>(null);
  const {
    usdPrice, recipient, approves, gasDetails, risk, transactionMainDetails: {
      risks, transactionDetails, viewContractsMap, trace,
    },
  } = useMemo(() => getDataFunc(data, projectAddress, siteUrl, chain), [data]);

  const handleContractSelect = useCallback((contractAddress: string, type?: Web3ContractEntity.type) => {
    const contract = viewContractsMap.get(contractAddress);

    if (contract) {
      setContractData(contract);

      switch (type) {
        case Web3ContractEntity.type.ERC1155:
        case Web3ContractEntity.type.ERC721: {
          setScreen(RESULT_SCREENS.COLLECTION);
          break;
        }

        case Web3ContractEntity.type.ERC20: {
          setScreen(RESULT_SCREENS.ERC20);
          break;
        }

        default:
          setScreen(RESULT_SCREENS.WEBSITE_PROTOCOL);
          break;
      }
    }
  }, [viewContractsMap]);

  const handleGoBack = () => setScreen(RESULT_SCREENS.SCANNING_RESULT);

  useEffect(() => {
    if (risk && setButtonsRisk) {
      setButtonsRisk(risk);
    }
  }, [risk]);

  const getScreen = () => {
    switch (screen) {
      case RESULT_SCREENS.SCANNING_RESULT:
        return (
          <ApproveWindow
            usdPrice={usdPrice}
            recipient={recipient}
            approves={approves}
            gasDetails={gasDetails}
            risk={risk}
            isRevoke={isRevoke}
            risks={risks}
            detects={transactionDetails.dangerMessages || []}
            handleContractSelect={handleContractSelect}
            chain={chain}
          />
        );
      case RESULT_SCREENS.WEBSITE_PROTOCOL:
        return (
          <TransactionInfo
            data={contractData as TokenData}
            trace={trace}
          />
        );
      case RESULT_SCREENS.ERC20:
        return (
          <TokenInfo
            data={contractData as TokenData}
            dexPairs={getDexPairByAddress(contractData?.info.address || '', transactionDetails?.swap?.dexPairs || [])}
            trace={trace}
          />
        );
      case RESULT_SCREENS.COLLECTION:
        return <CollectionInfo data={contractData as TokenData} trace={trace} />;
      default:
        return (
          <ApproveWindow
            usdPrice={usdPrice}
            recipient={recipient}
            approves={approves}
            gasDetails={gasDetails}
            risk={risk}
            isRevoke={isRevoke}
            risks={risks}
            detects={transactionDetails.dangerMessages || []}
            handleContractSelect={handleContractSelect}
            chain={chain}
          />
        );
    }
  };

  return (
    <>
      {screen !== RESULT_SCREENS.SCANNING_RESULT ? (
        <HeaderWithTabs
          selectedAddress={contractData?.info?.address}
          text={RISK_OPTIONS[risk].title}
          handleBackClick={handleGoBack}
          risks={risks}
          onTabSelect={handleContractSelect}
        />
      ) : <Header />}
      {getScreen()}
    </>
  );
};

export default memo(ApproveWrapper) as typeof ApproveWrapper;
