export enum CUSTOM_CHAINS {
  BNB = 'BNB',
  BASE = 'Base',
}

export const CHAIN_IDS = {
  [CUSTOM_CHAINS.BNB]: '0x38',
  [CUSTOM_CHAINS.BASE]: '0x2105',
};

export const CHAIN_IDS_INT = {
  [CUSTOM_CHAINS.BNB]: 56,
  [CUSTOM_CHAINS.BASE]: 8453,
};

export const CHAIN_HEX_BY_ID = {
  [CHAIN_IDS_INT[CUSTOM_CHAINS.BNB]]: '0x38',
  [CHAIN_IDS_INT[CUSTOM_CHAINS.BASE]]: '0x2105',
};

export const EVM_CHAIN_IDS = [CHAIN_IDS[CUSTOM_CHAINS.BNB], CHAIN_IDS[CUSTOM_CHAINS.BASE]];
export const EVM_CHAIN_IDS_INT = [CHAIN_IDS_INT[CUSTOM_CHAINS.BNB], CHAIN_IDS_INT[CUSTOM_CHAINS.BASE]];
