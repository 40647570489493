import cn from 'classnames';
import { memo, ReactNode } from 'react';

import { EtherscanVerification } from 'shared/components/EtherscanVerification';
import { Image, IMAGE_SIZES } from 'shared/components/Image';
import { Popup } from 'shared/components/Popup';
import { CUSTOM_CHAINS } from 'shared/constants/custom-chain.constants';

import styles from './styles.module.scss';

interface AdditionInfo {
  name: string;
  value: string | ReactNode;
}

interface Label {
  text: string;
  isWarn?: boolean;
}
interface Props {
  additionalInfo?: AdditionInfo[];
  labels?: Label[];
  isAddressVerified?: boolean;
  imageUrl?: string;
  name: string;
  symbol?: string;
  icon?: string;
  iconTooltip?: string;
  banner?: string;
  address?: string;
  isImageRound?: boolean;
  chain?: CUSTOM_CHAINS;
}

const WrapHeader = ({
  imageUrl, name, symbol, icon, iconTooltip, isAddressVerified, additionalInfo,
  labels, banner, address, isImageRound = true, chain,
}: Props) => (
  <div
    className={cn(styles.wrap, { [styles.withBanner]: banner })}
  >
    {banner && <img className={styles.banner} src={banner} alt="" />}
    <div className={styles.container}>
      <div className={styles.imageWrap}>
        <Image src={imageUrl} size={IMAGE_SIZES.L} name={name} isRound={isImageRound} />
      </div>
      <div className={styles.main}>
        <div className={styles.nameWrap}>
          <h1 className={styles.name}>{name}</h1>
          {symbol && <span className={styles.symbol}>{symbol}</span>}
          {icon
      && (
        <Popup
          styleType="white"
          content={iconTooltip}
          position="bottom center"
          disabled={!iconTooltip}
          trigger={(
            <img className={styles.icon} src={icon} alt="" />
          )}
        />
      )}
        </div>
        {Boolean(labels?.length) && (
          <div className={styles.labels}>
            {labels?.map(({ text, isWarn }) => <span key={text} className={cn(styles.label, { [styles.withWarn]: isWarn })}>{text}</span>)}
          </div>
        )}
        <EtherscanVerification chain={chain} isVerified={isAddressVerified} address={address} />
        {Boolean(additionalInfo?.length) && (
          <div className={styles.additionalInfo}>
            {additionalInfo?.map(({ name: infoName, value }) => (
              <div className={styles.item} key={name}>
                <span className={styles.name}>{infoName}</span>
                <span className={styles.value}>{value}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  </div>
);

export default memo(WrapHeader);
