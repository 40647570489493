import cn from 'classnames';
import { memo } from 'react';

import { Popup } from 'shared/components/Popup';
import { withTranslation } from 'shared/helpers/i18n.helpers';
import { useTheme } from 'shared/hooks/theme.hooks';

import {
  getSocialIcon,
  getStubIcons,
  SOCIALS, SOCIAL_LABELS,
} from './constants';
import { TSocials } from './interfaces';
import styles from './styles.module.scss';

interface Props {
  className?: string
  data?: TSocials
}

const Socials = ({ data, className }: Props) => {
  const { theme } = useTheme();
  const socials = (Object.keys(data || {}) as SOCIALS[]).filter((key) => data?.[key]);
  const hasData = socials.length > 0;

  return (
    <div className={cn(styles.wrap, className)}>
      <div className={styles.title}>{withTranslation('Community trust')}</div>
      {hasData
        ? (
          <div className={styles.socials}>
            {socials.map((key) => {
              const icon = getSocialIcon(theme)[key];
              return (
                <Popup
                  content={SOCIAL_LABELS[key]}
                  key={key}
                  trigger={(
                    <a key={key} className={cn(styles.link)} href={data?.[key] as string} target="_blank" rel="noreferrer">
                      {icon}
                    </a>
                  )}
                />
              );
            })}
          </div>
        )
        : (
          <div className={styles.emptySocials}>
            <div className={styles.socials}>{getStubIcons(theme).map((icon) => icon)}</div>
            <div className={styles.noInfo}>{withTranslation('No information')}</div>
          </div>
        )}
    </div>
  );
};

export default memo(Socials);
