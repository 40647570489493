import {
  createContext, FC, PropsWithChildren, useContext, useEffect, useState,
} from 'react';
import Browser from 'webextension-polyfill';

import { STORAGE_THEME } from 'extension/constants/chrome-storage.constants';
import { getValueToChromeStorage, setValueToChromeStorage, subscribeChangesChromeStorage } from 'extension/helpers/chrome-storage.helpers';
import { useCurrentUrl } from 'extension/hooks/use-current-url';
import { DEFAULT_THEME, THEME } from 'shared/constants/theme.constants';
import { getShadowRoot } from 'shared/helpers/extension.helpers';

type ThemeContextType = {
  theme: string,
  setTheme: (newTheme: THEME) => void
}

const initialContextState = {
  theme: THEME.LIGHT,
  setTheme: () => null,
};

export const ThemeContext = createContext<ThemeContextType>(initialContextState);

export const useThemeData = (): ThemeContextType => useContext(ThemeContext);

const getNode = () => {
  const node = getShadowRoot();
  return node || document.body;
};

export const useTheme = (): ThemeContextType => {
  const [currentTheme, setCurrentTheme] = useState<string>(DEFAULT_THEME);
  const url = useCurrentUrl();

  useEffect(() => {
    subscribeChangesChromeStorage(STORAGE_THEME, (theme: THEME) => {
      setCurrentTheme(theme);
      const node = getNode();
      node.classList.add(theme);
      node.classList.remove(theme === THEME.DARK ? THEME.LIGHT : THEME.DARK);
    });
  }, []);

  useEffect(() => {
    getValueToChromeStorage(STORAGE_THEME, (theme: THEME) => {
      const newTheme = theme || DEFAULT_THEME;
      setCurrentTheme(newTheme);

      if (!newTheme) {
        setValueToChromeStorage(STORAGE_THEME, DEFAULT_THEME);
      }

      setCurrentTheme(newTheme);
      const node = getNode();
      node.classList.add(newTheme);

      const removeClass = newTheme === THEME.DARK ? THEME.LIGHT : THEME.DARK;
      node.classList.remove(removeClass);
    });

  }, [url]);

  const setTheme = (newThemeValue: THEME) => {
    setCurrentTheme(newThemeValue);
    setValueToChromeStorage(STORAGE_THEME, newThemeValue);
  };

  useEffect(() => {
    const node = getNode();
    // NOTE: this code is needed to set the default theme when working in http://localhost:3000
    if (!Browser.storage) {
      node.classList.add(DEFAULT_THEME);
    }
    // end

    if (!url && !node.classList.contains(THEME.LIGHT) && !node.classList.contains(THEME.DARK)) {
      node.classList.add(DEFAULT_THEME);
    }
  }, []);

  return { theme: currentTheme, setTheme };
};

export const ThemeContextProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const data = useTheme();
  return (
    <ThemeContext.Provider value={data}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useCurrentTheme = (): ThemeContextType => {
  const data = useThemeData();
  return data;
};
