import cn from 'classnames';
import { memo } from 'react';

import { getNftName } from 'shared/helpers/common.helpers';
import { getTokenUrl } from 'shared/helpers/url.helpers';
import { useMetaData } from 'shared/hooks/use-metadata';
import { ImageSize } from 'shared/services/token/shared/enums';
import { tokenService } from 'shared/services/token/token.service';

import MarketplaceIcon from './MarketplaceIcon';
import WashTrading from './WashTrading';
import { SwapNft } from './interfaces';
import styles from './styles.module.scss';
import BigValue from '../BigValue';
import { Image } from '../Image';
import { Skeleton } from '../Skeleton';

interface Props {
  data: SwapNft
}

const MintedToken = ({ data }: Props) => {
  const { data: metadata, isLoading } = data.mintUrl ? useMetaData(data.address, data.id, data.mintUrl) : { data: null, isLoading: false };

  if (isLoading) {
    return <Skeleton className={styles.nftSkeleton} />;
  }

  const imageUrl = metadata?.url ? tokenService.getPreviewURL(
    {
      previewURL: metadata?.url,
      size: ImageSize.Size560,
    },
    false,
  ) : '';

  const name = metadata?.name || data.name;
  const nftName = name ? getNftName(data.id, name) : `${data.collectionName || ''}#${data.id}`;

  return (
    <div className={styles.data}>
      <div className={styles.nftInfo}>
        <div className={styles.nftImageWrap}>
          <Image className={styles.nftImage} src={imageUrl || data.collectionLogo} name={name} alt={name} />
          {data.marketplaceIcon && <MarketplaceIcon src={data.marketplaceIcon} />}
        </div>
        <div className={styles.nameWrap}>
          <div className={styles.top}>
            <a
              className={cn(styles.name, styles.link, styles.mintedTokenName)}
              target="_blank"
              href={getTokenUrl(data.address, data.id)}
              rel="noreferrer"
            >
              {nftName}
            </a>
            {data.count > 1 && <span className={styles.count}><BigValue value={data.count} maxValue={10 ** 3} /></span>}
          </div>
          {data.description !== getNftName(data.id, name) && <div className={styles.collectionName}>{data.description}</div>}
          {data.washTrading && <WashTrading />}
        </div>
      </div>
      <div className={styles.nftsCount}>+1 NFT</div>
    </div>
  );
};

export default memo(MintedToken);
