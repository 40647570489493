import { memo } from 'react';

import Header from 'shared/components/layouts/Header/Header';
import { CUSTOM_CHAINS } from 'shared/constants/custom-chain.constants';
import { RISK_TYPE } from 'shared/constants/risks.constants';
import { Web3ContractEntity } from 'shared/interfaces/analyze.interfaces';
import { ProtocolRisks } from 'shared/modules/analyze/Scan/ScanningResult/interfaces';
import { ApprovesDetails, MessageData, TransactionGasDetails } from 'shared/modules/analyze/Scan/interfaces';
import Approve from 'shared/modules/analyze/TokenActions/Approve';
import { IRecipient } from 'shared/modules/analyze/TokenActions/Approve/Recipient/interfaces';

interface Props {
  usdPrice?: number;
  recipient: IRecipient;
  approves: ApprovesDetails[];
  gasDetails?: TransactionGasDetails | null;
  risk: RISK_TYPE;
  isRevoke?: boolean;
  risks?: ProtocolRisks[]
  detects?: MessageData[];
  handleContractSelect: (tokenAddress: string, type: Web3ContractEntity.type) => void;
  children?: JSX.Element | null;
  chain?: CUSTOM_CHAINS;
}

const ApproveWindow = ({
  usdPrice, recipient, approves, gasDetails, risk, isRevoke, risks, detects, handleContractSelect, children, chain,
}: Props) => (
  <>
    {children || <Header />}
    <Approve
      isRevoke={isRevoke}
      usdPrice={usdPrice}
      recipient={recipient}
      approves={approves}
      gasDetails={gasDetails}
      risk={risk}
      risks={risks || []}
      detects={detects || []}
      handleContractSelect={handleContractSelect}
      chain={chain}
    />
  </>
);

export default memo(ApproveWindow);
