import { memo, useMemo } from 'react';
import useSWR from 'swr';

import { useRequestWithToken } from 'shared/hooks/use-request-with-token';
import { ChartBaseResponse } from 'shared/interfaces/chart.interfaces';

import styles from './styles.module.scss';
import BarLineChart from '../BarLineChart';
import { ChartData } from '../BarLineChart/interfaces';

interface Props {
  apiUrl: string;
  barsLabel: string;
  linesLabel: string;
  title: string;
  fieldsForChartMap: {
    bar: string;
    line: string;
  }
  linesTickPrefix?: string;
}

const ChartWithData = ({
  apiUrl, barsLabel, linesLabel, title, fieldsForChartMap, linesTickPrefix,
}: Props) => {
  const { get, hasToken } = useRequestWithToken();
  const { data, isLoading } = useSWR<ChartBaseResponse>([apiUrl, hasToken], () => get<ChartBaseResponse>(apiUrl,
    { buckets: 30 }), { shouldRetryOnError: false });

  const mappedData: ChartData | undefined = useMemo(() => {
    if (data) {
      return data?.items?.map((item) => {
        const dateMilliseconds = String(new Date(item.to || new Date()).getTime());

        return ({
          x: dateMilliseconds,
          bar: Number(item[fieldsForChartMap.bar] || 0),
          line: Number(item[fieldsForChartMap.line] || 0),
        });
      });
    }

    return undefined;
  }, [data]);

  return (
    <div className={styles.wrap}>
      <div className={styles.title}>{title}</div>
      <BarLineChart
        linesTickPrefix={linesTickPrefix}
        data={mappedData}
        isLoading={isLoading}
        barsLabel={barsLabel}
        linesLabel={linesLabel}
      />
    </div>
  );
};

export default memo(ChartWithData);
