import qs from 'qs';

import { BASE_EXPLORER_URL, BNB_EXPLORER_URL, ETH_EXPLORER_URL } from 'shared/constants/blockchains.constants';
import { CHECK_NFT_COLLECTION_PATH, CHECK_NFT_TOKEN_PATH } from 'shared/constants/check-nft.constants';
import { CUSTOM_CHAINS } from 'shared/constants/custom-chain.constants';

export const getSiteName = (url?: string): string => {
  try {
    const domain = url && (new URL(url));
    return domain ? domain.origin : '';
  } catch (error) {
    return url || '';
  }
};

export const getDomainNameWithoutProtocol = (url?: string, withTopLevelDomain?: boolean): string => {
  if (!url) {
    return '';
  }

  const domain = (new URL(url));
  const hostName = domain.hostname;
  const hostNameWithoutProtocol = hostName.replace('www.', '').split('.')[0];

  return withTopLevelDomain ? hostName : hostNameWithoutProtocol;
};

export const getUrlWithoutTrailingSlash = (url: string): string => {
  const lastSymbol = url.slice(-1);
  if (lastSymbol === '/') {
    return url.slice(0, -1);
  }

  return url;
};

export const prepareUrl = (path: string, params: { [key: string]: string | number }): string => {
  const query: { [key: string]: string | number } = {};
  const result = Object
    .keys(params)
    .reduce((str, key) => {
      // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
      if (str.match(new RegExp(`:${key}`))) {
        str = str.replace(new RegExp(`:${key}`), params[key].toString());
      } else {
        query[key] = params[key];
      }
      return str;
    }, path);

  return qs.stringify(query) ? `${result}?${qs.stringify(query)}` : result;
};

export const getEtherscanAddressUrl = (address: string, chain?: CUSTOM_CHAINS): string => {
  if (chain === CUSTOM_CHAINS.BNB) {
    return `${BNB_EXPLORER_URL}/address/${address}`;
  }

  if (chain === CUSTOM_CHAINS.BASE) {
    return `${BASE_EXPLORER_URL}/address/${address}`;
  }

  return `${ETH_EXPLORER_URL}/address/${address}`;
};

export const getTokenUrl = (address: string, id: string): string => `${CHECK_NFT_TOKEN_PATH}${address}/${id}`;
export const getCollectionUrl = (address: string, id: string): string => `${CHECK_NFT_COLLECTION_PATH}?collection=${id}&query=${address}`;
export const removeSubDomainFromUrl = (url: string): string => url.replace('www.', '');
