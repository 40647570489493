import { RISK_TYPE } from 'shared/constants/risks.constants';
import { RiskGroup } from 'shared/interfaces/analyze.interfaces';
import { FAIR_LEVEL } from 'shared/modules/analyze/TokenActions/MakeOffer/constants';

import { withTranslation } from './i18n.helpers';

export const getFairLevelByPricePercentDiff = (percent: number | null): FAIR_LEVEL | null => {
  if (percent === null) return null;
  if (percent >= 30) return FAIR_LEVEL.LOW;
  if (percent >= 15 && percent < 30) return FAIR_LEVEL.MEDIUM;
  return FAIR_LEVEL.HIGH;
};

export const getRiskTypeFromRisk = (riskGroup?: RiskGroup): RISK_TYPE => {
  if (riskGroup === RiskGroup.N || riskGroup === RiskGroup.L) {
    return RISK_TYPE.LOW;
  }
  if (riskGroup === RiskGroup.M) {
    return RISK_TYPE.MIDDLE;
  }

  if (!riskGroup) return RISK_TYPE.LOW;
  return RISK_TYPE.CRITICAL;
};

const RISK_TEXTS = {
  [RISK_TYPE.LOW]: withTranslation('Low risk detected'),
  [RISK_TYPE.MIDDLE]: withTranslation('Medium risk detected'),
  [RISK_TYPE.CRITICAL]: withTranslation('High risk detected'),
};

const RISK_LABELS = {
  [RISK_TYPE.LOW]: 'Low',
  [RISK_TYPE.MIDDLE]: 'Medium',
  [RISK_TYPE.CRITICAL]: 'High',
};

export const getRiskInfoFromRisk = (risk: RiskGroup): { hasRisk: boolean; text: string; label: string; riskType: RISK_TYPE } => {
  const riskType = getRiskTypeFromRisk(risk);
  const text = RISK_TEXTS[riskType];
  const label = RISK_LABELS[riskType];

  return ({
    hasRisk: riskType !== RISK_TYPE.LOW,
    text,
    label,
    riskType,
  });
};
