import cn from 'classnames';
import { memo } from 'react';

import CopyButton from 'shared/components/CopyButton';
import { withTranslation } from 'shared/helpers/i18n.helpers';
import { ContractInfo } from 'shared/modules/analyze/Scan/interfaces';

import { EMPTY_STUB } from './constants';
import styles from './styles.module.scss';

type Props = Pick<ContractInfo, 'contractName' | 'address' | 'contractOwner' | 'contractCreator' | 'audits' | 'implementationAddress'>

const ContractData = ({
  contractName, address, contractCreator, contractOwner, audits, implementationAddress,
}: Props) => {
  const auditsList = Object.keys(audits || {});

  return (
    <div className={styles.contractData}>
      <div className={styles.item}>
        <div className={styles.label}>
          {withTranslation('Contract name')}:
        </div>
        <div className={styles.value}>
          {contractName || EMPTY_STUB}
        </div>
      </div>
      <div className={cn(styles.item, styles.addressWrap)}>
        <div className={styles.address}>
          <div className={styles.label}>
            {withTranslation('Address')}:
          </div>
          <div className={styles.value}>
            {address}
          </div>
        </div>
        <CopyButton text={address} />
      </div>
      <div className={styles.item}>
        <div className={styles.label}>
          {withTranslation('Implementation contract')}:
        </div>
        <div className={styles.value}>
          {implementationAddress || EMPTY_STUB}
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.label}>
          {withTranslation('Contract owner')}:
        </div>
        <div className={styles.value}>
          {contractOwner || EMPTY_STUB}
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.label}>
          {withTranslation('Contract creator')}:
        </div>
        <div className={styles.value}>
          {contractCreator || EMPTY_STUB}
        </div>
      </div>
      <div className={cn(styles.item, styles.auditsWrap)}>
        <span className={styles.label}>
          {withTranslation('Audits passed')}:
        </span>
        <span className={cn(styles.value, styles.audits)}>
          {auditsList.length ? auditsList.map((audit, index) => (
            <span>
              {Boolean(index) && ', '}
              <a className={styles.audit} target="__blank" href={audits?.[audit]}>{audit}</a>
            </span>
          )) : EMPTY_STUB}
        </span>
      </div>
    </div>
  );
};

export default memo(ContractData);
