import { memo } from 'react';

import styles from './styles.module.scss';

interface Props {
  barsLabel: string;
  linesLabel: string;
}

const IconLine = (
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
    <path d="M1 6.82837L6.65685 1.17151" stroke="#00A867" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);

const Labels = ({ barsLabel, linesLabel }: Props) => (
  <div className={styles.wrap}>
    <div className={styles.label}>
      <div className={styles.icon}><div className={styles.barIcon} /></div>
      <span className={styles.name}>{barsLabel}</span>
    </div>
    <div className={styles.label}>
      <div className={styles.icon}>{IconLine}</div>
      <span className={styles.name}>{linesLabel}</span>
    </div>
  </div>
);

export default memo(Labels);
