import { FC, useMemo, memo } from 'react';

import alertIcon from 'shared/assets/images/icons/blacklist-circle.svg';
import verifiedIcon from 'shared/assets/images/icons/verified-shield.svg';
import warningIcon from 'shared/assets/images/icons/warning-triangle.svg';
import BigValue from 'shared/components/BigValue';
import Risks from 'shared/components/Risks';
import Tabs from 'shared/components/Tabs';
import WrapHeader from 'shared/components/assetHeader/WrapHeader';
import { DATE_FORMAT } from 'shared/constants/global.constants';
import { PAYMENT_TOKENS, TOKEN_LABELS } from 'shared/constants/token.constants';
import { withTranslation } from 'shared/helpers/i18n.helpers';
import { getImageUrl } from 'shared/helpers/image.helpers';
import { getDateWithFormat } from 'shared/helpers/time.helpers';
import { Tab } from 'shared/interfaces/common.interfaces';
import { TraceWithRisk } from 'shared/interfaces/fetch.interfaces';

import CollectionTab from './Collection';
import styles from './styles.module.scss';
import ContractsInfo from '../components/ContractsInfo';
import { TokenData } from '../interfaces';

interface Props {
  data: TokenData;
  trace: TraceWithRisk[]
}

enum TABS_TEXTS {
  RISKS = 'DETECTS',
  CONTRACTS = 'CONTRACTS',
  COLLECTION = 'COLLECTION',
}

const CollectionInfo: FC<Props> = ({ data, trace }) => {
  const { isAddressVerified } = data.info;
  const TABS: Tab[] = useMemo(
    () => {
      const hasRisk = data.info.isUnpopular;

      const tabs = [
        {
          text: TABS_TEXTS.RISKS,
          Component: () => (
            <Risks
              warnings={data.info.warnings}
              {...data.risks}
            />
          ),
        },
        {
          text: TABS_TEXTS.CONTRACTS,
          Component: () => (
            <ContractsInfo
              mainContract={data.info.contractsInfo.main}
              relatedContracts={data.info.contractsInfo.related}
              trace={trace}
            />
          ),
        },
        {
          text: TABS_TEXTS.COLLECTION,
          icon: warningIcon,
          hasRisk,
          Component: () => (
            <CollectionTab
              hasRisk={hasRisk}
              sales={data.info.sales}
              quantity={data.info.items}
              owners={data.info.owners}
              mintingCap={data.info.minting?.cap}
              mintingTotal={data.info.minting?.total}
              socials={data.info.socials}
              collectionId={data.info.collectionId}
              description={data.info.description}
              address={data.info.address}
            />
          ),
        },
      ];

      return tabs;
    }, [data, isAddressVerified],
  );

  const getAdditionalInfo = () => {
    const { marketCapETH, createdAt } = data.info;

    if (!createdAt && !marketCapETH) return undefined;
    const additionalInfo = [];

    if (marketCapETH) {
      additionalInfo.push({
        name: withTranslation('Mkt cap'),
        value:
  <>
    {TOKEN_LABELS[PAYMENT_TOKENS.ETH]}<BigValue
      postfix=""
      maxValue={10 ** 3}
      value={marketCapETH}
      className={styles.bigValue}
    />
  </>,
      });
    }

    if (createdAt) {
      additionalInfo.push({
        name: withTranslation('Created'),
        value: getDateWithFormat(createdAt, DATE_FORMAT.MONTH_YEAR) as string,
      });
    }

    return additionalInfo;
  };

  const getLabels = () => {
    const {
      isVerified, audits, hasRisk,
    } = data.info;
    const labels = [];
    if (isVerified) labels.push({ text: withTranslation('W3A whitelist') });
    if (hasRisk) labels.push({ text: withTranslation('W3A blacklist'), isWarn: true });
    if (audits && Object.values(audits).length > 0) labels.push({ text: withTranslation('Audit passed') });

    return labels.length ? labels : undefined;
  };

  return (
    <>
      <WrapHeader
        name={data.info.name}
        isAddressVerified={isAddressVerified}
        labels={getLabels()}
        imageUrl={data.info.imageUrl}
        additionalInfo={getAdditionalInfo()}
        icon={(data.info.hasRisk || data.info.isVerified) ? getImageUrl(data.info.hasRisk ? alertIcon : verifiedIcon) : ''}
        symbol={data.info.symbol}
        banner={data.info.banner}
        address={data.info.address}
        isImageRound={false}
      />
      <Tabs data={TABS} />
    </>
  );
};

export default memo(CollectionInfo);
