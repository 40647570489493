import { getRiskTypeFromRisk } from 'shared/helpers/analyze.helpers';
import { AnalyzeTransactionResponse, TransactionRequestMethod } from 'shared/interfaces/analyze.interfaces';
import { IRecipient } from 'shared/modules/analyze/TokenActions/Approve/Recipient/interfaces';

import { getWebsiteAlertByStatus } from './common.helpers';
import { getApproves, getTransactionGasDetails, getTransactionMainDetails } from './data.helpers';
import { DataForRenderApprove } from '../interfaces/approves.interfaces';

export const getApprovePriceUsd = (data: AnalyzeTransactionResponse): number => data.approvedTokensVolume?.volumeUSD;

const getWarningMessage = (isRecipient: boolean): string => (isRecipient
  ? 'W3A has detected suspicious activity associated with this wallet. Act carefully and make sure it is not a scam.'
  : 'W3A has detected suspicious activity associated with this contract address. Act carefully and make sure it is not a scam.');

export const getApproveRecipient = (data: AnalyzeTransactionResponse): IRecipient => {
  const recipientAddress = data.traceOperations.approves[0]?.to || '';
  const recipientData = data.approveRecipients.find((recipient) => recipient.address === recipientAddress);
  const contractData = data.contracts.find((contract) => contract.address === recipientAddress);
  const projectData = data.projects.find((project) => project.id === contractData?.projectId);
  const isRecipient = !contractData;

  const hasWarning = data.suspiciousActivities.some((activity) => activity.address === recipientAddress);
  const warning = hasWarning ? getWarningMessage(isRecipient) : '';
  const recipient: IRecipient = {
    name: recipientData?.name || contractData?.name || null,
    address: recipientAddress,
    // if approve to recipient then can't be verified
    isAddressVerified: isRecipient ? undefined : contractData?.detectors?.externalVerification,
    transactionsCount: contractData?.numOfTransactions,
    contractCreationDate: contractData?.createdAt,
    nftsCount: isRecipient ? recipientData?.numOfTokens : undefined,
    isRecipient,
    warning,
    imageSrc: projectData?.previewURL || contractData?.imgURL,
  };

  return recipient;
};

export const checkDataForApprove = (data: AnalyzeTransactionResponse): boolean => (
  data.transactionType === TransactionRequestMethod.Approve || data.transactionType === TransactionRequestMethod.Revoke);

export const getApproveRenderData = (
  data: AnalyzeTransactionResponse, projectAddress?: string, siteUrl?: string,
): DataForRenderApprove => ({
  usdPrice: getApprovePriceUsd(data),
  websiteAlert: getWebsiteAlertByStatus(data.siteAnalysis.status, siteUrl),
  recipient: getApproveRecipient(data),
  approves: getApproves(data.tokens, data.contracts, data.traceOperations, data.collections),
  gasDetails: getTransactionGasDetails(data.transactionGas),
  risk: getRiskTypeFromRisk(data.transactionRiskGroup),
  transactionMainDetails: getTransactionMainDetails(data, projectAddress || '', siteUrl),
});
