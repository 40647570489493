import {
  createContext, FC, useCallback, useContext, useMemo, useState,
} from 'react';

export enum EXTENSION_MODAL_TYPES {
  DEX_PAIRS = 'DEX_PAIRS',
}

export const INITIAL_MODAL_STATE = {
  current: null,
  props: null,
};

export type ShowModal = (modal: EXTENSION_MODAL_TYPES, props?: unknown | null) => void

export type ModalsState = {
  current: EXTENSION_MODAL_TYPES | null,
  props: unknown | null
}

type UseModals = {
  state: ModalsState,
  show: ShowModal,
  hide: (() => void),
}

const initialContextState = {
  state: INITIAL_MODAL_STATE,
  show: () => null,
  hide: () => null,
};

export const ExtensionModalContext = createContext<UseModals>(initialContextState);

export const useExtensionModals = (): UseModals => useContext(ExtensionModalContext);

interface Props {
  children: JSX.Element | JSX.Element[];
  modals: JSX.Element | JSX.Element[];
}

const ExtensionModalsProvider: FC<Props> = ({ children, modals }) => {
  const [state, setState] = useState<ModalsState>(INITIAL_MODAL_STATE);

  const show: ShowModal = useCallback((current, props) => {
    setState({ current, props });
  }, [setState]);

  const hide = useCallback(() => {
    setState(INITIAL_MODAL_STATE);
  }, [setState]);

  const value = useMemo(() => ({ state, show, hide }), [state, show, hide]);

  return (
    <ExtensionModalContext.Provider value={value}>
      {state.current ? modals : children}
    </ExtensionModalContext.Provider>
  );
};

export default ExtensionModalsProvider;
