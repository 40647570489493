// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import cn from 'classnames';
import {
  memo, useCallback, useEffect, useMemo, useState,
} from 'react';

import { Header } from 'shared/components/layouts';
import { RISK_OPTIONS, RISK_TYPE } from 'shared/constants/risks.constants';
import { useExtensionScroll } from 'shared/hooks/common.hooks';
import { Web3ContractEntity } from 'shared/interfaces/analyze.interfaces';

import CollectionInfo from './CollectionInfo';
import ScanningResult from './ScanningResult';
import TransactionInfo from './TransactionInfo';
import HeaderWithTabs from './components/HeaderWithTabs';
import { RESULT_SCREENS } from './constants';
import { getDexPairByAddress } from './helpers/common.helpers';
import { TokenData } from './interfaces';
import { DataForRenderAnalyze } from './interfaces/analyze.interfaces';
import styles from './styles.module.scss';
import TokenInfo from '../TokenInfo';

interface Props<T> {
  data: T;
  projectAddress: string;
  siteUrl?: string;
  setButtonsRisk?: (risk: RISK_TYPE) => void;
  getDataFunc: (data: T, projectAddress?: string, siteUrl?: string, chain?: CUSTOM_CHAINS) => DataForRenderAnalyze;
  chain?: CUSTOM_CHAINS;
}

const AnalyzeResult = <T, >({
  data, projectAddress, siteUrl, setButtonsRisk, getDataFunc, chain,
}: Props<T>) => {
  const [screen, setScreen] = useState(RESULT_SCREENS.SCANNING_RESULT);
  const [tokenData, setTokenData] = useState<TokenData | null>(null);

  useExtensionScroll(styles.wrapper);

  const {
    risk, transactionMainDetails: {
      transactionDetails, trace, viewContractsMap, risks,
    }, gasDetails,
  } = useMemo(() => getDataFunc(data, projectAddress, siteUrl), [data, projectAddress, siteUrl]);

  const handleGoBack = useCallback(
    () => {
      setScreen(RESULT_SCREENS.SCANNING_RESULT);
    },
    [setScreen],
  );

  const handleERC20Select = useCallback(
    (tokenAddress: string) => {
      const token = viewContractsMap.get(tokenAddress);
      if (token) {
        setTokenData(token);
        setScreen(RESULT_SCREENS.ERC20);
      }
    }, [setTokenData, setScreen, data, viewContractsMap],
  );

  const handleNFTSelect = useCallback(
    (tokenAddress: string) => {
      const collectionInfo = viewContractsMap.get(tokenAddress);
      if (collectionInfo) {
        setTokenData(collectionInfo);
        setScreen(RESULT_SCREENS.COLLECTION);
      }
    }, [setTokenData, setScreen, data, viewContractsMap],
  );

  const handleWebsiteProtocolSelect = useCallback(
    (tokenAddress: string) => {
      const collection = data.collections?.find((item) => item.contractAddress === tokenAddress);
      if (collection) {
        handleNFTSelect(tokenAddress);
      } else {
        const protocolInfo = viewContractsMap.get(tokenAddress);
        if (protocolInfo) {
          setTokenData(protocolInfo);
          setScreen(RESULT_SCREENS.WEBSITE_PROTOCOL);
        }
      }
    }, [setTokenData, setScreen, viewContractsMap],
  );

  const handleTokenSelect = useCallback(
    (tokenAddress: string, type: Web3ContractEntity.type) => {
      switch (type) {
        case Web3ContractEntity.type.ERC1155:
        case Web3ContractEntity.type.ERC721: {
          handleNFTSelect(tokenAddress);
          break;
        }

        case Web3ContractEntity.type.ERC20: {
          handleERC20Select(tokenAddress);
          break;
        }

        default:
          handleWebsiteProtocolSelect(tokenAddress);
          break;
      }

    }, [handleERC20Select, handleNFTSelect, handleWebsiteProtocolSelect],
  );

  const renderScreen = useMemo(() => {
    switch (screen) {
      case RESULT_SCREENS.SCANNING_RESULT:
        return (
          <ScanningResult
            transactionDetails={transactionDetails}
            risks={risks}
            handleTokenSelect={handleTokenSelect}
            gasDetails={gasDetails}
            risk={risk}
            trace={trace}
            contractsCount={data.contracts?.length || 0}
            chain={chain}
          />
        );
      case RESULT_SCREENS.WEBSITE_PROTOCOL:
        return (
          <TransactionInfo
            data={tokenData as TokenData}
            trace={trace}
            chain={chain}
          />
        );
      case RESULT_SCREENS.ERC20:
        return (
          <TokenInfo
            chain={chain}
            data={tokenData as TokenData}
            dexPairs={getDexPairByAddress(tokenData?.info.address)}
            trace={trace}
          />
        );
      case RESULT_SCREENS.COLLECTION:
        return (
          <CollectionInfo
            chain={chain}
            data={tokenData as TokenData}
            trace={trace}
          />
        );
      default:
        return null;
    }
  }, [chain, screen, handleGoBack, risks, trace, tokenData, handleTokenSelect, transactionDetails, gasDetails, risk]);

  const getHeader = () => {
    if (screen === RESULT_SCREENS.SCANNING_RESULT) {
      return <Header />;
    }

    return (
      <HeaderWithTabs
        selectedAddress={tokenData?.info?.address}
        text={RISK_OPTIONS[risk].title}
        handleBackClick={handleGoBack}
        risks={risks}
        onTabSelect={handleTokenSelect}
      />
    );
  };

  useEffect(() => {
    if (risk && setButtonsRisk) {
      setButtonsRisk(risk);
    }
  }, [risk]);

  return (
    <>
      {getHeader()}
      <div className={cn(styles.wrapper, styles.scroll)}>
        {renderScreen}
      </div>
    </>
  );
};
export default memo(AnalyzeResult) as typeof AnalyzeResult;
