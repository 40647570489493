/* eslint-disable @typescript-eslint/ban-ts-comment */
import { uniqBy } from 'lodash';

import { SuspiciousActivityEntity } from 'shared/components/Risks/SuspiciousActivity/interfaces';
import { TECHNICAL_RISKS } from 'shared/components/Risks/TechnicalRisks/constants';
import { TechnicalRisks } from 'shared/components/Risks/TechnicalRisks/interfaces';
import { VulnerableCodeDetector, VulnerableCodeDetectorsData } from 'shared/components/Risks/VulnerableCodeDetectors/interfaces';
import { RisksData } from 'shared/components/Risks/interfaces';
import { SOCIALS } from 'shared/components/Socials/constants';
import { TSocials } from 'shared/components/Socials/interfaces';
import { CUSTOM_CHAINS } from 'shared/constants/custom-chain.constants';
import { RISK_TYPE } from 'shared/constants/risks.constants';
import {
  TOKEN_TYPES,
  TOKEN_TYPES_LABELS,
} from 'shared/constants/token.constants';
import { fromHexToString, fromWei } from 'shared/helpers/big-number.helpers';
import { withTranslation } from 'shared/helpers/i18n.helpers';
import {
  CollectionDescriptor,
  ContractViews,
  SECURITY_LEVEL,
  Socials,
  TokenEntity,
  Web3ContractAuditEntity,
  Web3ContractEntity,
  Web3ContractEntityDTO,
  Web3ProjectEntity,
} from 'shared/interfaces/analyze.interfaces';

import {
  CONTRACT_FIRST_INTERACTION_WARNING,
  CONTRACT_REDUCED_RISK_WARNING,
  NA_TOKEN_LABEL,
  NOT_ANALYZED_CONTRACT_DESCRIPTION,
  NO_COLLECTION_LABEL,
} from '../Scan/constants';
import { getAuditsByContract, getAuditsFromContract } from '../Scan/helpers/common.helpers';
import { ContractInfo, MintingData, TokenData } from '../Scan/interfaces';

const technicalRisksEmpty = Object.keys(TECHNICAL_RISKS).reduce<TechnicalRisks>((acc, key) => ({
  ...acc,
  [key]: false,
}), {} as TechnicalRisks);

export const getRisksDataEmpty = (withRelated?: boolean): {count: number, risks: RisksData} => ({
  count: 0,
  risks: {
    technicalRisks: { count: 0, data: { main: technicalRisksEmpty, related: withRelated ? technicalRisksEmpty : undefined } },
    suspiciousActivity: { count: 0, data: [] },
    vulnerableCodeDetectors: { count: 0, data: { main: [], related: withRelated ? [] : undefined } as VulnerableCodeDetectorsData },
  },
});

const getWarnings = (contract: Web3ContractEntityDTO): string[] => {
  const warnings = [];

  if (contract?.riskConfidenceReduction) {
    warnings.push(CONTRACT_REDUCED_RISK_WARNING);
  }

  if (contract?.hasInteraction === false && contract?.securityLevel !== SECURITY_LEVEL.WHITELIST) {
    warnings.push(CONTRACT_FIRST_INTERACTION_WARNING);
  }

  if (contract?.detectors?.externalVerification === undefined) {
    warnings.push(NOT_ANALYZED_CONTRACT_DESCRIPTION);
  }

  return warnings;
};

export const getContractInfoFromContract = (contract: Web3ContractEntityDTO): ContractInfo => ({
  address: contract?.address,
  domainName: contract?.domainName,
  isAddressVerified: contract?.detectors?.externalVerification,
  transactionsCount: contract?.numOfTransactions,
  createdAt: contract?.createdAt,
  publicName: contract?.publicName,
  contractName: contract?.name,
  contractOwner: contract?.contractOwnerDomainName || contract?.contractOwnerAddress,
  contractCreator: contract?.contractCreatorDomainName || contract?.contractCreatorAddress,
  audits: contract?.audits ? getAuditsFromContract(contract?.audits) : undefined,
  hasRisk: contract?.securityLevel === SECURITY_LEVEL.BLACKLIST,
  isVerified: contract?.securityLevel === SECURITY_LEVEL.WHITELIST,
  implementationAddress: contract?.implementationAddress,
});

const getContractsInfo = (contract: Web3ContractEntityDTO, relatedContracts?: Web3ContractEntityDTO[]): {
  main: ContractInfo;
  related: ContractInfo[];
} => ({
  main: getContractInfoFromContract(contract),
  related: relatedContracts?.map((relatedContract) => getContractInfoFromContract(relatedContract)) || [],
});

const getCommonData = (
  contract: Web3ContractEntityDTO,
  relatedContracts?: Web3ContractEntityDTO[],
) => ({
  symbol: contract?.symbol,
  imageUrl: contract?.imgURL,
  createdAt: contract?.createdAt,
  transactionsCount: contract?.numOfTransactions,
  hasRisk: Boolean(contract?.securityLevel === SECURITY_LEVEL.BLACKLIST),
  warnings: getWarnings(contract),
  contractsInfo: getContractsInfo(contract, relatedContracts),
});

export const getSocials = (socials?: Socials): TSocials => ({
  [SOCIALS.WEBSITE]: socials?.site,
  [SOCIALS.TWITTER]: socials?.twitter,
  [SOCIALS.GITHUB]: socials?.gitHub,
  [SOCIALS.DISCORD]: socials?.discord,
  [SOCIALS.REDDIT]: socials?.reddit,
  [SOCIALS.TELEGRAM]: socials?.telegram,
  [SOCIALS.LINKEDIN]: socials?.linkedIn,
  [SOCIALS.FACEBOOK]: socials?.facebook,
  [SOCIALS.OPENSEA]: socials?.opensea,
  [SOCIALS.UNISWAP]: socials?.uniswap,
  [SOCIALS.WE_CHAT]: socials?.weChat,
});

const mergeTechnicalRisks = (risks1: TechnicalRisks, risks2: TechnicalRisks): TechnicalRisks => {
  const merged = { ...risks1, ...risks2 };
  // eslint-disable-next-line no-restricted-syntax
  for (const key in merged) {
    // @ts-ignore
    if (risks1[key] || risks2[key]) {
    // @ts-ignore
      merged[key] = true;
    }
  }
  return merged;
};

export const getContractRisksWithRelated = (
  riskContract: Web3ContractEntityDTO,
  contractRisksMap: Map<string, {
    count: number;
    risks: RisksData;
  }>,
  relatedContracts?: Web3ContractEntityDTO[],
): {
  count: number;
  risks: RisksData;
} => {
  const mainRisks = contractRisksMap.get(riskContract.address);
  const hasRelatedContracts = Boolean(relatedContracts?.length);

  const relatedRisks = (relatedContracts || []).reduce<RisksData>((acc: RisksData, relatedContract) => {
    const contractRisks = contractRisksMap.get(relatedContract.address);
    if (!contractRisks) return acc;
    const technicalRisksMerged = mergeTechnicalRisks(
      acc.technicalRisks?.data?.main || {} as TechnicalRisks,
      contractRisks?.risks?.technicalRisks?.data?.main || {},
    );
    return ({
      technicalRisks: { count: 0, data: { main: technicalRisksMerged } },
      suspiciousActivity: {
        count: 0,
        data: [...(acc.suspiciousActivity?.data || []),
          ...(contractRisks?.risks?.suspiciousActivity?.data || [])] as SuspiciousActivityEntity[],
      },
      vulnerableCodeDetectors: {
        count: 0,
        data: {
          main: uniqBy([
            ...(acc.vulnerableCodeDetectors?.data?.main || []),
            ...(contractRisks?.risks?.vulnerableCodeDetectors?.data?.main || [])], 'name') as VulnerableCodeDetector,
        },
      },
    });

  }, {} as RisksData);

  const technicalRisksCountRelated = Object.values(relatedRisks.technicalRisks?.data?.main || []).filter((risk) => risk).length;
  const suspiciousActivityCountRelated = relatedRisks.suspiciousActivity?.data?.length || 0;
  const vulnerableCodeDetectorsCountRelated = relatedRisks.vulnerableCodeDetectors?.data?.main?.length || 0;

  const relatedRisksCount = technicalRisksCountRelated + suspiciousActivityCountRelated + vulnerableCodeDetectorsCountRelated;

  const relatedRisksData: RisksData = ({
    technicalRisks: { data: relatedRisks.technicalRisks?.data, count: technicalRisksCountRelated },
    vulnerableCodeDetectors: { data: relatedRisks.vulnerableCodeDetectors?.data, count: suspiciousActivityCountRelated },
    suspiciousActivity: { data: relatedRisks.suspiciousActivity?.data, count: vulnerableCodeDetectorsCountRelated },
  });

  if (mainRisks) {
    mainRisks.risks.suspiciousActivity.data = [
      ...(mainRisks?.risks.suspiciousActivity?.data || []),
      ...(relatedRisks.suspiciousActivity?.data || [])];
  }

  return {
    count: relatedRisksCount + (mainRisks?.count || 0),
    risks: {
      technicalRisks: {
        count: (mainRisks?.risks?.technicalRisks?.count || 0) + technicalRisksCountRelated,
        data: {
          main: mainRisks?.risks?.technicalRisks?.data?.main || technicalRisksEmpty,
          related: hasRelatedContracts ? relatedRisksData.technicalRisks?.data?.main : undefined,
        },
      },
      suspiciousActivity: {
        count: (mainRisks?.risks?.suspiciousActivity?.count || 0) + suspiciousActivityCountRelated,
        data: mainRisks?.risks.suspiciousActivity.data || [],
      },
      vulnerableCodeDetectors: {
        count: (mainRisks?.risks?.vulnerableCodeDetectors?.count || 0) + vulnerableCodeDetectorsCountRelated,
        data: {
          main: mainRisks?.risks?.vulnerableCodeDetectors?.data?.main || [],
          related: hasRelatedContracts ? relatedRisksData.vulnerableCodeDetectors.data.main : undefined,
        },
      },
    },
  };
};

const getCollectionData = (
  viewContract: Web3ContractEntityDTO,
  riskContract: Web3ContractEntityDTO,
  collections: CollectionDescriptor[],
  contractRisksMap: Map<
  string,
  {
    count: number;
    risks: RisksData;
  }
  >,
  auditsData: Web3ContractAuditEntity[],
  relatedContracts?: Web3ContractEntityDTO[],
): TokenData | null => {
  let risksData = getContractRisksWithRelated(riskContract, contractRisksMap, relatedContracts);

  if (!risksData) return null;

  const collection = collections.find(
    (item) => item.contractAddress === viewContract.address,
  );

  const name = collection?.name || NO_COLLECTION_LABEL;
  const isVerified = viewContract.securityLevel === SECURITY_LEVEL.WHITELIST;

  if (isVerified) {
    risksData = getRisksDataEmpty(Boolean(relatedContracts?.length));
  }

  const socials = getSocials(viewContract?.socials);

  const audits = getAuditsByContract(auditsData, viewContract.address);

  const mainInfo = {
    risk: riskContract?.detectors?.externalVerification
      ? RISK_TYPE.LOW
      : RISK_TYPE.CRITICAL,
    name,
    risksCount: risksData?.count,
    data: risksData?.risks,
    contract: viewContract,
    isAddressVerified: riskContract?.detectors?.externalVerification,
    label: withTranslation('Collection'),
    isVerified,
    imageUrl: collection?.logo,
    riskContractAddress: riskContract?.address,
    address: viewContract.address,
  };

  const collectionData = {
    sales: collection?.statistic?.sales,
    owners: collection?.statistic?.numOfOwners,
    marketCapUSD: collection?.statistic?.marketCapUSD,
    marketCapETH: collection?.statistic?.marketCapETH
      ? Number(collection?.statistic?.marketCapETH)
      : undefined,
    imageUrl: collection?.logo,
    items: collection?.statistic?.numOfTokens,
    id: collection?.id,
    name,
    contractName: viewContract?.name,
    isProxy: viewContract?.isProxy,
    isUnpopular: viewContract?.detectors?.unpopularCollection,
    banner: collection?.banner,
    createdAt: viewContract?.createdAt || collection?.firstEventAt,
    collectionId: collection?.id,
    description: collection?.description,
  };

  const commonData = getCommonData(
    viewContract,
    relatedContracts,
  );

  const minting: MintingData | undefined = {
    cap: viewContract?.circulatingSupply
      || null,
    total: viewContract?.totalSupply
      ? fromWei(fromHexToString(viewContract?.totalSupply), viewContract?.decimals || 18)
      : null,
  };

  const result = {
    risks: risksData.risks,
    info: {
      ...commonData,
      ...mainInfo,
      ...collectionData,
      socials,
      audits,
      ...(minting ? { minting } : {}),
    },
  };

  return result;
};

const getErc20Data = (
  viewContract: Web3ContractEntityDTO,
  riskContract: Web3ContractEntityDTO,
  contractRisksMap: Map<
  string,
  {
    count: number;
    risks: RisksData;
  }
  >,
  auditsData: Web3ContractAuditEntity[],
  relatedContracts?: Web3ContractEntityDTO[],
  chain?: CUSTOM_CHAINS,
): TokenData | null => {
  const minting: MintingData | undefined = {
    cap: viewContract?.circulatingSupply || null,
    total: viewContract?.totalSupply
      ? fromWei(fromHexToString(viewContract?.totalSupply), viewContract?.decimals || 18)
      : null,
  };

  let risksData = getContractRisksWithRelated(riskContract, contractRisksMap, relatedContracts);
  if (!risksData) return null;

  const socials = getSocials(viewContract?.socials);

  const audits = getAuditsByContract(auditsData, viewContract.address);
  const priceUSD = viewContract?.lastPriceUSD;
  const name = viewContract?.name || NA_TOKEN_LABEL;
  const isVerified = viewContract?.securityLevel === SECURITY_LEVEL.WHITELIST;

  if (isVerified) {
    risksData = getRisksDataEmpty(Boolean(relatedContracts?.length));
  }

  const mainInfo = {
    risk: riskContract?.detectors?.externalVerification
      ? RISK_TYPE.LOW
      : RISK_TYPE.CRITICAL,
    name,
    risksCount: risksData?.count,
    label: TOKEN_TYPES_LABELS[chain === CUSTOM_CHAINS.BNB ? TOKEN_TYPES.BEP20 : TOKEN_TYPES.ERC20],
    data: risksData?.risks,
    contract: viewContract,
    isVerified,
    isAddressVerified: riskContract?.detectors?.externalVerification,
    imageUrl: viewContract?.imgURL,
    riskContractAddress: riskContract?.address,
    address: viewContract?.address,
  };

  const info = {
    isProxy: viewContract?.isProxy,
    socials,
    ...(minting ? { minting } : {}),
    audits,
    priceUSD,
    marketCapUSD: viewContract?.marketCapUSD || undefined,
  };

  const commonData = getCommonData(
    viewContract,
    relatedContracts,
  );

  return {
    risks: risksData.risks,
    info: { ...commonData, ...mainInfo, ...info },
  };
};

const getProtocolData = (
  collections: CollectionDescriptor[],
  contractRisksMap: Map<
  string,
  {
    count: number;
    risks: RisksData;
  }
  >,
  projectAddress: string,
  auditsData: Web3ContractAuditEntity[],
  tokens: TokenEntity[],
  projects: Web3ProjectEntity[],
  contractViews?: ContractViews,
  siteUrl?: string,
  chain?: CUSTOM_CHAINS,
): TokenData | null => {

  const { viewContract, riskContract } = contractViews?.protocol?.main || {};

  if (!viewContract || !riskContract) return null;

  const collection = collections.find(
    (item) => item.contractAddress === viewContract.address,
  );
  if (collection) {
    return getCollectionData(
      viewContract,
      riskContract,
      collections,
      contractRisksMap,
      auditsData,
    );
  }

  const projectData = projects.find(
    (project) => project.id === viewContract.projectId,
  );

  const isVerified = chain ? viewContract.securityLevel === SECURITY_LEVEL.WHITELIST : viewContract?.projectId !== null
    && viewContract?.projectId !== -1;

  const isToken = tokens.some(
    (token) => token.contractAddress === projectAddress,
  );

  if (isToken || collection) {
    return null;
  }

  const isERC20 = viewContract.type === Web3ContractEntity.type.ERC20;

  let risksData = getContractRisksWithRelated(riskContract, contractRisksMap, contractViews?.protocol?.related);
  if (!risksData) return null;

  if (isVerified) {
    risksData = getRisksDataEmpty(Boolean(contractViews?.protocol?.related?.length));
  }

  const name = isERC20 ? viewContract.name : projectData?.name;

  const isAddressVerified = viewContract?.detectors?.externalVerification;

  const mainInfo = {
    address: viewContract?.address,
    risk: isAddressVerified ? RISK_TYPE.LOW : RISK_TYPE.CRITICAL,
    isVerified,
    name: name || siteUrl || window.location.hostname,
    risksCount: isVerified ? 0 : risksData?.count,
    data: risksData?.risks,
    contract: viewContract,
    label:
    viewContract.type === Web3ContractEntity.type.ERC20
      ? TOKEN_TYPES_LABELS[
        chain === CUSTOM_CHAINS.BNB ? TOKEN_TYPES.BEP20 : TOKEN_TYPES.ERC20]
      : undefined,
    imageUrl: projectData?.previewURL || viewContract?.imgURL,
    isAddressVerified,
    isProject: true,
  };

  const socials = getSocials(projectData?.socials || viewContract?.socials);
  const audits = getAuditsByContract(auditsData, viewContract.address);

  const info = {
    isProxy: viewContract?.isProxy,
    socials,
    audits,
  };

  const commonData = getCommonData(
    viewContract,
    contractViews?.protocol?.related,
  );

  return {
    risks: risksData?.risks,
    info: { ...commonData, ...mainInfo, ...info },
  };
};

export const getViewContractsWithInfoMap = (
  contractRisksMap: Map<
  string,
  {
    count: number;
    risks: RisksData;
  }
  >,
  projectAddress: string,
  collections: CollectionDescriptor[],
  auditsData: Web3ContractAuditEntity[],
  projects: Web3ProjectEntity[],
  tokens: TokenEntity[],
  contractViews?: ContractViews,
  siteUrl?: string,
  chain?: CUSTOM_CHAINS,
): Map<string, TokenData> => {
  const infoMap = new Map<string, TokenData>();

  contractViews?.collectionsList?.forEach(({ main, related }) => {
    const contractData = getCollectionData(
      main.viewContract,
      main.riskContract,
      collections,
      contractRisksMap,
      auditsData,
      related,
    );
    if (contractData) infoMap.set(main.viewContract.address, contractData);
  });

  contractViews?.erc20List?.forEach(({ main, related }) => {
    const contractData = getErc20Data(
      main.viewContract,
      main.riskContract,
      contractRisksMap,
      auditsData,
      related,
      chain,
    );
    if (contractData) infoMap.set(main.viewContract.address, contractData);
  });

  const protocolData = getProtocolData(
    collections,
    contractRisksMap,
    projectAddress,
    auditsData,
    tokens,
    projects,
    contractViews,
    siteUrl,
    chain,
  );
  if (protocolData?.info?.address) {
    infoMap.set(protocolData.info.address, protocolData);
  }

  return infoMap;
};
