import axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';

import { APP_CONFIG } from 'shared/constants/common.constants';

export const executeSendRequest = (method: 'post' | 'put', config?: AxiosRequestConfig) => <T>(
  urlPath: string,
  body?: Record<string, unknown>,
  headers?: Record<string, unknown>,
): Promise<T> => axios[method]<T>(
  urlPath, body ? { ...body } : undefined, { ...config, ...(headers || {}), withCredentials: false },
).then(({ data }) => data);

export const executeGetRequest = (method: 'get', config?: AxiosRequestConfig) => <T>(
  urlPath: string,
  query?: Record<string, unknown> | null,
  headers?: Record<string, unknown> | null,
): Promise<T> => {
  if (query) {
    urlPath += `?${qs.stringify(query, { encode: true, arrayFormat: 'brackets' })}`;
  }

  return axios[method]<T>(urlPath, { ...config, ...headers ?? {}, withCredentials: false }).then(({ data }) => data);
};

export const post = executeSendRequest('post');
export const put = executeSendRequest('put');
export const get = executeGetRequest('get');

const { getExtensionVersion } = APP_CONFIG.IS_WEBAPP
  ? require('shared/helpers/stub.helpers') : require('shared/helpers/browser-api.helpers.ts');

axios.interceptors.request.use((config) => {

  if (!APP_CONFIG.IS_WEBAPP) {
    if (config.headers) {
      config.headers['X-Application-Version'] = getExtensionVersion();
    }
  }

  return config;
}, (error) => Promise.reject(error));
