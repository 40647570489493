import cn from 'classnames';
import { memo } from 'react';

import logo from 'shared/assets/images/logo-black.svg';
import logoWhite from 'shared/assets/images/logo-white.svg';
import { getImageByTheme } from 'shared/helpers/theme.helpers';
import { useTheme } from 'shared/hooks/theme.hooks';

import styles from './styles.module.scss';

interface Props {
  className?: string
}

const Logo = ({ className }: Props) => {
  const { theme } = useTheme();

  return (
    <div className={cn(styles.logoWrapper, className)}>
      <img src={getImageByTheme(theme, logo, logoWhite)} alt="logo" className={styles.logo} />
    </div>
  );
};

export default memo(Logo);
