import cn from 'classnames';
import { memo } from 'react';
import { Table } from 'semantic-ui-react';
import { TableHeaderCellProps } from 'semantic-ui-react/dist/commonjs/collections/Table/TableHeaderCell';

import styles from '../../styles.module.scss';

type Props = TableHeaderCellProps & {
  alignRight?: boolean
  alignLeft?: boolean
  name?: string
  title?: string
  className?: string,
}

const TableHeadCell = ({
  children, alignRight, alignLeft, className,
  sortable, ...props
}: Props) => (
  <Table.HeaderCell
    className={cn(styles.headerCell,
      { [styles.cellRight]: alignRight },
      { [styles.alignLeft]: alignLeft },
      { [styles.cellSortable]: sortable },
      className)}
    {...props}
  >
    {children}
  </Table.HeaderCell>
);

TableHeadCell.defaultProps = {
  alignRight: false,
  alignLeft: false,
  name: '',
  title: '',
  className: '',
};

export default memo(TableHeadCell);
