import cn from 'classnames';
import { MouseEventHandler, ReactNode } from 'react';

import { BUTTON_SIZES, BUTTON_TYPES } from './constants';
import styles from './styles.module.scss';
import Loader from '../../Loader';

interface Props {
  type?: BUTTON_TYPES;
  size?: BUTTON_SIZES;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined
  children: ReactNode | JSX.Element;
  isLoading?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
}

const Button = ({
  type = BUTTON_TYPES.PRIMARY,
  size = BUTTON_SIZES.M,
  fullWidth = false,
  className,
  onClick,
  children,
  isLoading,
  ...props
}: Props): JSX.Element => (
  <button
    className={cn(
      styles.wrap,
      styles.button,
      styles[`size${size}`],
      styles[type.toLowerCase()],
      { [styles.loading]: isLoading, [styles.fullWidth]: fullWidth },
      className,
    )}
    onClick={onClick}
    {...props}
  >
    {isLoading && <Loader className={styles.loaderWrap} loaderClassName={styles.loader} maskClassName={styles.loaderMask} /> }
    {children}
  </button>
);

Button.defaultProps = {
  type: BUTTON_TYPES.PRIMARY,
  size: BUTTON_SIZES.M,
  className: '',
  onClick: () => null,
  isLoading: false,
  disabled: false,
};

export default Button;
