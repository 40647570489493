import cn from 'classnames';
import { FC } from 'react';

import { Button, BUTTON_TYPES, BUTTON_SIZES } from 'shared/components';
import { RISK_TYPE } from 'shared/constants/risks.constants';
import { TRANSACTION_VIEWS } from 'shared/constants/view.constants';
import { WALLET_PROVIDERS } from 'shared/constants/wallet.constants';
import { withTranslation } from 'shared/helpers/i18n.helpers';

import { getWalletImageByProvider } from './helpers';
import { IconWallet } from './icons';
import styles from './styles.module.scss';

interface Props {
  handleDecline: () => void
  handleProceed: () => void
  className?: string
  walletProvider?: WALLET_PROVIDERS | null;
  risk?: RISK_TYPE;
  transactionView?: TRANSACTION_VIEWS | null;
}

const REJECT_BTN_TYPE_BY_RISK = {
  [RISK_TYPE.LOW]: BUTTON_TYPES.SECONDARY,
  [RISK_TYPE.MIDDLE]: BUTTON_TYPES.SECONDARY,
  [RISK_TYPE.CRITICAL]: BUTTON_TYPES.PRIMARY_BLACK,
};

const CONTINUE_BTN_TYPE_BY_RISK = {
  [RISK_TYPE.LOW]: BUTTON_TYPES.PRIMARY_BLACK,
  [RISK_TYPE.MIDDLE]: BUTTON_TYPES.SECONDARY,
  [RISK_TYPE.CRITICAL]: BUTTON_TYPES.SECONDARY,
};

const FooterButtons: FC<Props> = ({
  handleDecline, handleProceed, className, walletProvider, risk = RISK_TYPE.LOW, transactionView,
}) => {

  const getButtons = () => {
    switch (transactionView) {
      case TRANSACTION_VIEWS.DECISION: return (
        <Button
          onClick={handleDecline}
          type={REJECT_BTN_TYPE_BY_RISK[risk]}
          className={styles.button}
          size={BUTTON_SIZES.S}
          fullWidth
        >
          {withTranslation('Close risk report')}
        </Button>
      );

      default: return (
        <div className={styles.buttons}>
          <Button
            onClick={handleDecline}
            type={REJECT_BTN_TYPE_BY_RISK[risk]}
            className={styles.button}
            size={BUTTON_SIZES.S}
          >
            {withTranslation('Reject')}
          </Button>
          <Button
            onClick={handleProceed}
            type={CONTINUE_BTN_TYPE_BY_RISK[risk]}
            className={cn(styles.button, { [styles.withRisk]: CONTINUE_BTN_TYPE_BY_RISK[risk] !== BUTTON_TYPES.PRIMARY_BLACK })}
            size={BUTTON_SIZES.S}
          >
            {withTranslation('Continue')}
            {walletProvider
              ? <img className={styles.walletImage} src={getWalletImageByProvider(walletProvider)} alt="" />
              : IconWallet}
          </Button>
        </div>
      );
    }
  };

  return (
    <div className={cn(styles.wrap, className)}>{getButtons()}</div>
  );
};

FooterButtons.defaultProps = {
  className: '',
};

export default FooterButtons;
