import cn from 'classnames';
import { memo } from 'react';

import { IconArrowDownFull } from 'shared/constants/icons.constants';

import styles from './styles.module.scss';

interface Props {
  className?: string;
  iconClassName?: string;
}

const Separator = ({ className, iconClassName }: Props) => (
  <div className={cn(styles.separator, className)}>
    <div className={cn(styles.icon, iconClassName)}><IconArrowDownFull /></div>
  </div>
);

export default memo(Separator);
