import cn from 'classnames';
import { memo } from 'react';

import styles from './styles.module.scss';

interface Props {
  name: string;
  value: string;
  className?: string;
}

const Label = ({ name, value, className }: Props) => (
  <div className={cn(styles.wrap, className)}>
    <span className={styles.name}>{name}</span>
    <span className={styles.value}>{value}</span>
  </div>
);

export default memo(Label);
