import alertHigh from 'shared/assets/images/icons/risks/alert-high.svg';
import alertLow from 'shared/assets/images/icons/risks/alert-low.svg';
import alertMedium from 'shared/assets/images/icons/risks/alert-medium.svg';
import criticalRisk from 'shared/assets/images/icons/risks/critical-risk-icon.svg';
import middleRisk from 'shared/assets/images/icons/risks/middle-risk-icon.svg';
import noRisk from 'shared/assets/images/icons/risks/no-risks-icon.svg';
import shieldHigh from 'shared/assets/images/icons/shield-danger.svg';
import shieldLow from 'shared/assets/images/icons/shield-protected.svg';
import shieldMedium from 'shared/assets/images/icons/shield-warning.svg';
import { withTranslation } from 'shared/helpers/i18n.helpers';

export enum RISK_TYPE {
  LOW = 'LOW',
  MIDDLE = 'MIDDLE',
  CRITICAL = 'CRITICAL',
}

export const RISK_OPTIONS = {
  [RISK_TYPE.LOW]: {
    title: withTranslation('Low risk'),
    icon: noRisk,
  },
  [RISK_TYPE.MIDDLE]: {
    title: withTranslation('Medium risk'),
    icon: middleRisk,
  },
  [RISK_TYPE.CRITICAL]: {
    title: withTranslation('High risk'),
    icon: criticalRisk,
  },
};

export const RISK_ALERT_ICONS = {
  [RISK_TYPE.LOW]: alertLow,
  [RISK_TYPE.MIDDLE]: alertMedium,
  [RISK_TYPE.CRITICAL]: alertHigh,
};

export const RISK_ALERT_SHIELD_ICONS = {
  [RISK_TYPE.LOW]: shieldLow,
  [RISK_TYPE.MIDDLE]: shieldMedium,
  [RISK_TYPE.CRITICAL]: shieldHigh,
};
