/* eslint-disable import/no-restricted-paths */
/* eslint-disable global-require */
import { APP_CONFIG } from 'shared/constants/common.constants';

const { getValueFromSessionChromeStorage, setValueToSessionChromeStorage } = APP_CONFIG.IS_WEBAPP
  ? require('shared/helpers/extension.helpers') : require('extension/helpers/chrome-storage.helpers');

export const getValueFromExtSessionChromeStorage = <TValue = Record<string, any>>(key?: string): Promise<TValue | null> => {
  if (APP_CONFIG.IS_WEBAPP) {
    return Promise.resolve(null) as unknown as Promise<TValue | null>;
  }

  return getValueFromSessionChromeStorage(key) as Promise<TValue | null>;
};

export const setValueToExtSessionChromeStorage = async <TValue>(key: string, value: TValue): Promise<void> => {
  if (APP_CONFIG.IS_WEBAPP) {
    return Promise.resolve() as unknown as Promise<void>;
  }

  return setValueToSessionChromeStorage(key, value) as Promise<void>;
};
