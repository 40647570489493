import React from 'react';
import ReactDOM from 'react-dom/client';

import 'shared/assets/scss/helpers/theme.scss';
import 'semantic-ui-css/semantic.min.css';
import 'shared/assets/scss/global.scss';

import { Widget } from './containers';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Widget />
  </React.StrictMode>,
);
