import {
  createContext, FC, PropsWithChildren, useContext,
} from 'react';
import { Communicator } from 'shared/services/communicator/webapp-communicator';

type CommunicatorContextType = Communicator;

const initialContextState = new Communicator('w3a-extension', 'w3a-webapp');

export const CommunicatorContext = createContext<CommunicatorContextType>(initialContextState);

export const useCommunicator = (): CommunicatorContextType => useContext(CommunicatorContext);

export const CommunicatorContextProvider: FC<PropsWithChildren<unknown>> = ({ children }) => (
  <CommunicatorContext.Provider value={initialContextState}>
    {children}
  </CommunicatorContext.Provider>
);
