import cn from 'classnames';
import { memo } from 'react';

import { Image, IMAGE_SIZES } from 'shared/components';
import { HeaderInner } from 'shared/components/layouts';
import { Web3ContractEntity } from 'shared/interfaces/analyze.interfaces';

import styles from './styles.module.scss';
import { ProtocolRisks } from '../../ScanningResult/interfaces';

interface Props {
  text: string;
  handleBackClick: () => void;
  risks: ProtocolRisks[];
  selectedAddress?: string;
  onTabSelect: (contractAddress: string, type?: Web3ContractEntity.type) => void;
}

const HeaderWithTabs = ({
  text, handleBackClick, risks, selectedAddress, onTabSelect,
}: Props) => (
  <HeaderInner
    text={text}
    handleBackClick={handleBackClick}
    rightContent={(
      <div className={styles.contracts}>
        {risks.map(({ name, imageSrc, contract }) => (
          <button
            onClick={() => onTabSelect(contract?.address || '', contract?.type)}
            key={name}
            className={cn(styles.btn, { [styles.active]: contract?.address === selectedAddress })}
          >
            <Image
              className={styles.image}
              isRound
              size={IMAGE_SIZES.S}
              key={name}
              src={imageSrc || undefined}
              name={name}
            />
          </button>
        ))}
      </div>
    )}
  />
);

export default memo(HeaderWithTabs);
