import { ICON_BY_SITE_ANALYZE_STATUS } from 'shared/constants/site-analyze.constants';
import { ProjectAnalysisStatus, Web3ContractAuditEntity } from 'shared/interfaces/analyze.interfaces';
import { AuditsData, IDEXPair } from 'shared/modules/analyze/Scan/interfaces';

import { IHighlightAlert } from '../ScanningResult/interfaces';
import { SITE_ANALYSIS_INFO } from '../constants';

export const getAuditsByContract = (auditsData: Web3ContractAuditEntity[], contractAddress: string): AuditsData => {
  const audits = auditsData.filter((audit) => audit.address === contractAddress);
  const result = audits.reduce((acc, audit) => {
    const auditor = (audit.auditor || '').toLowerCase();
    return ({
      ...acc,
      [auditor]: audit.auditUrl,
    });
  }, {} as AuditsData);

  return result;
};

export const getAuditsFromContract = (audits: Web3ContractAuditEntity[]): AuditsData => {
  const result = audits.reduce((acc, audit) => {
    const auditor = (audit.auditor || '').toLowerCase();
    return ({
      ...acc,
      [auditor]: audit.auditUrl,
    });
  }, {} as AuditsData);

  return result;
};

export const getWebsiteAlertByStatus = (status: ProjectAnalysisStatus, siteUrl?: string): IHighlightAlert => {
  const { risk: websiteRisk, text } = SITE_ANALYSIS_INFO[status || ProjectAnalysisStatus.Neutral];
  const websiteAlert: IHighlightAlert = {
    risk: websiteRisk,
    icon: ICON_BY_SITE_ANALYZE_STATUS[status],
    website: {
      name: siteUrl ?? window.location.hostname ?? '',
      text,
    },
  };

  return websiteAlert;
};

export const getDexPairByAddress = (contractAddress: string, dexPairs: IDEXPair[]): IDEXPair | null => {
  if (dexPairs) {
    const dexPair = (dexPairs || []).find(({ address }) => address === contractAddress);
    return dexPair || null;
  }

  return null;
};
