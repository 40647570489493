/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import cn from 'classnames';
import React, { memo, useState } from 'react';

import { ArrowDownIcon } from 'shared/constants/icons.constants';
import { withTranslation } from 'shared/helpers/i18n.helpers';

import styles from './styles.module.scss';

const TABS = {
  MAIN: 'MAIN',
  RELATED: 'RELATED',
} as const;

interface Props {
  title: string;
  risksCount?: number | null;
  children: (tab: keyof typeof TABS) => React.ReactNode;
  mainCount?: number;
  relatedCount?: number;
  isOpen?: boolean;
  className?: string;
  description: string;
  onOpenClick: (value: boolean) => void;
  openButtonText: string;
  hasData: boolean;
}

const getCountInfo = (risksCount?: number | null, hasData?: boolean) => {
  if (!hasData) {
    return (
      <div className={cn(styles.label, styles.noData)}>
        {withTranslation('No data')}
      </div>
    );
  }
  if (risksCount === 0) {
    return (
      <div className={cn(styles.label, styles.notFound)}>
        {withTranslation('Not found')}
      </div>
    );
  }

  return <div className={styles.count}>{risksCount}</div>;
};

const RiskCard = ({
  title,
  risksCount,
  children,
  mainCount,
  relatedCount,
  isOpen,
  className,
  description,
  onOpenClick,
  openButtonText,
  hasData,
}: Props) => {
  const [selectedTab, setSelectedTab] = useState<keyof typeof TABS>(TABS.MAIN);

  const hasTabs = mainCount !== undefined && relatedCount !== undefined;
  const hasRelatedData = relatedCount !== undefined && relatedCount > 0;
  const canCollapse = hasRelatedData || selectedTab === TABS.MAIN;

  return (
    <div
      className={cn(styles.wrap, className, {
        [styles.open]: isOpen,
        [styles.withData]: hasData,
        [styles.disabledCollapse]: !canCollapse,
      })}
    >
      <div
        className={styles.top}
        onClick={
          canCollapse && hasData ? () => onOpenClick(!isOpen) : undefined
        }
      >
        <div className={styles.titleWrap}>
          <span className={styles.title}>{title}</span>
          {getCountInfo(risksCount, hasData)}
        </div>
        <div className={styles.rightContentWrap}>
          {hasTabs && hasData && (
            <div className={styles.tabs}>
              <button
                className={cn(styles.tab, {
                  [styles.active]: selectedTab === TABS.MAIN,
                })}
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedTab(TABS.MAIN);
                }}
              >
                <span className={styles.tabName}>
                  {withTranslation('Main contract')}
                </span>
                <span className={styles.tabCount}>{mainCount || 0}</span>
              </button>
              <button
                className={cn(styles.tab, styles.relatedTab, {
                  [styles.active]: selectedTab === TABS.RELATED,
                })}
                onClick={(e) => {
                  e.stopPropagation();
                  if (hasRelatedData) {
                    setSelectedTab(TABS.RELATED);
                  }
                }}
              >
                <span className={styles.tabName}>
                  {withTranslation('Related')}
                </span>
                <span className={styles.tabCount}>{relatedCount || 0}</span>
              </button>
            </div>
          )}
          {hasData && <ArrowDownIcon className={styles.arrowIcon} />}
        </div>
      </div>
      {children(selectedTab)}
      <div className={styles.description}>{description}</div>
      {hasData && (
        <button
          className={styles.openBtn}
          onClick={() => canCollapse && onOpenClick(!isOpen)}
        >
          {isOpen ? withTranslation('Collapse') : openButtonText}{' '}
          <ArrowDownIcon className={styles.btnArrowIcon} />
        </button>
      )}
    </div>
  );
};

export default memo(RiskCard);
