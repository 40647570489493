/* eslint-disable max-len */
import { FC, useMemo } from 'react';

import alertIcon from 'shared/assets/images/icons/blacklist-circle.svg';
import verifiedIcon from 'shared/assets/images/icons/verified-shield.svg';
import BigValue from 'shared/components/BigValue';
import Risks from 'shared/components/Risks';
import Tabs from 'shared/components/Tabs';
import WrapHeader from 'shared/components/assetHeader/WrapHeader';
import { CUSTOM_CHAINS } from 'shared/constants/custom-chain.constants';
import { DATE_FORMAT } from 'shared/constants/global.constants';
import { withTranslation } from 'shared/helpers/i18n.helpers';
import { getImageUrl } from 'shared/helpers/image.helpers';
import { getDateWithFormat } from 'shared/helpers/time.helpers';
import { Tab } from 'shared/interfaces/common.interfaces';
import { TraceWithRisk } from 'shared/interfaces/fetch.interfaces';

import Protocol from './Protocol';
import styles from './styles.module.scss';
import ContractsInfo from '../components/ContractsInfo';
import { TokenData } from '../interfaces';

interface Props {
  data: TokenData;
  trace: TraceWithRisk[];
  chain?: CUSTOM_CHAINS;
}

enum TABS_TEXTS {
  RISKS = 'DETECTS',
  CONTRACTS = 'CONTRACTS',
  PROTOCOL = 'PROTOCOL',
}

const TransactionInfo: FC<Props> = ({
  data, trace, chain,
}) => {
  const { isAddressVerified } = data.info;

  const getAdditionalInfo = () => {
    const { transactionsCount, createdAt } = data.info;

    if (!createdAt && !transactionsCount) return undefined;
    const additionalInfo = [];

    if (transactionsCount) {
      additionalInfo.push({ name: withTranslation('Txn'), value: <><BigValue value={transactionsCount} className={styles.bigValue} /></> });
    }

    if (createdAt) {
      additionalInfo.push({
        name: withTranslation('Deployed'),
        value: getDateWithFormat(createdAt, DATE_FORMAT.MONTH_YEAR) as string,
      });
    }

    return additionalInfo;
  };

  const getLabels = () => {
    const {
      isProxy, isVerified, audits, hasRisk,
    } = data.info;
    const labels = [];
    if (isProxy) labels.push({ text: 'Proxy' });
    if (isVerified) labels.push({ text: withTranslation('W3A whitelist') });
    if (hasRisk) labels.push({ text: withTranslation('W3A blacklist'), isWarn: true });
    if (audits && Object.values(audits).length > 0) labels.push({ text: withTranslation('Audit passed') });

    return labels.length ? labels : undefined;
  };

  const TABS: Tab[] = useMemo(
    () => {
      const isCustomChain = Boolean(chain);
      const tabs = [
        {
          text: TABS_TEXTS.RISKS,
          Component: () => (
            <Risks
              warnings={data.info.warnings}
              skipEmptyTechnicalRisks={isCustomChain}
              {...data.risks}
            />
          ),
        },
        ...(isCustomChain ? [] : [{
          text: TABS_TEXTS.CONTRACTS,
          Component: () => (
            <ContractsInfo
              mainContract={data.info.contractsInfo.main}
              relatedContracts={data.info.contractsInfo.related}
              trace={trace}
            />
          ),
        }]),
        ...(isCustomChain ? [] : [{
          text: TABS_TEXTS.PROTOCOL,
          Component: () => (
            <Protocol
              socials={data.info.socials}
            />
          ),
        }]),
      ];

      return tabs;
    }, [data, isAddressVerified],
  );

  return (
    <div className={styles.wrap}>
      <WrapHeader
        name={data.info.name}
        isAddressVerified={isAddressVerified}
        labels={getLabels()}
        imageUrl={data.info.imageUrl}
        additionalInfo={getAdditionalInfo()}
        icon={(data.info.hasRisk || data.info.isVerified) ? getImageUrl(data.info.hasRisk ? alertIcon : verifiedIcon) : ''}
        address={data.info.address}
        chain={chain}
      />
      <Tabs data={TABS} />
    </div>
  );
};

export default TransactionInfo;
