import cn from 'classnames';
import { memo, useRef } from 'react';

import warningIcon from 'shared/assets/images/icons/lightning-red.svg';
import { InfoPopup } from 'shared/components/InfoPopup';
import { getImageUrl } from 'shared/helpers/image.helpers';

import RiskDetect from './RiskDetect';
import styles from './styles.module.scss';

interface Props {
  name: string;
  mainRisk?: boolean | null;
  relatedRisk?: boolean | null;
  description: string;
  isOpen: boolean;
  highlightOnRisk?: boolean
}

const RiskRow = ({
  name, mainRisk, relatedRisk, description, isOpen, highlightOnRisk,
}: Props) => {
  const rowRef = useRef<HTMLDivElement>(null);
  const hasRisk = mainRisk || relatedRisk;
  const displayRisk = highlightOnRisk && hasRisk;
  return (
    <div className={cn(styles.wrap, { [styles.isOpen]: isOpen, [styles.withRisk]: displayRisk })}>
      <div className={styles.main}>
        <div className={styles.name}>
          {displayRisk && <img src={getImageUrl(warningIcon)} alt="" />}
          {name}
        </div>
        <div className={styles.detects}>
          <RiskDetect hasDetect={mainRisk} />
          {relatedRisk !== undefined && <RiskDetect hasDetect={relatedRisk} />}
          <InfoPopup triggerClassName={styles.infoPopupTrigger} position="bottom right" content={description} />
        </div>
      </div>
      {hasRisk && (
        <div
          className={styles.description}
          ref={rowRef}
          style={{
            maxHeight: `${!isOpen ? '0px' : 'fit-content'}`,
            minHeight: `${!isOpen ? 'auto' : `${Number(rowRef.current?.scrollHeight)}px` }`,
          }}
        >{description}
        </div>
      )}
    </div>
  );
};

export default memo(RiskRow);
