import browser from 'webextension-polyfill';

// eslint-disable-next-line import/no-restricted-paths
import { getCodeExecutionEnvironment } from '../../extension/helpers/environments.helpers';

// NOTE: imports from this file should be only dynamically and only inside extension build
export const sendRuntimeMessage = (data: Record<string, unknown>): void => {
  browser.runtime.sendMessage(data).catch(() => null);
};

export const getExtensionImageSrc = (src: string): string => {
  const { isInject } = getCodeExecutionEnvironment();

  if (isInject && browser.runtime) {
    return browser.runtime.getURL(src);
  }

  return src;
};

export const getExtensionVersion = (): string => browser.runtime.getManifest()?.version;
