export enum PAYMENT_TOKENS {
  ETH = 'ETH',
  WETH = 'WETH',
  BNB = 'BNB',
  BASE = 'BASE',
}

export const TOKEN_LABELS = {
  [PAYMENT_TOKENS.ETH]: 'Ξ',
};

export enum TOKEN_TYPES {
  ERC20 = 'ERC20',
  BEP20 = 'BEP20',
}

export const TOKEN_TYPES_LABELS = {
  [TOKEN_TYPES.ERC20]: 'ERC-20',
  [TOKEN_TYPES.BEP20]: 'BEP-20',
};

export const TOKEN_ADDRESSES = {
  [PAYMENT_TOKENS.WETH]: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  [PAYMENT_TOKENS.ETH]: '0x0000000000000000000000000000000000000000',
};

export const MAX_ERC_20_AMOUNT = '115792089237316195423570985008687907853269984665640564039457584007913129639935';
export const MIN_ERC_20_LENGTH_FOR_APPROVE_ALL = 38;
