import cn from 'classnames';
import { FC } from 'react';

import Note from 'shared/modules/analyze/Scan/components/Note';

import SuspiciousActivity from './SuspiciousActivity';
import TechnicalRisks from './TechnicalRisks';
import VulnerableCodeDetectors from './VulnerableCodeDetectors';
import { RisksData } from './interfaces';
import styles from './styles.module.scss';
import { TokenWarning } from '../TokenWarning';

type Props = RisksData & {
  className?: string;
  warnings?: string[];
  skipEmptyTechnicalRisks?: boolean;
}

const Risks: FC<Props> = ({
  className, technicalRisks, vulnerableCodeDetectors, suspiciousActivity, warnings, skipEmptyTechnicalRisks,
}) => (
  <div className={cn(styles.wrap, className)}>
    {Boolean(warnings?.length) && warnings?.map((warning) => <TokenWarning key={warning} text={warning} className={styles.warning} />)}
    <TechnicalRisks skipEmptyRisks={skipEmptyTechnicalRisks} risks={technicalRisks} className={styles.risk} />
    <SuspiciousActivity risks={suspiciousActivity} className={styles.risk} />
    <VulnerableCodeDetectors risks={vulnerableCodeDetectors} className={styles.risk} />
    <Note />
  </div>
);

Risks.defaultProps = {
  className: '',
};

export default Risks;
