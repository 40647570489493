/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import cn from 'classnames';
import { memo, ReactNode, useRef } from 'react';

import warningIcon from 'shared/assets/images/icons/zip-warning.svg';
import { ArrowUpIcon } from 'shared/constants/icons.constants';
import { getImageUrl } from 'shared/helpers/image.helpers';

import styles from './styles.module.scss';

interface Props {
  title: string;
  description: string | ReactNode;
  isOpen: boolean;
  onClick?: () => void;
}

const Detect = ({
  title, description, isOpen, onClick,
}: Props) => {
  const detectRef = useRef<HTMLDivElement>(null);
  const canClick = isOpen && onClick;

  return (
    <div
      className={cn(styles.detect, { [styles.open]: isOpen, [styles.hasClick]: canClick })}
      ref={detectRef}
      onClick={canClick ? onClick : undefined}
    >
      <div className={styles.main}>
        <div className={styles.header}>
          <img className={styles.icon} src={getImageUrl(warningIcon)} alt="" />
          <span className={styles.title}>{title}</span>
        </div>
        <div
          style={{ maxHeight: `${!isOpen ? '0px' : `${Number(detectRef.current?.scrollHeight)}px`}` }}
          className={styles.description}
        >
          {description}
        </div>
      </div>
      {canClick && (
        <div className={styles.icon}>
          <ArrowUpIcon className={styles.arrow} />
        </div>
      )}
    </div>
  );
};

export default memo(Detect);
