export interface Transaction {
  from: string;
  to: string;
  data: string;
  value: string;
}

export enum TransactionType {
  Transfer = 'transfer',
  Sale = 'sale',
  Approve = 'approve',
  Revoke = 'revoke',
  ContractDeployment = 'contract_deployment',
  Other = 'other',
  SignatureRequest = 'signature_request',
  MakeOffer = 'make_offer',
  PlaceBid = 'place_bid',
  ApproveV2 = 'approve_v2',
  Listing = 'listing',
  EthDirectTransfer = 'eth_direct_transfer',
}
