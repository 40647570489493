import shieldOff from 'shared/assets/images/icons/blacklist-circle.svg';
import shieldUnknown from 'shared/assets/images/icons/shield-unknown.svg';
import shieldOn from 'shared/assets/images/icons/verified-shield-black.svg';
import shieldOnWhite from 'shared/assets/images/icons/verified-shield.svg';
import { ProjectAnalysisStatus } from 'shared/interfaces/analyze.interfaces';

export const ICON_BY_SITE_ANALYZE_STATUS = {
  [ProjectAnalysisStatus.Validated]: shieldOn,
  [ProjectAnalysisStatus.Neutral]: shieldUnknown,
  [ProjectAnalysisStatus.Dangerous]: shieldOff,
  [ProjectAnalysisStatus.Suspicious]: shieldOff,
};

export const DARK_ICON_BY_SITE_ANALYZE_STATUS = {
  [ProjectAnalysisStatus.Validated]: shieldOnWhite,
  [ProjectAnalysisStatus.Neutral]: shieldUnknown,
  [ProjectAnalysisStatus.Dangerous]: shieldOff,
  [ProjectAnalysisStatus.Suspicious]: shieldOff,
};
