import { FC } from 'react';

import honeypotIcon from 'shared/assets/images/icons/honeypot.svg';
import { Image, Popup } from 'shared/components';
import { getUSDPriceWithFallback } from 'shared/helpers/big-number.helpers';
import { withTranslation } from 'shared/helpers/i18n.helpers';
import { getImageUrl } from 'shared/helpers/image.helpers';

import styles from './styles.module.scss';

interface Props {
  name: string;
  imageSrc?: string;
  value: string;
  usdPrice?: string | number;
  isHoneypot?: boolean;
}

const ERC20: FC<Props> = ({
  name, imageSrc, value, usdPrice, isHoneypot,
}) => (
  <div className={styles.wrap}>
    <div className={styles.info}>
      <Image isRound className={styles.tokenImage} src={imageSrc} name={name} alt={name} />
      <div className={styles.nameWrap}>
        <span className={styles.name}>{name}</span>
        {isHoneypot
            && (
              <Popup
                content={
                  `${withTranslation('You will not be able to transfer or sell this token due to contract restrictions or high fees')}.`
                }
                trigger={(
                  <img
                    src={getImageUrl(honeypotIcon)}
                    alt=""
                    className={styles.honeypotIcon}
                  />
                )}
                className={styles.popup}
              />
            )}
      </div>
    </div>
    <div className={styles.value}>
      <span>{value}</span>
      {Boolean(usdPrice) && (
        <div className={styles.usdPrice}>
          {getUSDPriceWithFallback(usdPrice || 0)}
        </div>
      ) }
    </div>
  </div>
);

ERC20.defaultProps = {
  imageSrc: '',
};

export default ERC20;
