import { FC, useCallback, useState } from 'react';

import { APP_CONFIG } from 'shared/constants/common.constants';
import { withTranslation } from 'shared/helpers/i18n.helpers';

import { VulnerableCodeDetectorsData } from './interfaces';
import styles from './styles.module.scss';
import RiskCard from '../components/RiskCard';
import RiskRow from '../components/RiskRow';
import { RiskData } from '../interfaces';

interface Props {
  className?: string
  risks: RiskData<VulnerableCodeDetectorsData>
}

const VulnerableCodeDetectors: FC<Props> = ({ className, risks }) => {
  const [isOpen, setIsOpen] = useState(false);

  const hasData = risks.data.main.length > 0 || Boolean(risks.data.related?.length);

  const mainCount = risks.data.main.length;
  const relatedCount = risks.data.related ? risks.data.related.length : undefined;

  const onOpenClick = useCallback(
    (value: boolean) => {
      setIsOpen(value);
    },
    [],
  );

  return (
    <RiskCard
      title={withTranslation('Moderate risks')}
      risksCount={risks.count}
      className={className}
      isOpen={isOpen}
      onOpenClick={onOpenClick}
      description={APP_CONFIG.IS_WEBAPP
        ? 'Risks with minimal impact on the contract\'s security.' : withTranslation('moderate_risks_description')}
      openButtonText={withTranslation('Details')}
      hasData={hasData}
      mainCount={mainCount}
      relatedCount={relatedCount}
    >
      {(tab) => {
        const data = tab === 'MAIN' ? risks.data.main : (risks.data.related || []);
        const renderedData = isOpen ? data : data.slice(0, 4);

        return (
          <div className={styles.wrap}>
            <div className={styles.detectors}>
              {renderedData.map(({
                name, info,
              }) => (
                <RiskRow
                  key={name}
                  name={name}
                  description={info}
                  isOpen={isOpen}
                  mainRisk
                />
              ))}
            </div>
          </div>
        );
      }}
    </RiskCard>
  );
};

VulnerableCodeDetectors.defaultProps = {
  className: '',
};

export default VulnerableCodeDetectors;
