import { FC, useCallback, useState } from 'react';

import { APP_CONFIG } from 'shared/constants/common.constants';
import { removeEmptyFieldsFromObj } from 'shared/helpers/common.helpers';
import { withTranslation } from 'shared/helpers/i18n.helpers';

import { TECHNICAL_RISKS, TECHNICAL_RISKS_INFO, TECHNICAL_RISKS_LABELS } from './constants';
import { TechnicalRisksData } from './interfaces';
import styles from './styles.module.scss';
import RiskCard from '../components/RiskCard';
import RiskRow from '../components/RiskRow';
import { RiskData } from '../interfaces';

interface Props {
  className?: string
  risks: RiskData<TechnicalRisksData>
  skipEmptyRisks?: boolean;
}

const TechnicalRisks: FC<Props> = ({ className, risks, skipEmptyRisks }) => {
  const [isOpen, setIsOpen] = useState(false);

  const onOpenClick = useCallback(
    (value: boolean) => {
      setIsOpen(value);
    },
    [],
  );

  const mainRisks = skipEmptyRisks ? removeEmptyFieldsFromObj(risks.data.main) : risks.data.main;

  const risksTotal = Object.keys(mainRisks).length;
  const mainRisksCount = (Object.keys(mainRisks) as TECHNICAL_RISKS[]).filter((key) => Boolean(mainRisks[key])).length;
  const relatedRisksCount = risks.data.related
    ? (Object.keys(risks.data.related) as TECHNICAL_RISKS[]).filter((key) => Boolean(risks.data.related?.[key])).length : undefined;

  return (
    <RiskCard
      title={withTranslation('Key risks')}
      risksCount={risks.count}
      className={className}
      description={APP_CONFIG.IS_WEBAPP
        ? 'Main contract\'s risks are more critical than related contracts.' : withTranslation('key_risks_description')}
      onOpenClick={onOpenClick}
      openButtonText={`${withTranslation('See all')} (${risksTotal})`}
      isOpen={isOpen}
      hasData
      mainCount={mainRisksCount}
      relatedCount={relatedRisksCount}
    >
      {(tab) => {
        const selectedRisks = (tab === 'MAIN' || !risks.data.related) ? mainRisks : risks.data.related;
        const sortedData = (Object.keys(selectedRisks) as Array<TECHNICAL_RISKS>)
          .sort((a) => (selectedRisks[a] === false ? 1 : -1));

        const risksToShow = sortedData.filter((key) => Boolean(selectedRisks[key])).length;
        const data = isOpen ? sortedData : sortedData.slice(0, risksToShow > 3 ? risksToShow : 3);
        return (
          <div className={styles.wrap}>
            {data.map((key) => {
              const risksData = selectedRisks[key];
              return (
                <RiskRow
                  key={key}
                  name={TECHNICAL_RISKS_LABELS[key]}
                  description={TECHNICAL_RISKS_INFO[key]}
                  isOpen={isOpen}
                  mainRisk={risksData}
                  highlightOnRisk
                />
              );
            })}
          </div>
        );
      }}
    </RiskCard>
  );
};

TechnicalRisks.defaultProps = {
  className: '',
};

export default TechnicalRisks;
