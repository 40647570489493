import WindowWithButtons from 'shared/components/WindowWithButtons';
import {
  AnalyzeTransactionResponse,
  AnalyzeTransactionResponseType,
  TransactionETHTransferAnalysis,
  TransactionRequestMethod,
} from 'shared/interfaces/analyze.interfaces';
import { ProceedFuncWithManualId } from 'shared/interfaces/common.interfaces';
import AnalyzeResult from 'shared/modules/analyze/Scan/AnalyzeResult';
import ApproveWrapper from 'shared/modules/analyze/Scan/ApproveWrapper';
import DirectTransfer from 'shared/modules/analyze/Scan/DirectTransfer';
import { getDirectTransferData } from 'shared/modules/analyze/Scan/DirectTransfer/helpers';
import { checkDataForApprove, getApproveRenderData } from 'shared/modules/analyze/Scan/helpers/approve.helpers';
import { getAnalyzeRenderData } from 'shared/modules/analyze/Scan/helpers/data.helpers';

interface Props {
  data: AnalyzeTransactionResponseType;
  projectAddress: string;
  transactionValue: string;
  siteUrl: string;
  handleDecline: () => void;
  handleProceed: ProceedFuncWithManualId;
}

const Alert = ({
  data, projectAddress, transactionValue, siteUrl, handleDecline, handleProceed,
}: Props): React.ReactElement => {

  const isDirectETHTransfer = data.transactionType === TransactionRequestMethod.ETHTransfer;
  const isApprove = checkDataForApprove(data as AnalyzeTransactionResponse);

  return (
    <WindowWithButtons
      handleDecline={handleDecline}
      handleProceed={handleProceed}
    >{
        () => (
          <>
            {isDirectETHTransfer && (
              <DirectTransfer<TransactionETHTransferAnalysis>
                getDataFunc={getDirectTransferData}
                data={data as unknown as TransactionETHTransferAnalysis}
                transactionValue={transactionValue}
                toAddress={projectAddress}
              />
            )}

            {!isDirectETHTransfer && isApprove && (
              <ApproveWrapper<AnalyzeTransactionResponse>
                getDataFunc={getApproveRenderData}
                data={data}
                siteUrl={siteUrl}
                projectAddress={projectAddress}
              />
            )}

            {!isDirectETHTransfer && !isApprove && (
              <AnalyzeResult<AnalyzeTransactionResponse>
                getDataFunc={(txData, address, url) => getAnalyzeRenderData(
                  txData, address, url,
                )}
                data={data}
                siteUrl={siteUrl}
                projectAddress={(projectAddress || '').toLowerCase()}
              />
            )}
          </>
        )
      }
    </WindowWithButtons>
  );
};

export default Alert;
