import cn from 'classnames';
import { MouseEvent, memo, useState } from 'react';

import ethIcon from 'shared/assets/images/icons/eth-icon.svg';
import unverifiedIcon from 'shared/assets/images/icons/etherscan-unverified-white.svg';
import verifiedIcon from 'shared/assets/images/icons/etherscan-verified-white.svg';
import { CUSTOM_CHAINS } from 'shared/constants/custom-chain.constants';
import { CheckedCircleIcon, CopyIcon } from 'shared/constants/icons.constants';
import { formatAddress } from 'shared/helpers/address.helpers';
import { copyText } from 'shared/helpers/common.helpers';
import { withTranslation } from 'shared/helpers/i18n.helpers';
import { getImageUrl } from 'shared/helpers/image.helpers';
import { getEtherscanAddressUrl } from 'shared/helpers/url.helpers';

import styles from './styles.module.scss';
import { Popup } from '../Popup';

export interface Props {
  address: string
  isVerified?: boolean;
  className?: string;
  withChainIcon?: boolean;
  alertOnNonVerified?: boolean;
  cropAddress?: boolean;
  ensName?: string;
  chain?: CUSTOM_CHAINS;
}

const Address = ({
  address, isVerified = undefined, className = '', withChainIcon = true,
  alertOnNonVerified = false, cropAddress = true, ensName, chain,
}: Props) => {
  const [isOpenCopyPopup, setIsOpenCopyPopup] = useState(false);

  const handleCopy = async (e: MouseEvent) => {
    e.preventDefault();
    await copyText(address);
    setIsOpenCopyPopup(true);
    setTimeout(() => setIsOpenCopyPopup(false), 1000);
  };

  return (
    <div className={cn(styles.wrap, className)}>
      {withChainIcon && (
        <Popup
          content={`Chain: ${chain || 'Ethereum'}`}
          trigger={<img className={styles.ethIcon} src={getImageUrl(ethIcon)} alt="ethereum" />}
        />
      )}
      <Popup
        disabled={!cropAddress && !ensName}
        content={address}
        trigger={(
          <a href={getEtherscanAddressUrl(address, chain)} target="_blank" rel="noreferrer" className={styles.address}>
            {cropAddress ? formatAddress(address) : (ensName || address)}
          </a>
        )}
      />
      {(isVerified !== undefined && (isVerified || alertOnNonVerified))
      && (
        <Popup
          styleType="white"
          content={isVerified ? `${withTranslation('Verified by')} Etherscan` : `${('Not verified by')} Etherscan`}
          trigger={<img className={styles.verifiedIcon} src={getImageUrl(isVerified ? verifiedIcon : unverifiedIcon)} alt="verified" />}
        />
      )}
      <Popup
        styleType="white"
        content={`${withTranslation('Copied')}!`}
        open={isOpenCopyPopup}
        position="bottom center"
        trigger={(
          <button onClick={(e) => { e.stopPropagation(); handleCopy(e); }} className={styles.copyBtn}>
            {isOpenCopyPopup ? <CheckedCircleIcon /> : <CopyIcon className={styles.copyIcon} />}
          </button>
        )}
      />
    </div>
  );
};

export default memo(Address);
