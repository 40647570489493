import cn from 'classnames';
import { memo, useState } from 'react';

import { ArrowDownIcon } from 'shared/constants/icons.constants';
import { withTranslation } from 'shared/helpers/i18n.helpers';

import Main from './Main';
import styles from './styles.module.scss';
import { ContractInfo } from '../../../interfaces';

interface Props extends ContractInfo {
  title: string;
  className?: string;
  withChart?: boolean;
}

const ContractCard = ({
  title, className, withChart, ...contractInfo
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={cn(styles.wrap, className, { [styles.open]: isOpen })}>
      <button className={styles.top} onClick={() => setIsOpen(!isOpen)}>
        <span className={styles.title}>{title}</span>
        <ArrowDownIcon className={styles.arrowIcon} />
      </button>
      <Main withChart={withChart} {...contractInfo} isOpen={isOpen} />
      <button className={styles.openBtn} onClick={() => setIsOpen(!isOpen)}>
        {withTranslation(isOpen ? 'Collapse' : 'Details')} <ArrowDownIcon className={styles.btnArrowIcon} />
      </button>
    </div>
  );
};

export default memo(ContractCard);
