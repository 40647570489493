import { FC } from 'react';

import Message from 'shared/components/Message';
import { MESSAGE_TYPES } from 'shared/components/Message/constants';
import Socials from 'shared/components/Socials';
import { TSocials } from 'shared/components/Socials/interfaces';
import { withTranslation } from 'shared/helpers/i18n.helpers';

import Overview from './Overview';
import styles from './styles.module.scss';
import Note from '../../components/Note';

interface Props {
  hasRisk?: boolean,
  sales?: number,
  quantity?: number,
  owners?: number,
  mintingCap?: string | null,
  mintingTotal?: string | null,
  socials?: TSocials;
  collectionId?: number;
  description?: string;
  address: string;
}

const Collection: FC<Props> = ({
  hasRisk,
  sales,
  quantity,
  owners,
  mintingCap,
  mintingTotal,
  socials,
  collectionId,
  description,
  address,
}) => (
  <div className={styles.wrapper}>
    {hasRisk && (
      <Message
        messageType={MESSAGE_TYPES.WARNING}
        message={
          `${withTranslation('The collection has been created just recently or has low transaction volume')}. 
        ${withTranslation('Please make sure it is not a scam')}!`
        }
        title={withTranslation('Unpopular collection')}
        className={styles.warning}
      />
    )}
    <Socials data={socials} />
    <Overview
      collectionId={collectionId}
      sales={sales}
      owners={owners}
      quantity={quantity}
      mintingCap={mintingCap}
      mintingTotal={mintingTotal}
      description={description}
      address={address}
    />
    <Note />
  </div>
);

Collection.defaultProps = {
  hasRisk: false,
  sales: 0,
  quantity: 0,
  owners: 0,
};

export default Collection;
