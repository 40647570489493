import { memo, useEffect } from 'react';

import SwapInfo from 'shared/components/SwapInfo';
import { Header } from 'shared/components/layouts';
import { CUSTOM_CHAINS } from 'shared/constants/custom-chain.constants';
import { RISK_TYPE } from 'shared/constants/risks.constants';
import { withTranslation } from 'shared/helpers/i18n.helpers';

import styles from './styles.module.scss';
import Report from '../../components/Report';
import Note from '../components/Note';
import { MessageData, SwapDetails } from '../interfaces';

type DataForRender = {
  risk: RISK_TYPE;
  swapDetails: SwapDetails;
  dangerMessages: MessageData[];
}

interface Props<T> {
  data: T;
  transactionValue: string;
  toAddress: string;
  setButtonsRisk?: (risk: RISK_TYPE) => void,
  getDataFunc: (data: T, transactionValue: string, toAddress: string, chain?: CUSTOM_CHAINS) => DataForRender
}

const DirectTransfer = <T, >({
  data, transactionValue, toAddress, setButtonsRisk, getDataFunc,
}: Props<T>) => {
  const { risk, swapDetails, dangerMessages } = getDataFunc(data, transactionValue, toAddress);

  useEffect(() => {
    if (risk && setButtonsRisk) {
      setButtonsRisk(risk);
    }
  }, [risk]);

  return (
    <>
      <Header />
      <div className={styles.main}>
        <Report
          risk={risk}
          label={withTranslation('Direct transfer')}
          risks={[]}
          detects={dangerMessages}
        />
        <div className={styles.block}>
          <div className={styles.title}>{withTranslation('Transaction preview')}</div>
          <SwapInfo data={swapDetails} />
        </div>
        <Note />
      </div>
    </>
  );
};

export default memo(DirectTransfer) as typeof DirectTransfer;
