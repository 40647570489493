import coinbaseIcon from 'shared/assets/images/wallets/Coinbase.svg';
import metamaskIcon from 'shared/assets/images/wallets/Metamask.svg';
import otherIcon from 'shared/assets/images/wallets/Other.svg';
import trustWalletIcon from 'shared/assets/images/wallets/TrustWallet.svg';
import { WALLET_PROVIDERS } from 'shared/constants/wallet.constants';
import { getImageUrl } from 'shared/helpers/image.helpers';

export const getWalletImageByProvider = (provider?: WALLET_PROVIDERS | null): string => {
  switch (provider) {
    case WALLET_PROVIDERS.METAMASK:
      return getImageUrl(metamaskIcon);

    case WALLET_PROVIDERS.COINBASE:
      return getImageUrl(coinbaseIcon);

    case WALLET_PROVIDERS.TRUST_WALLET:
      return getImageUrl(trustWalletIcon);

    default:
      return getImageUrl(otherIcon);
  }
};
