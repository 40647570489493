import cn from 'classnames';
import { memo } from 'react';

import styles from './styles.module.scss';
import { Logo } from '../Logo';

interface Props {
  className?: string;
}

const Header = ({ className }: Props) => (
  <div className={cn(styles.wrapper, className)}>
    <Logo />
  </div>
);

export default memo(Header);
