import cn from 'classnames';
import pluralize from 'pluralize';
import {
  FC, memo, useRef,
} from 'react';

import verifiedIcon from 'shared/assets/images/icons/verified-shield.svg';
import { EtherscanVerification, Image, IMAGE_SIZES } from 'shared/components';
import { CUSTOM_CHAINS } from 'shared/constants/custom-chain.constants';
import { ArrowUpIcon } from 'shared/constants/icons.constants';
import { withTranslation } from 'shared/helpers/i18n.helpers';
import { getImageUrl } from 'shared/helpers/image.helpers';
import { TraceWithRisk } from 'shared/interfaces/fetch.interfaces';

import Trace from './Trace';
import styles from './styles.module.scss';
import { ProtocolRisks } from '../../../Scan/ScanningResult/interfaces';

interface Props {
  risks: ProtocolRisks[]
  handleContractSelect: (data: ProtocolRisks) => void;
  isOpen: boolean;
  trace?: TraceWithRisk[];
  chain?: CUSTOM_CHAINS;
}

const getProtocolImage = (
  isVerified: boolean,
) => {
  let imageUrl = null;

  if (isVerified) {
    imageUrl = verifiedIcon;
  }

  if (imageUrl) {
    const localImageUrl = getImageUrl(imageUrl);
    return (
      <img
        className={styles.iconImage}
        src={localImageUrl}
        alt={isVerified ? 'verified' : ''}
      />
    );
  }

  return null;
};

const AuditedContracts: FC<Props> = ({
  risks, handleContractSelect, isOpen, trace, chain,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const collapsedContentRef = useRef<HTMLDivElement>(null);

  return (
    <div className={cn(styles.wrap, { [styles.open]: isOpen })}>
      {risks.length > 0 && (
        <div
          ref={collapsedContentRef}
          className={styles.collapsedContent}
          style={{ maxHeight: `${isOpen ? '0px' : `${Number(20)}px`}` }}
        >
          <div className={styles.images}>
            {risks.map(({ name, imageSrc }, index) => (
              <Image
                className={styles.collapsedImage}
                isRound
                size={IMAGE_SIZES.S}
                key={name}
                src={imageSrc || undefined}
                name={name}
                style={{ zIndex: 50 - index }}
              />
            ))}
          </div>
          <span className={styles.title}>{withTranslation('Analyzed contracts')}</span>
        </div>
      )}
      <div
        ref={contentRef}
        style={{ maxHeight: `${!isOpen ? '0px' : `${Number(contentRef.current?.scrollHeight)}px`}` }}
        className={styles.risks}
      >
        <div className={styles.items}>
          {risks.map(({
            name, risksCount, isVerified, description, label, data, contract, imageSrc, isAddressVerified, ...rest
          }) => (
            <button
              className={styles.risk}
              onClick={() => handleContractSelect(
                {
                  name,
                  risksCount,
                  isVerified,
                  description,
                  label,
                  data,
                  contract,
                  imageSrc,
                  isAddressVerified,
                  ...rest,
                },
              )}
              key={name}
            >
              <div className={styles.info}>
                <div className={styles.main}>
                  <Image isRound src={imageSrc || undefined} className={styles.image} name={name} />
                  <div className={styles.about}>
                    <div className={styles.name}>
                      <span>{name}</span>
                      {getProtocolImage(Boolean(isVerified))}
                    </div>
                    {(description || label || isVerified)
                  && (
                    <p className={styles.description}>
                      {label}
                      {isVerified && (
                        <>
                          {label && <span> • </span>}
                          {withTranslation('W3A whitelist')}
                        </>
                      )}
                      {(label && description) && <span> • </span>}
                      {description}
                    </p>
                  )}
                    <EtherscanVerification
                      className={styles.verification}
                      isVerified={isAddressVerified}
                      chain={chain}
                    />
                  </div>
                </div>
              </div>
              <div className={styles.risksWrap}>
                {(!isVerified && (contract?.detectors?.externalVerification || (Boolean(risksCount))))
              && <div className={styles.count}>{risksCount} {withTranslation(pluralize('detect', risksCount))}</div>}
                <ArrowUpIcon className={styles.arrowRisk} />
              </div>
            </button>
          ))}
        </div>
        {trace && <Trace data={trace} chain={chain} />}
      </div>
    </div>
  );
};

export default memo(AuditedContracts);
