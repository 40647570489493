import cn from 'classnames';
import pluralize from 'pluralize';
import { memo, useCallback } from 'react';

import { formatNumberWithComas } from 'shared/helpers/big-number.helpers';
import { withTranslation } from 'shared/helpers/i18n.helpers';
import { EXTENSION_MODAL_TYPES, useExtensionModals } from 'shared/modules/extensionModals/modals.context';

import styles from './styles.module.scss';
import { IDEXPair } from '../../../Scan/interfaces';
import Row from '../Row';

interface Props {
  data: IDEXPair
}

const DexPair = ({ data }: Props) => {
  const { show } = useExtensionModals();

  const handleClick = useCallback(() => {
    show(EXTENSION_MODAL_TYPES.DEX_PAIRS, {
      address: data.address, pairsCount: data.pairsCount,
    });
  }, []);

  const info = (
    <div className={styles.info}>
      DEX {withTranslation('pairs')} {withTranslation('for')} <b>{data.name}</b>
    </div>
  );

  const pairsData = (
    <div className={cn(styles.data)}>
      <span>{formatNumberWithComas(data.pairsCount, 0)} {withTranslation(pluralize('pair', data.pairsCount))}</span>
    </div>
  );

  return (
    <Row
      info={info}
      data={pairsData}
      onClick={handleClick}
    />
  );
};

export default memo(DexPair);
