import cn from 'classnames';
import { memo } from 'react';

import styles from './styles.module.scss';

interface Props {
  className?: string;
  data: string | number;
}

const Count = ({ className, data }: Props) => (
  <span className={cn(styles.wrap, className)}>{data}</span>
);

export default memo(Count);
