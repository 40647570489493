import { CHAIN_IDS, CUSTOM_CHAINS } from './custom-chain.constants';
import { PAYMENT_TOKENS } from './token.constants';

export const CHAIN_BY_ID = {
  [CHAIN_IDS[CUSTOM_CHAINS.BNB]]: CUSTOM_CHAINS.BNB,
  [CHAIN_IDS[CUSTOM_CHAINS.BASE]]: CUSTOM_CHAINS.BASE,
};

export const CHAIN_EIP_BY_ID = {
  [CHAIN_IDS[CUSTOM_CHAINS.BNB]]: 'eip155-56',
  [CHAIN_IDS[CUSTOM_CHAINS.BASE]]: 'eip155-8453',
};

export const CHAIN_ID_BY_EIP = {
  [CHAIN_EIP_BY_ID[CHAIN_IDS[CUSTOM_CHAINS.BNB]]]: '0x38',
  [CHAIN_EIP_BY_ID[CHAIN_IDS[CUSTOM_CHAINS.BASE]]]: '0x2105',
};

export const COIN_NAME_BY_IDS = {
  [CHAIN_IDS[CUSTOM_CHAINS.BASE]]: PAYMENT_TOKENS.BASE,
  [CHAIN_IDS[CUSTOM_CHAINS.BNB]]: PAYMENT_TOKENS.BNB,
};

export const SCAN_BY_CHAIN = {
  [CUSTOM_CHAINS.BNB]: 'Bscscan',
  [CUSTOM_CHAINS.BASE]: 'Basescan',
};
