import cn from 'classnames';
import { memo, ReactNode } from 'react';

import styles from './styles.module.scss';

interface Props {
  text: ReactNode;
  className?: string;
  isBlack?: boolean;
  isDisabled?: boolean;
  isDark?: boolean;
}

const TailIcon = (
  <svg className={styles.tail} xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none">
    <path d="M3.99976 10.5C0 7.5 0 0 0 0C0 0 5.99976 6 11 6L3.99976 10.5Z" fill="#F3F3F3" />
  </svg>
);

const BubbleMessage = ({
  className, text, isBlack, isDisabled, isDark,
}: Props) => (
  <div className={cn(className,
    styles.wrap,
    { [styles.isBlack]: isBlack, [styles.isDisabled]: isDisabled, [styles.isDark]: isDark })}
  >
    {TailIcon}
    <div className={styles.main}>
      {text}
    </div>
  </div>
);

export default memo(BubbleMessage);
