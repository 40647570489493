export const COMMUNICATION_MESSAGES = {
  GET_FAVORITES_ADDRESSES: 'GET_FAVORITES_ADDRESSES',
  SEND_FAVORITES_ADDRESSES: 'SEND_FAVORITES_ADDRESSES',
  SET_FAVORITE_ADDRESS: 'SET_FAVORITE_ADDRESS',
  REMOVE_FAVORITE_ADDRESS: 'REMOVE_FAVORITE_ADDRESS',
  GET_METAMASK_VERSION: 'GET_METAMASK_VERSION',
  SEND_METAMASK_VERSION: 'SEND_METAMASK_VERSION',
  GET_USER_TOKEN: 'GET_USER_TOKEN',
  SEND_USER_TOKEN: 'SEND_USER_TOKEN',
  OPEN_TRACE: 'OPEN_TRACE',
  PING: 'PING',
  SET_ALERTS: 'SET_ALERTS',
};
