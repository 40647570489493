import cn from 'classnames';
import { memo } from 'react';
import CustomSkeleton, { SkeletonProps, SkeletonTheme } from 'react-loading-skeleton';

import 'react-loading-skeleton/dist/skeleton.css';
import { THEME } from 'shared/constants/theme.constants';
import { useTheme } from 'shared/hooks/theme.hooks';

import { SKELETON_BASE_COLOR, SKELETON_HIGHLIGHT_COLOR } from './constants';
import styles from './styles.module.scss';

type Props = {
  className?: string
  baseColor?: string
  highlightColor?: string
} & SkeletonProps

const Skeleton = ({
  className, highlightColor, baseColor, ...props
}: Props) => {
  const { theme } = useTheme();
  return (
    <SkeletonTheme
      baseColor={theme === THEME.DARK ? '#404040' : baseColor}
      highlightColor={theme === THEME.DARK ? '#727272' : highlightColor}
    >
      <CustomSkeleton className={cn(styles.skeleton, className)} inline {...props} />
    </SkeletonTheme>
  );
};

Skeleton.defaultProps = {
  className: undefined,
  baseColor: SKELETON_BASE_COLOR,
  highlightColor: SKELETON_HIGHLIGHT_COLOR,
};

export default memo(Skeleton);
