import browser from 'webextension-polyfill';

import { LANDING_URL } from 'shared/constants/url.constants';
import { logData } from 'shared/helpers/log.helpers';

import { isPromise } from './common.helpers';

const { REACT_APP_BROWSER } = process.env;
export const setValueToChromeStorage = <TValue>(key: string, value: TValue): void => {
  if (browser.storage) {
    browser.storage.local.set({ [key]: value }).then().catch((e) => logData(e));
  }
};

export const getValueToChromeStorage = <TDefault, TVal>(key: string, onChange: (value: TVal) => void, defaultValue?: TDefault): void => {
  if (browser.storage) {
    browser.storage.local.get(key).then((result) => {
      const value: TVal = result[key] || defaultValue;
      onChange(value);
    });
  }
};

export const setValueToSyncChromeStorage = <TValue>(key: string, value: TValue): void => {
  if (browser.storage) {
    browser.storage.sync.set({ [key]: value }).then().catch((e) => logData(e));
  }
};

export const getValueFromSyncChromeStorage = <TDefault, TVal>(
  key: string | undefined, onChange: (value: TVal) => Promise<void> | void, defaultValue?: TDefault): void => {
  if (browser.storage) {
    browser.storage.sync.get(key).then((result) => {
      const value: TVal = key ? result[key] : result;
      onChange(value ?? defaultValue as TVal);
    });
  }
};

export const subscribeChangesChromeSyncStorage = <TValue, TOldValue>(
  key: string, onChange: (value: TValue, oldValue: TOldValue) => void,
): void => {
  if (browser.storage) {
    browser.storage.sync.onChanged.addListener((changes) => {
      if (changes[key]) {
        onChange(changes[key]?.newValue, changes[key]?.oldValue);
      }
    });
  }
};

export const subscribeChangesChromeStorage = <TValue, TOldValue>(
  key: string, onChange: (value: TValue, oldValue: TOldValue) => void,
): void => {
  if (browser.storage) {
    browser.storage.onChanged.addListener((changes) => {
      if (changes[key]) {
        onChange(changes[key]?.newValue, changes[key]?.oldValue);
      }
    });
  }
};

export const getValueFromSessionChromeStorage = <TValue = Record<string, any>>(key?: string): Promise<TValue | null> => {

  if (REACT_APP_BROWSER === 'chrome' && chrome.storage?.session) {
    const result = chrome.storage.session.get(key);
    if (isPromise(result)) {
      return result as Promise<TValue | null>;
    }

    return Promise.resolve(result ?? null) as Promise<TValue | null>;
  }
  if (browser.storage) {
    return browser.storage.local.get('session').then((result) => {
      const value: TValue = result.session;
      return value as Promise<TValue | null>;
    });
  }

  return Promise.resolve(null);
};

export const setValueToSessionChromeStorage = async <TValue>(key: string, value: TValue): Promise<void> => {
  if (REACT_APP_BROWSER === 'chrome' && chrome.storage?.session) {
    await chrome.storage.session.set({
      [key]: value,
    });
  }

  if (browser.storage) {
    const sessionData = await getValueFromSessionChromeStorage('session');
    await browser.storage.local.set({
      session: {
        ...(sessionData || {}),
        [key]: value,
      },
    });
  }
};

export const getCookieValue = (url: string, name: string): Promise<browser.Cookies.Cookie | null> => browser.cookies.get({ url, name });

// setting cookie fo 365 days
export const setCookieValue = (url: string, name: string, value?: string): Promise<browser.Cookies.Cookie> => browser.cookies.set(
  {
    url, name, value, expirationDate: (new Date().getTime() / 1000) + 60 * 60 * 24 * 365,
  },
);

export const getExtensionState = async (): Promise<Record<string, string>> => {
  try {
    const jsonState = await getCookieValue(LANDING_URL, 'w3a-state');
    const state = jsonState ? JSON.parse(jsonState.value) : {};
    return state as Record<string, string>;
  } catch (error) {
    return {};
  }
};

export const getExtensionStateValue = async (key: string): Promise<any> => {
  const state = await getExtensionState();
  return state[key] || null;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setExtensionStateValue = async (key: string, value: any): Promise<void> => {
  const state = await getExtensionState();
  const updatedState = { ...state, [key]: value };
  await setCookieValue(LANDING_URL, 'w3a-state', JSON.stringify(updatedState));
};

export const getStorageValueAsync = async <T = any>(key: string, defaultValue: any = null): Promise<T> => {
  const result = await browser.storage.local.get(key);
  const value = (result as unknown as { [key: string]: any })[key] || defaultValue;
  return value as T;
};

export const setStorageValueAsync = (key: string, value: any): Promise<void> => browser.storage.local
  .set({ [key]: value })
  .then()
  .catch((e) => logData(e));
