import WindowWithButtons from 'shared/components/WindowWithButtons';
import { CUSTOM_CHAINS } from 'shared/constants/custom-chain.constants';
import { ProceedFuncWithManualId } from 'shared/interfaces/common.interfaces';
import {
  CustomChainComplexTransactionAnalysis,
  CustomChainETHTransactionAnalysis,
  CustomChainTransactionAnalysis, StringAssetType,
} from 'shared/interfaces/custom-chain.interfaces';
import { TransactionType } from 'shared/interfaces/transaction.interfaces';
import AnalyzeResult from 'shared/modules/analyze/Scan/AnalyzeResult';
import ApproveWrapper from 'shared/modules/analyze/Scan/ApproveWrapper';
import DirectTransfer from 'shared/modules/analyze/Scan/DirectTransfer';
import {
  getDirectTransferData as getCustomChainDirectTransferData,
  getCustomChainApproveRenderData,
  getCustomChainAnalyzeRenderData,
} from 'shared/modules/analyze/Scan/helpers/custom-chain.helpers';

interface Props {
  data: CustomChainTransactionAnalysis;
  projectAddress: string;
  transactionValue: string;
  handleDecline: () => void;
  handleProceed: ProceedFuncWithManualId;
  chain: CUSTOM_CHAINS;
}

const CustomChainAlert = ({
  data, projectAddress, transactionValue, handleDecline, handleProceed, chain,
}: Props): React.ReactElement => {

  const isDirectETHTransfer = data.txType === 'transfer'
  && data.txAssetsTypes.includes(StringAssetType.NATIVE_COIN) && !('trace' in data);
  const isApprove = [TransactionType.Approve, TransactionType.ApproveV2, TransactionType.Revoke].includes(data.txType);

  return (
    <WindowWithButtons
      handleDecline={handleDecline}
      handleProceed={handleProceed}
    >{
        () => (
          <>
            {isDirectETHTransfer && (
              <DirectTransfer<CustomChainETHTransactionAnalysis>
                getDataFunc={(txData, value, address) => getCustomChainDirectTransferData(txData, value, address, chain)}
                data={data as unknown as CustomChainETHTransactionAnalysis}
                transactionValue={transactionValue}
                toAddress={projectAddress}
              />
            )}

            {!isDirectETHTransfer && isApprove && (
              <ApproveWrapper<CustomChainComplexTransactionAnalysis>
                getDataFunc={(txData, address, url) => getCustomChainApproveRenderData(
                  txData, address, url,
                )}
                data={data as CustomChainComplexTransactionAnalysis}
                projectAddress={(projectAddress || '').toLowerCase()}
                chain={chain}
              />
            ) }

            {!isDirectETHTransfer && !isApprove && (
              <AnalyzeResult<CustomChainComplexTransactionAnalysis>
                getDataFunc={(txData, address, url) => getCustomChainAnalyzeRenderData(
                  txData, address, url, chain,
                )}
                data={data as CustomChainComplexTransactionAnalysis}
                projectAddress={(projectAddress || '').toLowerCase()}
                chain={chain}
              />
            )}
          </>
        )
      }
    </WindowWithButtons>
  );
};

export default CustomChainAlert;
