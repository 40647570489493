import {
  createContext, FC, PropsWithChildren, useContext, useEffect, useState,
} from 'react';

import { STORAGE_TOKEN } from 'extension/constants/chrome-storage.constants';
import { getValueToChromeStorage, subscribeChangesChromeStorage } from 'extension/helpers/chrome-storage.helpers';

type UserContextType = string;

const initialContextState = '';

export const UserContext = createContext<UserContextType>(initialContextState);

export const useUserToken = (): UserContextType => useContext(UserContext);

const useUser = (): string => {
  const [token, setToken] = useState('');

  subscribeChangesChromeStorage(STORAGE_TOKEN, (value: string) => {
    setToken(value);
  });

  useEffect(() => {
    getValueToChromeStorage(STORAGE_TOKEN, (value) => setToken(value as string));
  }, []);

  return token;
};

export const UserTokenContextProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const token = useUser();
  return (
    <UserContext.Provider value={token}>
      {children}
    </UserContext.Provider>
  );
};
