import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { DEFAULT_THEME, THEME } from 'shared/constants/theme.constants';

import { LOCAL_STORAGE_KEYS } from 'webapp/src/constants/storage.constants';
import { setLocalStorageValue } from 'webapp/src/helpers/storage.helpers';

type ThemeContextType = {
  theme: string;
  setTheme: (newTheme: THEME) => void;
};

const initialContextState = {
  theme: THEME.LIGHT,
  setTheme: () => null,
};

export const ThemeContext = createContext<ThemeContextType>(initialContextState);

export const useThemeData = (): ThemeContextType => useContext(ThemeContext);

export const useTheme = (): ThemeContextType => {
  const [currentTheme, setCurrentTheme] = useState<string>(DEFAULT_THEME);

  useEffect(() => {
    function callback(mutationList: any[]) {
      mutationList.forEach((mutation) => {
        if ([THEME.DARK, THEME.LIGHT].includes(mutation.target.className)) {
          setCurrentTheme(mutation.target.className);
        }
      });
    }
    if (typeof document !== 'undefined') {
      const node = document.body;

      const observer = new MutationObserver(callback);
      if (node) {
        observer.observe(node, {
          attributes: true,
          attributeFilter: ['class'],
          childList: false,
          characterData: false,
        });
      }
    }
  }, []);

  const handleThemeChange = useCallback((changedTheme: THEME) => {
    if (typeof document !== 'undefined') {
      if (changedTheme === THEME.DARK) {
        document.body.classList.add('dark-ext');
        document.body.classList.remove('light-ext');
      } else {
        document.body.classList.remove('dark-ext');
        document.body.classList.add('light-ext');
      }
      setLocalStorageValue(LOCAL_STORAGE_KEYS.THEME, changedTheme);
    }
  }, []);

  return { theme: currentTheme, setTheme: handleThemeChange };
};

export const ThemeContextProvider: FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  const data = useTheme();
  return <ThemeContext.Provider value={data}>{children}</ThemeContext.Provider>;
};

export const useCurrentTheme = (): ThemeContextType => {
  const data = useThemeData();
  return data;
};
