import { memo, useEffect, useState } from 'react';

// eslint-disable-next-line import/no-restricted-paths
import { CUSTOM_CHAIN_MESSAGE } from 'shared/constants/chrome.storage.constants';
import { CUSTOM_CHAINS } from 'shared/constants/custom-chain.constants';
import { CloseIcon } from 'shared/constants/icons.constants';
import { THEME } from 'shared/constants/theme.constants';
import { withTranslation } from 'shared/helpers/i18n.helpers';
import { getValueFromExtSessionChromeStorage, setValueToExtSessionChromeStorage } from 'shared/helpers/storage.helpers';
import { useTheme } from 'shared/hooks/theme.hooks';

import {
  IconBase, IconBaseDarkMode, IconBnb, IconBnbDarkMode,
} from './icons';
import styles from './styles.module.scss';

interface Props {
  chain: CUSTOM_CHAINS
}

const getIcon = (chain: CUSTOM_CHAINS, theme: THEME): JSX.Element => {
  const isDark = theme === THEME.DARK;

  if (chain === CUSTOM_CHAINS.BASE) {
    return isDark ? IconBaseDarkMode : IconBase;
  }

  return isDark ? IconBnbDarkMode : IconBnb;
};

const CustomChainMessage = ({ chain }: Props) => {
  const [show, setShow] = useState(false);
  const { theme } = useTheme();

  useEffect(() => {
    const checkMessage = async () => {
      const result = await getValueFromExtSessionChromeStorage(CUSTOM_CHAIN_MESSAGE);
      if (!result?.[CUSTOM_CHAIN_MESSAGE]?.[chain]) setShow(true);
    };
    checkMessage();
  }, []);

  const onCloseClick = async () => {
    const result = await getValueFromExtSessionChromeStorage(CUSTOM_CHAIN_MESSAGE);
    setValueToExtSessionChromeStorage(CUSTOM_CHAIN_MESSAGE, { [chain]: true, ...result || {} });
    setShow(false);
  };

  if (!show) return null;
  return (
    <div className={styles.wrap}>
      <div className={styles.top}>
        {getIcon(chain, theme)}
        <div className={styles.title}>{chain} chain txn [Test mode]</div>
      </div>
      <div className={styles.description}>{withTranslation(`CHAIN_MESSAGE_${chain.toUpperCase()}`)}</div>
      <button
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onCloseClick();
        }}
        className={styles.closeBtn}
      >
        <CloseIcon />
      </button>
    </div>
  );
};

export default memo(CustomChainMessage);
