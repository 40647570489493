import {
  ApproveMethodDescriptor,
  ApproveRecipientDescriptor,
  ContractViews, DetectorsMessageAnalysis, TraceSimulations, TransactionDetectors, TransactionGasDescriptor,
  TransferMethodDescriptor,
  Web3ContractEntityDTO,
  Web3SuspiciousActivityEntity,
} from './analyze.interfaces';
import {
  AnalysisDescriptor, CallMethodDescriptor, EventDescriptor, RiskGroup,
} from './fetch.interfaces';
import { TransactionType } from './transaction.interfaces';

export interface CustomChainCreateTransactionAnalysis {

  txType: TransactionType.ContractDeployment;

  riskGroup: RiskGroup;

}

export enum StringAssetType {
  NATIVE_COIN = 'NATIVE_COIN',
  ETH = 'ETH',
  ERC20 = 'ERC20',
  ERC721 = 'ERC721',
  ERC1155 = 'ERC1155',
  ERC404 = 'ERC404',
  UNKNOWN = 'Unknown',
}

export enum ContractType {
  ERC721 = 0,
  ERC1155 = 1,
  ERC20 = 2,
  ERC404 = 3,
}

export class ETHTransferDetectorsV2 {

  transferAmount: RiskGroup;

  walletDrainer: RiskGroup;

  dangerousWebsite: RiskGroup;

  newlyCreatedSite: RiskGroup;

  suspiciousActivity: RiskGroup;

  suspiciousAddress: RiskGroup;

}

export interface CustomChainETHTransactionAnalysis {
  riskGroup: RiskGroup;

  txAssetsTypes: StringAssetType[];

  txType: TransactionType;

  transactionGas: TransactionGasDescriptor;

  toIsAContract: boolean;

  detectors: ETHTransferDetectorsV2;

  detectorsMessageDescriptor: DetectorsMessageAnalysis[];

  nativeCoinToUSDCoeff: number;
}

export interface TransactionDetectorsV2 {
  dangerousWebsite: RiskGroup;

  suspiciousApprove: RiskGroup;

  suspiciousAddress: RiskGroup;

  directERC20Transfer: RiskGroup;

  zeroAddress: RiskGroup;

  bigETHTransfer: RiskGroup;

  phishingSwap: RiskGroup;

  walletDrainer: RiskGroup;

  newlyCreatedSite: RiskGroup;
}

export interface ContractDescriptor {
  id: string;

  parent: string | null;

  method: CallMethodDescriptor;

  calls: CallMethodDescriptor[];

  events: EventDescriptor[];

  viewOnly?: boolean;
}

export interface CustomChainBytecodeTransactionDetector {
  txOrigin: boolean;

  suicidal: boolean;

  controlledDelegatecall: boolean;

  isMintable: boolean;

  otherWhitelist: boolean;

  otherBlacklist: boolean;
}

export interface CustomChainContractDetectors {
  bytecode: CustomChainBytecodeTransactionDetector;
}

export interface CustomChainContract {
  address: string;

  type: ContractType;

  decimals: string;

  symbol?: string;

  name?: string;

  isProxy: boolean;

  detectors: CustomChainContractDetectors;
}

export interface Web3SuspiciousActivityDTO {
  source: 0;

  address: string;

  timestamp: string;

  description?: string | null;

  risk?: string | null;

  name: string;

  tooltip?: string;
}

export interface ETHTransferDescriptor {
  address: string;

  value: string;
}

export interface TransactionOperationsDescriptorV2 {
  nativeCoin: string;

  nativeCoinToUSDCoeff?: number;

  receivedNativeCoin: string;

  nativeCoinTransfers: ETHTransferDescriptor[];

  from: TransferMethodDescriptor[];

  to: TransferMethodDescriptor[];

  approves: ApproveMethodDescriptor[];

}

export interface CustomChainComplexTransactionAnalysis {
  txAssetsTypes: StringAssetType[];

  txType: TransactionType;

  transactionRisk: number;

  transactionRiskGroup: RiskGroup;

  transactionGas: TransactionGasDescriptor;

  contracts: Web3ContractEntityDTO[];

  siteAnalysis: AnalysisDescriptor;

  traceOperations: TransactionOperationsDescriptorV2;

  approveRecipients: ApproveRecipientDescriptor[];

  trace: ContractDescriptor[];

  suspiciousActivities: Web3SuspiciousActivityEntity[];

  detectors: TransactionDetectors;

  walletBalance: string;

  contractViews: ContractViews;

  simulationTraces: TraceSimulations[];

  totalRiskAdditionalCount: number;

  detectorsMessageDescriptor: DetectorsMessageAnalysis[];

  websiteCreationDate: Date;
}

export type CustomChainTransactionAnalysis =
  | CustomChainCreateTransactionAnalysis
  | CustomChainETHTransactionAnalysis
  | CustomChainComplexTransactionAnalysis;
