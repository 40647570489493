/* eslint-disable max-len */
import { withTranslation } from 'shared/helpers/i18n.helpers';

import { APP_CONFIG } from './common.constants';

const { IS_WEBAPP } = APP_CONFIG;

export enum DANGER_MESSAGES {
  BALANCE_CHANGE_BY_CONTRACT = 'BALANCE_CHANGE_BY_CONTRACT',
  HONEYPOT_SCAM_ERC_20 = 'HONEYPOT_SCAM_ERC_20',
  HONEYPOT_SCAM_ERC_721 = 'HONEYPOT_SCAM_ERC_721',
  TRANSFER_RESTRICTION = 'TRANSFER_RESTRICTION',
  OWNER_PERMISSIONS = 'OWNER_PERMISSIONS',
  IMPOSSIBLE_APPROVE = 'IMPOSSIBLE_APPROVE',
  SCAM_NAME = 'SCAM_NAME',
  OWNER_APPROVE = 'OWNER_APPROVE',
  ETH_DIRECT_TRANSFER = 'ETH_DIRECT_TRANSFER',
  ERC20_DIRECT_TRANSFER = 'ERC20_DIRECT_TRANSFER',
  HONEYPOT_SCAM_ERC_20_TRANSFER_NOT_WORK = 'HONEYPOT_SCAM_ERC_20_TRANSFER_NOT_WORK',
  HONEYPOT_SCAM_ERC_20_BIG_FEE = 'HONEYPOT_SCAM_ERC_20_BIG_FEE',
  HONEYPOT_SCAM_ERC_20_AMOUNT_OUT_CHANGE = 'HONEYPOT_SCAM_ERC_20_AMOUNT_OUT_CHANGE',
  HONEYPOT_SCAM_ERC_20_NO_SALE = 'HONEYPOT_SCAM_ERC_20_NO_SALE',
  HONEYPOT_SCAM_ERC_20_HIGH_PURCHASE_FEE = 'HONEYPOT_SCAM_ERC_20_HIGH_PURCHASE_FEE',
  CUSTOM_WHITE_BLACK_LIST = 'CUSTOM_WHITE_BLACK_LIST',
  EMPTY_PAID_CONTRACT = 'EMPTY_PAID_CONTRACT',
  PHISHING_SWAP = 'PHISHING_SWAP',
  SCAM_ADDRESS = 'SCAM_ADDRESS',
  WASH_TRADING = 'WASH_TRADING',
  NO_DEX_PAIRS = 'NO_DEX_PAIRS',
  VOLATILE_TOKEN_LIQUIDITY = 'VOLATILE_TOKEN_LIQUIDITY',
  BIG_ETH_TRANSFER = 'BIG_ETH_TRANSFER',
  POISONING_ATTACK = 'POISONING_ATTACK',
  METAMORPHIC_CONTRACT = 'METAMORPHIC_CONTRACT',
  SUSPICIOUS_ADDRESS = 'SUSPICIOUS_ADDRESS',
  APPROVE_RESTRICTION = 'APPROVE_RESTRICTION',
  BLOCKLIST_SITE = 'BLOCKLIST_SITE',
  WALLET_DRAINER = 'WALLET_DRAINER',
  WALLET_DRAINER_APPROVE = 'WALLET_DRAINER_APPROVE',
  SUSPICIOUS_LISTING = 'SUSPICIOUS_LISTING',
  SUSPICIOUS_APPROVE = 'SUSPICIOUS_APPROVE',
  NEWLY_CREATED_WEBSITE = 'NEWLY_CREATED_WEBSITE',
  WASH_TRADING_TOKEN = 'WASH_TRADING_TOKEN',
  LOW_TECH_RISKS = 'LOW_TECH_RISKS',
  SCAM_ENS_NAME = 'SCAM_ENS_NAME'
}

export const DANGER_MESSAGES_DATA = {
  [DANGER_MESSAGES.OWNER_PERMISSIONS]: {
    title: 'Unapproved asset transfer',
    message: IS_WEBAPP ? 'By signing this transaction, you purchase a token that allows the contract owner to transfer it without your consent.' : withTranslation(DANGER_MESSAGES.OWNER_PERMISSIONS),
  },
  [DANGER_MESSAGES.HONEYPOT_SCAM_ERC_20]: {
    title: 'Honeypot scam detection',
    message: IS_WEBAPP ? 'This ERC-20 token may have restrictions that prevent its further transfer and resale. Proceed with caution and research before investing.' : withTranslation(DANGER_MESSAGES.HONEYPOT_SCAM_ERC_20),
  },
  [DANGER_MESSAGES.HONEYPOT_SCAM_ERC_721]: {
    title: 'Honeypot scam detection',
    message: IS_WEBAPP ? 'This NFT may have restrictions that prevent its further transfer and resale. Proceed with caution and research before investing.' : withTranslation(DANGER_MESSAGES.HONEYPOT_SCAM_ERC_721),
  },
  [DANGER_MESSAGES.TRANSFER_RESTRICTION]: {
    title: 'Transfer restriction',
    message: IS_WEBAPP ? 'By signing this transaction, you purchase a token that can limit your ability to transfer it.' : withTranslation(DANGER_MESSAGES.TRANSFER_RESTRICTION),
  },
  [DANGER_MESSAGES.BALANCE_CHANGE_BY_CONTRACT]: {
    title: 'ERC-20 token allowance',
    message: IS_WEBAPP ? 'The contract allows its user to access your tokens and transfer them without your consent.' : withTranslation(DANGER_MESSAGES.BALANCE_CHANGE_BY_CONTRACT),
  },
  [DANGER_MESSAGES.IMPOSSIBLE_APPROVE]: {
    title: 'Blocked token approval',
    message: IS_WEBAPP ? 'By signing this transaction, you purchase a token that won\'t let you grant approvals to it. This way, you will not be able to sell it on DEXs.' : withTranslation(DANGER_MESSAGES.IMPOSSIBLE_APPROVE),
  },
  [DANGER_MESSAGES.SCAM_NAME]: {
    title: 'Scam token detection',
    message: IS_WEBAPP ? 'This transaction involves a possible scam token that exploits the original token\'s address in its name.' : withTranslation(DANGER_MESSAGES.SCAM_NAME),
  },
  [DANGER_MESSAGES.OWNER_APPROVE]: {
    title: 'Unsanctioned token approval',
    message: IS_WEBAPP ? 'Contract owner will be able to grant approvals to your tokens without your consent.' : withTranslation(DANGER_MESSAGES.OWNER_APPROVE),
  },
  [DANGER_MESSAGES.ETH_DIRECT_TRANSFER]: {
    title: 'Direct transfer',
    message: IS_WEBAPP ? 'Direct transactions carry high risks since they aren\'t recorded on a blockchain and aren\'t regulated by smart contracts. This way, you have no guarantee of receiving any assets in return.' : withTranslation(DANGER_MESSAGES.ETH_DIRECT_TRANSFER),
  },
  [DANGER_MESSAGES.ERC20_DIRECT_TRANSFER]: {
    title: 'Direct transfer',
    message: IS_WEBAPP ? 'Direct transactions carry high risks since they aren\'t recorded on a blockchain and aren\'t regulated by smart contracts. This way, you have no guarantee of receiving any assets in return.' : withTranslation(DANGER_MESSAGES.ERC20_DIRECT_TRANSFER),
  },
  [DANGER_MESSAGES.HONEYPOT_SCAM_ERC_20_AMOUNT_OUT_CHANGE]: {
    title: 'Honeypot scam detection',
    message: IS_WEBAPP
      ? 'High sale fee. You will pay a high fee (50% and more) when selling this token. This is a sign of a honeypot scam.'
      : `${withTranslation('High sale fee')}. ${withTranslation(DANGER_MESSAGES.HONEYPOT_SCAM_ERC_20_AMOUNT_OUT_CHANGE)}`,
  },
  [DANGER_MESSAGES.HONEYPOT_SCAM_ERC_20_BIG_FEE]: {
    title: 'Honeypot scam detection',
    message: IS_WEBAPP
      ? 'High transfer fee. You will pay a high fee (50% and more) when transferring this token. This is a sign of a honeypot scam.'
      : `${withTranslation('High transfer fee')}. ${withTranslation(DANGER_MESSAGES.HONEYPOT_SCAM_ERC_20_BIG_FEE)}`,
  },
  [DANGER_MESSAGES.HONEYPOT_SCAM_ERC_20_TRANSFER_NOT_WORK]: {
    title: 'Honeypot scam detection',
    message: IS_WEBAPP
      ? 'No transfer. You will not be able to transfer and sell this token. This is a sign of a honeypot scam.'
      : `${withTranslation('No transfer')}. ${withTranslation(DANGER_MESSAGES.HONEYPOT_SCAM_ERC_20_TRANSFER_NOT_WORK)}`,
  },
  [DANGER_MESSAGES.HONEYPOT_SCAM_ERC_20_NO_SALE]: {
    title: 'Honeypot scam detection',
    message: IS_WEBAPP
      ? 'No sale. You will be able to transfer this token but not sell it. This is a sign of a honeypot scam.'
      : `${withTranslation('No sale')}. ${withTranslation(DANGER_MESSAGES.HONEYPOT_SCAM_ERC_20_NO_SALE)}`,
  },
  [DANGER_MESSAGES.HONEYPOT_SCAM_ERC_20_HIGH_PURCHASE_FEE]: {
    title: 'Honeypot scam detection',
    message: IS_WEBAPP
      ? 'High purchase fee. You will pay a high fee (50% and more) for purchasing this token. This is a sign of a honeypot scam.'
      : `${withTranslation('High transfer fee')}. ${withTranslation(DANGER_MESSAGES.HONEYPOT_SCAM_ERC_20_HIGH_PURCHASE_FEE)}`,
  },
  [DANGER_MESSAGES.CUSTOM_WHITE_BLACK_LIST]: {
    title: 'Custom white and blacklists',
    message: IS_WEBAPP ? 'This contract is a potential phishing scam. Signing the transaction may send all your ETH tokens to scammers.' : withTranslation(DANGER_MESSAGES.CUSTOM_WHITE_BLACK_LIST),
  },
  [DANGER_MESSAGES.EMPTY_PAID_CONTRACT]: {
    title: 'Suspected phishing contract',
    message: IS_WEBAPP ? 'If you sign this contract, all your ETH tokens will be sent to scammers.' : withTranslation(DANGER_MESSAGES.EMPTY_PAID_CONTRACT),
  },
  [DANGER_MESSAGES.PHISHING_SWAP]: {
    title: 'Phishing swap',
    message: IS_WEBAPP ? 'If you sign this swap transaction, the tokens you purchased on the decentralized exchange will be sent to scammers.' : withTranslation(DANGER_MESSAGES.PHISHING_SWAP),
  },
  [DANGER_MESSAGES.SCAM_ADDRESS]: {
    title: 'Scam address',
    message: IS_WEBAPP ? 'This transaction involves a scam address that imitates a 0x0..00 address and can carry out a variety of harmful actions.' : withTranslation(DANGER_MESSAGES.SCAM_ADDRESS),
  },
  [DANGER_MESSAGES.NO_DEX_PAIRS]: {
    title: 'Limited Liquidity',
    message: IS_WEBAPP ? 'By signing this transaction, you purchase a token with no DEX pair. You will not be able sell it.' : withTranslation(DANGER_MESSAGES.NO_DEX_PAIRS),
  },
  [DANGER_MESSAGES.VOLATILE_TOKEN_LIQUIDITY]: {
    title: 'Limited Liquidity',
    message: IS_WEBAPP ? 'By signing this transaction, you purchase a token with a weak DEX pair. It may have a negative impact on the token price when selling.' : withTranslation(DANGER_MESSAGES.VOLATILE_TOKEN_LIQUIDITY),
  },
  [DANGER_MESSAGES.BIG_ETH_TRANSFER]: {
    title: 'Wallet drainer',
    message: IS_WEBAPP ? 'By signing this transaction, you will send most of your ETH and ERC-20 tokens but get nothing in return.' : withTranslation(DANGER_MESSAGES.BIG_ETH_TRANSFER),
  },
  [DANGER_MESSAGES.POISONING_ATTACK]: {
    title: 'Poisoning attack',
    message: IS_WEBAPP ? 'The scammers have “poisoned” your transaction history with a fake address that looks similar to the one you have previously interacted with. Be attentive when sending assets, and double-check the recipient\'s address.' : withTranslation(DANGER_MESSAGES.POISONING_ATTACK),
  },
  [DANGER_MESSAGES.METAMORPHIC_CONTRACT]: {
    title: 'Metamorphic contract',
    message: IS_WEBAPP ? 'This transaction involves a contract that can change its code, potentially introducing malicious logic.' : withTranslation(DANGER_MESSAGES.METAMORPHIC_CONTRACT),
  },
  [DANGER_MESSAGES.SUSPICIOUS_ADDRESS]: {
    title: 'Suspicious address',
    message: IS_WEBAPP ? 'This transaction involves a wallet address that has a history of malicious activities.' : withTranslation(DANGER_MESSAGES.SUSPICIOUS_ADDRESS),
  },
  [DANGER_MESSAGES.APPROVE_RESTRICTION]: {
    title: 'Approve restriction',
    message: IS_WEBAPP ? 'By signing this transaction, you purchase a token that can limit your ability to grant approvals to it.' : withTranslation(DANGER_MESSAGES.APPROVE_RESTRICTION),
  },
  [DANGER_MESSAGES.BLOCKLIST_SITE]: {
    title: 'Blocklist site transaction',
    message: IS_WEBAPP ? 'You are making a transaction on a website from the W3A blocklist. The outcomes are unpredictable.' : withTranslation(DANGER_MESSAGES.BLOCKLIST_SITE),
  },
  [DANGER_MESSAGES.WALLET_DRAINER]: {
    title: 'Wallet drainer',
    message: IS_WEBAPP ? 'If you sign this transaction, you will send tokens or grant approval to a scam address.' : withTranslation(DANGER_MESSAGES.WALLET_DRAINER),
  },
  [DANGER_MESSAGES.WALLET_DRAINER_APPROVE]: {
    title: 'Wallet drainer',
    message: IS_WEBAPP ? 'By signing this transaction, you will give approval for most of your ETH and ERC-20 tokens to a wallet drainer and lose them.' : withTranslation(DANGER_MESSAGES.WALLET_DRAINER),
  },
  [DANGER_MESSAGES.SUSPICIOUS_LISTING]: {
    title: 'Extremely low price',
    message: IS_WEBAPP ? 'You are about to list an NFT from a high-demand collection for a very low price.' : withTranslation(DANGER_MESSAGES.SUSPICIOUS_LISTING),
  },
  [DANGER_MESSAGES.SUSPICIOUS_APPROVE]: {
    title: 'Suspicious approve',
    message: IS_WEBAPP ? 'By confirming this operation, you are granting token approval to a potentially malicious or compromised address.' : withTranslation(DANGER_MESSAGES.SUSPICIOUS_APPROVE),
  },
  [DANGER_MESSAGES.NEWLY_CREATED_WEBSITE]: {
    title: 'Suspicious domain',
    message: IS_WEBAPP ? 'This website was created recently. Be cautious and confirm its legitimacy, as many phishing sites use new domains.' : withTranslation(DANGER_MESSAGES.NEWLY_CREATED_WEBSITE),
  },
  [DANGER_MESSAGES.WASH_TRADING]: {
    title: 'Wash trading in collection',
    message: IS_WEBAPP ? 'Some tokens in this collection may have artificially inflated prices and demand..' : withTranslation(DANGER_MESSAGES.WASH_TRADING),
  },
  [DANGER_MESSAGES.WASH_TRADING_TOKEN]: {
    title: 'Wash trading in token',
    message: IS_WEBAPP ? 'The tokens you intend to buy may have artificially inflated prices and demand.' : withTranslation(DANGER_MESSAGES.WASH_TRADING_TOKEN),
  },
  [DANGER_MESSAGES.LOW_TECH_RISKS]: {
    title: 'Low tech risks',
    message: IS_WEBAPP ? 'The transaction has minor technical risks. Check details before proceeding.' : withTranslation(DANGER_MESSAGES.LOW_TECH_RISKS),
  },
  [DANGER_MESSAGES.SCAM_ENS_NAME]: {
    title: 'Fake ENS',
    message: IS_WEBAPP ? 'The scam uses fake Ethereum Name Service (ENS) domains that resemble real wallet addresses, misleading users into interacting with fraudulent accounts through search platforms.' : withTranslation(DANGER_MESSAGES.SCAM_ENS_NAME),
  },
};
