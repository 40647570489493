import useSWR from 'swr';

import { EXTENSION_METADATA_API } from 'shared/constants/check-nft.constants';
import { MetaDataEntity } from 'shared/interfaces/analyze.interfaces';

import { useRequestWithToken } from './use-request-with-token';

// eslint-disable-next-line import/no-restricted-paths

export const useMetaData = (address: string, id: string, mintUrl?: string | null): {
  data: MetaDataEntity | undefined;
  isLoading: boolean;
} => {
  const { post, hasToken } = useRequestWithToken();

  const { data, isLoading } = useSWR<MetaDataEntity>([EXTENSION_METADATA_API, address, id, hasToken], () => post<MetaDataEntity>(
    EXTENSION_METADATA_API,
    {
      address,
      id,
      url: mintUrl,
    },
  ));

  return { data, isLoading };
};
