import { FC } from 'react';

import washTradingIcon from 'shared/assets/images/icons/wash-trading.svg';
import { withTranslation } from 'shared/helpers/i18n.helpers';
import { getImageUrl } from 'shared/helpers/image.helpers';

import styles from './styles.module.scss';
import { Popup } from '../Popup';

const WashTrading: FC = () => (
  <Popup
    position="bottom left"
    trigger={(
      <div className={styles.washTrading}>
        <img src={getImageUrl(washTradingIcon)} alt="" />
        <span>Wash trading</span>
      </div>
    )}
    content={`${withTranslation('The tokens you intend to buy may have artificially inflated prices and demand')}.`}
  />
);

export default WashTrading;
