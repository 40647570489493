export enum BUTTON_TYPES {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  DESTRUCTIVE = 'DESTRUCTIVE',
  PRIMARY_BLACK = 'PRIMARY_BLACK',
  TWITTER='TWITTER',
  TERTIARY='TERTIARY'
}

export enum BUTTON_SIZES {
  M = 'M',
  S = 'S',
  XS = 'XS',
}
