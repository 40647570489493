import cn from 'classnames';
import { memo } from 'react';
import { Table, TableRowProps } from 'semantic-ui-react';

import styles from '../styles.module.scss';

type Props = TableRowProps & {
  className?: string,
}

const TableRow = ({
  children, className, ...props
}: Props) => (
  <Table.Row
    className={cn(styles.row,
      className, 'table-row')}
    {...props}
  >
    {children}
  </Table.Row>
);

TableRow.defaultProps = {
  className: '',
};

export default memo(TableRow);
