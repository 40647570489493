/* eslint-disable max-len */
import { APP_CONFIG } from 'shared/constants/common.constants';
import { RISK_TYPE } from 'shared/constants/risks.constants';
import { withTranslation } from 'shared/helpers/i18n.helpers';
import { ProjectAnalysisStatus } from 'shared/interfaces/analyze.interfaces';

const { IS_WEBAPP } = APP_CONFIG;

export enum ANALYSIS_STATUSES {
  WAITING = 'WAITING',
  TRACING = 'TRACING',
  SEARCHING = 'SEARCHING',
  SCANNING = 'SCANNING',
  LOADING_RESULTS = 'LOADING_RESULTS',
}

export type AnalysisStatusesForRandom = Exclude<ANALYSIS_STATUSES, ANALYSIS_STATUSES.LOADING_RESULTS | ANALYSIS_STATUSES.WAITING>;

export const ANALYSIS_STATUSES_LABELS = {
  [ANALYSIS_STATUSES.WAITING]: 'Waiting...',
  [ANALYSIS_STATUSES.TRACING]: 'Tracing...',
  [ANALYSIS_STATUSES.SEARCHING]: 'Searching...',
  [ANALYSIS_STATUSES.SCANNING]: 'Scanning...',
  [ANALYSIS_STATUSES.LOADING_RESULTS]: 'Loading results...',
};

export const ANALYSIS_RANDOM_STATUSES: Array<AnalysisStatusesForRandom> = [
  ANALYSIS_STATUSES.TRACING,
  ANALYSIS_STATUSES.SEARCHING,
  ANALYSIS_STATUSES.SCANNING,
];

export const STATUS_DURATION_SECONDS = {
  [ANALYSIS_STATUSES.WAITING]: {
    from: 1,
    to: 1.2,
  },
  [ANALYSIS_STATUSES.TRACING]: {
    from: 1,
    to: 3,
  },
  [ANALYSIS_STATUSES.SEARCHING]: {
    from: 2,
    to: 5,
  },
  [ANALYSIS_STATUSES.SCANNING]: {
    from: 1,
    to: 10,
  },
};

export enum RESULT_SCREENS {
  SCANNING_RESULT,
  WEBSITE_PROTOCOL,
  COLLECTION,
  ERC20,
}

export const RISK_ALERT_TEXTS = {
  [RISK_TYPE.LOW]: 'No risks detected',
  [RISK_TYPE.MIDDLE]: 'Medium risk detected',
  [RISK_TYPE.CRITICAL]: 'Critical risk detected',
};

export const SITE_ANALYSIS_INFO = {
  [ProjectAnalysisStatus.Neutral]: {
    risk: RISK_TYPE.MIDDLE,
    text: withTranslation('is not flagged by W3A'),
  },
  [ProjectAnalysisStatus.Dangerous]: {
    risk: RISK_TYPE.CRITICAL,
    text: withTranslation('is blocklisted by W3A'),
  },
  [ProjectAnalysisStatus.Suspicious]: {
    risk: RISK_TYPE.CRITICAL,
    text: withTranslation('is blocklisted by W3A'),
  },
  [ProjectAnalysisStatus.Validated]: {
    risk: RISK_TYPE.LOW,
    text: withTranslation('is whitelisted by W3A'),
  },
};

export const VERIFIED_CONTRACT_DESCRIPTION = `${withTranslation('The contract is in the W3A whitelist')}. ${withTranslation('You can trust it')}.`;
export const NOT_VERIFIED_CONTRACT_DESCRIPTION = `${withTranslation('The contract is not verified')}.`;
export const NOT_VERIFIED_CONTRACT_HEADER_DESCRIPTION = IS_WEBAPP ? 'The contract is not verified, that\'s why W3A can\'t specify all the risks it may have.' : withTranslation('NOT_VERIFIED_CONTRACT_HEADER_DESCRIPTION');
export const VERIFIED_TOKEN_DESCRIPTION = `${withTranslation('The token is in the W3A whitelist')}. ${withTranslation('You can trust it')}.`;
export const VERIFIED_COLLECTION_DESCRIPTION = `${withTranslation('The collection is in the W3A whitelist')}. ${withTranslation('You can trust it')}.`;
export const NOT_ANALYZED_CONTRACT_DESCRIPTION = IS_WEBAPP ? 'The contract has not been analyzed yet, that\'s why W3A can\'t specify all the risks it may have.' : withTranslation('NOT_ANALYZED_CONTRACT_DESCRIPTION');

const getDetectorTranslation = (key: string): string => withTranslation(key.replace(/-/g, '_'));

const getDetectorDescription = (key: string): string => withTranslation(`${key.replace(/-/g, '_')}_description`);

export const CODE_DETECTORS_INFO_LABELS: { [x: string]: string } = {
  'locked-ether': IS_WEBAPP ? 'ETH lock' : getDetectorTranslation('locked-ether'),
  whitelist_blacklist: IS_WEBAPP ? 'Custom white and blacklists' : getDetectorTranslation('whitelist_blacklist'),
  hidden_mint: IS_WEBAPP ? 'Hidden minting' : getDetectorTranslation('hidden_mint'),
  'reentrancy-eth': IS_WEBAPP ? 'Reentancy ETH' : getDetectorTranslation('reentrancy-eth'),
  'reentrancy-no-eth': IS_WEBAPP ? 'Reentrancy no ETH' : getDetectorTranslation('reentrancy-no-eth'),
  'unchecked-transfer': IS_WEBAPP ? 'Unchecked transfer' : getDetectorTranslation('unchecked-transfer'),
  'controlled-delegatecall': IS_WEBAPP ? 'Delegated call' : getDetectorTranslation('controlled-delegatecall'),
  'reentrancy-events': IS_WEBAPP ? 'Reentrancy events' : getDetectorTranslation('reentrancy-events'),
  'reentrancy-benign': IS_WEBAPP ? 'Reentrancy benign' : getDetectorTranslation('reentrancy-benign'),
  'unchecked-send': IS_WEBAPP ? 'Unchecked send' : getDetectorTranslation('unchecked-send'),
  'unchecked-lowlevel': IS_WEBAPP ? 'Unchecked low-level calls' : getDetectorTranslation('unchecked-lowlevel'),
  'tx-origin': IS_WEBAPP ? 'Obsolete functionality' : getDetectorTranslation('tx-origin'),
  'erc721-interface': IS_WEBAPP ? 'ERC-721 interface' : getDetectorTranslation('erc721-interface'),
  'erc20-interface': IS_WEBAPP ? 'ERC-20 interface' : getDetectorTranslation('erc20-interface'),
  'arbitrary-send-eth': IS_WEBAPP ? 'Arbitrary send ETH' : getDetectorTranslation('arbitrary-send-eth'),
  'arbitrary-send-erc21': IS_WEBAPP ? 'Arbitrary send ERC-20' : getDetectorTranslation('arbitrary-send-erc21'),
  'arbitrary-send-erc20-permit': IS_WEBAPP ? 'Arbitrary send ERC-20 permit' : getDetectorTranslation('arbitrary-send-erc20-permit'),
  'unprotected-upgrade': IS_WEBAPP ? 'Upgradable contract' : getDetectorTranslation('unprotected-upgrade'),
  'delegatecall-loop': IS_WEBAPP ? 'Delegated call loop' : getDetectorTranslation('delegatecall-loop'),

  trading_cooldown: IS_WEBAPP ? 'Trading cooldown' : getDetectorTranslation('trading_cooldown'),
  hardcoded_logic: IS_WEBAPP ? 'Hardcoded logic' : getDetectorTranslation('hardcoded_logic'),
};

export const CODE_DETECTORS_INFO_DESCRIPTIONS: { [x: string]: string } = {
  'locked-ether': IS_WEBAPP ? 'This contract can lock ETH, causing you to lose some of your tokens.' : getDetectorDescription('locked-ether'),
  whitelist_blacklist: IS_WEBAPP ? 'This contract has its own white and blacklists, potentially allowing the owner to impose restrictions on your token transactions.' : getDetectorDescription('whitelist_blacklist'),
  hidden_mint: IS_WEBAPP ? 'The contract owner can secretly mint new tokens, which may negatively affect the token price.' : getDetectorDescription('hidden_mint'),
  'reentrancy-eth': IS_WEBAPP ? 'The contract is prone to reentrancy attacks targeting ETH tokens, which could result in unpredictable outcomes.' : getDetectorDescription('reentrancy-eth'),
  'reentrancy-no-eth': IS_WEBAPP ? 'The contract is prone to reentrancy attacks targeting tokens other than ETH, which could result in unpredictable outcomes.' : getDetectorDescription('reentrancy-no-eth'),
  'unchecked-transfer': IS_WEBAPP ? 'The contract transfers assets without validating the transfer\'s successful completion. If it fails, you won\'t receive any assets or get back the tokens you sent.' : getDetectorDescription('unchecked-transfer'),
  'controlled-delegatecall': IS_WEBAPP ? 'This contract can delegate calls to other contracts, which could result in unpredictable outcomes.' : getDetectorDescription('controlled-delegatecall'),
  'reentrancy-events': IS_WEBAPP ? 'The contract is prone to events-specific reentrancy attacks.' : getDetectorDescription('reentrancy-events'),
  'reentrancy-benign': IS_WEBAPP ? 'The contract is prone to reentrancy attacks. It is a harmless function on its own, however, it often accompanies malicious attempts.' : getDetectorDescription('reentrancy-benign'),
  'unchecked-send': IS_WEBAPP ? 'The contract sends assets without validating the transfer\'s successful completion. If it fails, you won\'t receive any assets or get back the tokens you sent.' : getDetectorDescription('unchecked-send'),
  'unchecked-lowlevel': IS_WEBAPP ? 'The contract has unidentified low-level calls. If the asset transfer fails, you won\'t receive any tokens or get back the tokens you sent.' : getDetectorDescription('unchecked-lowlevel'),
  'tx-origin': IS_WEBAPP ? 'This contract uses an outdated tx.origin function, which makes it vulnerable.' : getDetectorDescription('obsolete-functionality'),
  'erc721-interface': IS_WEBAPP ? 'The contract\'s ERC-721 interface is incorrect, resulting in reduced functionality or deviation from generally accepted workflow.' : getDetectorDescription('erc721-interface'),
  'erc20-interface': IS_WEBAPP ? 'The contract\'s ERC-20 interface is incorrect, resulting in reduced functionality or deviation from generally accepted workflow.' : getDetectorDescription('erc20-interface'),
  'arbitrary-send-eth': IS_WEBAPP ? 'The contract can send ETH tokens to random addresses.' : getDetectorDescription('arbitrary-send-eth'),
  'arbitrary-send-erc20': IS_WEBAPP ? 'The contract can send ERC-20 tokens to random addresses.' : getDetectorDescription('arbitrary-send-erc20'),
  'arbitrary-send-erc20-permit': IS_WEBAPP ? 'The contract can send ERC-20 tokens to random addresses and use the permit function.' : getDetectorDescription('arbitrary-send-erc20-permit'),
  'unprotected-upgrade': IS_WEBAPP ? 'This contract can upgrade, which could result in unpredictable outcomes.' : getDetectorDescription('upgradable-contract'),
  'delegatecall-loop': IS_WEBAPP ? 'This contract can loop and delegate calls to other contracts, which could result in unpredictable outcomes.' : getDetectorDescription('delegatecall-loop'),

  trading_cooldown: IS_WEBAPP ? 'The contract has the ability to restrict your number of transactions for some period of time.' : getDetectorDescription('trading_cooldown'),
  hardcoded_logic: IS_WEBAPP ? 'The contract has the ability to restrict your number of transactions for some period of time.' : getDetectorDescription('hardcoded_logic'),
};

export const NA_TOKEN_LABEL = 'N/A Token';
export const NA_COLLECTION_LABEL = 'N/A Collection';

export const COLLECTION_ALERT_STUB_LABEL = 'Collection';
export const TOKEN_ALERT_STUB_LABEL = 'Token';

export const NO_COLLECTION_LABEL = 'No collection name';

export enum WARNING_MESSAGES {
  TRADING_COOLDOWN = 'TRADING_COOLDOWN',
}

export const WARNING_MESSAGES_DATA = {
  [WARNING_MESSAGES.TRADING_COOLDOWN]: {
    title: 'Trading cooldown',
    message: 'The contract has the ability to restrict the number of transactions for some period of time.',
  },
};

export const FOUNDATION_MAKE_OFFER_METHOD_NAME = 'makeOfferV2';
export const FOUNDATION_PLACE_BID_METHOD_NAME = 'placeBidV2';

export const TOKEN_WARNING_MESSAGES = {
  COLLECTION_SUSPICIOUS: withTranslation('tooltip_collection_suspicious'),
  ERC_20_SUSPICIOUS: withTranslation('tooltip_erc20_suspicious'),
  SANCTIONS_SUSPICIOUS: IS_WEBAPP ? 'This token is safe but is subject to sanctions, which may impose some restrictions on your transactions with it.' : withTranslation('tooltip_sanctions_suspicious'),
};

export const CONTRACT_REDUCED_RISK_WARNING = IS_WEBAPP ? 'This contract has key risks. However, W3A reduces its risk score due to high transaction activity and user trust.' : withTranslation('tooltip_critical_risks_contract');
export const CONTRACT_FIRST_INTERACTION_WARNING = IS_WEBAPP ? 'This is your first interaction with this smart contract.' : withTranslation('tooltip_first_interaction_contract');
