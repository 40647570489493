import { format } from 'date-fns';
import { FC, useCallback, useState } from 'react';

import { Address } from 'shared/components/Address';
import { InfoPopup } from 'shared/components/InfoPopup';
import { APP_CONFIG } from 'shared/constants/common.constants';
import { withTranslation } from 'shared/helpers/i18n.helpers';

import { SuspiciousActivityData } from './interfaces';
import styles from './styles.module.scss';
import RiskCard from '../components/RiskCard';
import { RiskData } from '../interfaces';

interface Props {
  className?: string
  risks: RiskData<SuspiciousActivityData>
}

const SuspiciousActivity: FC<Props> = ({ className, risks }) => {
  const [isOpen, setIsOpen] = useState(false);

  const hasData = risks.data.length > 0;

  const onOpenClick = useCallback(
    (value: boolean) => {
      setIsOpen(value);
    },
    [],
  );

  const activitiesNames = risks.data.reduce((acc, activity) => {
    const names = activity.activitiesList.map(({ text }) => text);
    return [...acc, names] as string[];
  }, [] as string[]);

  return (
    <RiskCard
      title={withTranslation('Suspicious activity')}
      risksCount={risks.count}
      className={className}
      description={APP_CONFIG.IS_WEBAPP
        ? 'Shady or illegal actions linked to the contract.' : withTranslation('suspicious_activity_description')}
      openButtonText={withTranslation('Details')}
      isOpen={isOpen}
      onOpenClick={onOpenClick}
      hasData={hasData}
    >
      {() => (
        <div className={styles.wrap}>
          {!isOpen && hasData
        && (
          <div className={styles.names}>
            {activitiesNames.map((name) => <div key={name} className={styles.name}>{name}</div>)}
            {activitiesNames.length > 3 && <div className={styles.name}>...</div>}
          </div>
        )}
          {isOpen && (
            <div className={styles.entities}>
              {risks.data.map(({
                address, contractName, description, activitiesList, isVerified,
              }) => (
                <div className={styles.entity} key={address}>
                  <div className={styles.top}>
                    <Address address={address} isVerified={isVerified} alertOnNonVerified />
                    {contractName
              && (
                <>
                  <div className={styles.separator} />
                  <span className={styles.contractName}>{contractName}</span>
                </>
              )}
                  </div>
                  {description && <div className={styles.description}>{description}</div>}
                  {activitiesList.map(({ date, text, tooltip }, index) => (
                    <div className={styles.activity} key={text}>
                      <span className={styles.index}>#{index + 1}</span>
                      {date && <span className={styles.date}>{format(new Date(date), 'MMM dd, yyyy')}:</span>}
                      <span className={styles.text}>{text}</span>
                      {tooltip && <InfoPopup triggerClassName={styles.infoPopupTrigger} position="bottom right" content={tooltip} />}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </RiskCard>
  );
};

SuspiciousActivity.defaultProps = {
  className: '',
};

export default SuspiciousActivity;
