/* eslint-disable max-len */
export const IconBnbDarkMode = (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.47868 18L4.49133 22.6419L8.43554 24.9628V27.6805L2.18305 24.0134V16.6426L4.47868 18ZM4.47868 13.3581V16.0631L2.18164 14.7043V11.9993L4.47868 10.6405L6.78696 11.9993L4.47868 13.3581ZM10.0827 11.9993L12.3797 10.6405L14.688 11.9993L12.3797 13.3581L10.0827 11.9993Z" fill="#999999" />
    <path d="M6.1385 21.6798V18.9621L8.43554 20.3209V23.0259L6.1385 21.6798ZM10.0827 25.9362L12.3797 27.295L14.688 25.9362V28.6412L12.3797 30L10.0827 28.6412V25.9362ZM17.9824 11.9993L20.2794 10.6405L22.5877 11.9993V14.7043L20.2794 16.0631V13.3581L17.9824 11.9993ZM20.2794 22.6419L20.2921 18L22.5891 16.6412V24.012L16.3366 27.6791V24.9614L20.2794 22.6419Z" fill="#999999" />
    <path d="M18.6322 21.6798L16.3352 23.0259V20.3209L18.6322 18.9621V21.6798Z" fill="#999999" />
    <path d="M18.6322 14.3202L18.6449 17.0379L14.6894 19.3588V24.012L12.3924 25.3581L10.0954 24.012V19.3588L6.13991 17.0379V14.3202L8.44679 12.9614L12.3783 15.2936L16.3338 12.9614L18.6421 14.3202H18.6322ZM6.1385 9.67976L12.3797 6L18.6322 9.67976L16.3352 11.0386L12.3797 8.70636L8.43554 11.0386L6.1385 9.67976Z" fill="#999999" />
    <path d="M28.479 11.1368L23.5747 2.33393C22.9157 1.14952 22.0038 0.5 21 0.5C19.9962 0.5 19.0843 1.14952 18.4253 2.33393L13.521 11.1368C12.9003 12.2601 12.8314 13.3375 13.3294 14.1857C13.8275 15.0339 14.8084 15.5 16.0957 15.5H25.9043C27.1916 15.5 28.1725 15.0339 28.6706 14.1857C29.1686 13.3375 29.0997 12.2524 28.479 11.1368Z" fill="#F5C300" />
    <path d="M21 4V8M21 12H21.01" stroke="#202124" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const IconBnb = (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.47868 18L4.49133 22.6419L8.43554 24.9628V27.6805L2.18305 24.0134V16.6426L4.47868 18ZM4.47868 13.3581V16.0631L2.18164 14.7043V11.9993L4.47868 10.6405L6.78696 11.9993L4.47868 13.3581ZM10.0827 11.9993L12.3797 10.6405L14.688 11.9993L12.3797 13.3581L10.0827 11.9993Z" fill="#808080" />
    <path d="M6.1385 21.6798V18.9621L8.43554 20.3209V23.0259L6.1385 21.6798ZM10.0827 25.9362L12.3797 27.295L14.688 25.9362V28.6412L12.3797 30L10.0827 28.6412V25.9362ZM17.9824 11.9993L20.2794 10.6405L22.5877 11.9993V14.7043L20.2794 16.0631V13.3581L17.9824 11.9993ZM20.2794 22.6419L20.2921 18L22.5891 16.6412V24.012L16.3366 27.6791V24.9614L20.2794 22.6419Z" fill="#808080" />
    <path d="M18.6322 21.6798L16.3352 23.0259V20.3209L18.6322 18.9621V21.6798Z" fill="#808080" />
    <path d="M18.6322 14.3202L18.6449 17.0379L14.6894 19.3588V24.012L12.3924 25.3581L10.0954 24.012V19.3588L6.13991 17.0379V14.3202L8.44679 12.9614L12.3783 15.2936L16.3338 12.9614L18.6421 14.3202H18.6322ZM6.1385 9.67976L12.3797 6L18.6322 9.67976L16.3352 11.0386L12.3797 8.70636L8.43554 11.0386L6.1385 9.67976Z" fill="#808080" />
    <path d="M28.479 11.1368L23.5747 2.33393C22.9157 1.14952 22.0038 0.5 21 0.5C19.9962 0.5 19.0843 1.14952 18.4253 2.33393L13.521 11.1368C12.9003 12.2601 12.8314 13.3375 13.3294 14.1857C13.8275 15.0339 14.8084 15.5 16.0957 15.5H25.9043C27.1916 15.5 28.1725 15.0339 28.6706 14.1857C29.1686 13.3375 29.0997 12.2524 28.479 11.1368Z" fill="#F5C300" />
    <path d="M21 4V8M21 12H21.01" stroke="#202124" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const IconBaseDarkMode = (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.36602 17.2929H15.9297V18.708H3.36602V17.2929Z" fill="#999999" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12 26.0703C16.4568 26.0703 20.0698 22.4573 20.0698 18.0004C20.0698 13.5436 16.4568 9.93063 12 9.93063C7.54312 9.93063 3.93014 13.5436 3.93014 18.0004C3.93014 22.4573 7.54312 26.0703 12 26.0703ZM12 29.455C18.3261 29.455 23.4545 24.3266 23.4545 18.0004C23.4545 11.6743 18.3261 6.5459 12 6.5459C5.67378 6.5459 0.54541 11.6743 0.54541 18.0004C0.54541 24.3266 5.67378 29.455 12 29.455Z" fill="#999999" />
    <path d="M28.479 11.1368L23.5747 2.33393C22.9157 1.14952 22.0038 0.5 21 0.5C19.9962 0.5 19.0843 1.14952 18.4253 2.33393L13.521 11.1368C12.9003 12.2601 12.8314 13.3375 13.3294 14.1857C13.8275 15.0339 14.8084 15.5 16.0957 15.5H25.9043C27.1916 15.5 28.1725 15.0339 28.6706 14.1857C29.1686 13.3375 29.0997 12.2524 28.479 11.1368Z" fill="#F5C300" />
    <path d="M21 4V8M21 12H21.01" stroke="#202124" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const IconBase = (
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.36602 17.2929H15.9297V18.708H3.36602V17.2929Z" fill="#808080" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12 26.0703C16.4568 26.0703 20.0698 22.4573 20.0698 18.0004C20.0698 13.5436 16.4568 9.93063 12 9.93063C7.54312 9.93063 3.93014 13.5436 3.93014 18.0004C3.93014 22.4573 7.54312 26.0703 12 26.0703ZM12 29.455C18.3261 29.455 23.4545 24.3266 23.4545 18.0004C23.4545 11.6743 18.3261 6.5459 12 6.5459C5.67378 6.5459 0.54541 11.6743 0.54541 18.0004C0.54541 24.3266 5.67378 29.455 12 29.455Z" fill="#808080" />
    <path d="M28.479 11.1368L23.5747 2.33393C22.9157 1.14952 22.0038 0.5 21 0.5C19.9962 0.5 19.0843 1.14952 18.4253 2.33393L13.521 11.1368C12.9003 12.2601 12.8314 13.3375 13.3294 14.1857C13.8275 15.0339 14.8084 15.5 16.0957 15.5H25.9043C27.1916 15.5 28.1725 15.0339 28.6706 14.1857C29.1686 13.3375 29.0997 12.2524 28.479 11.1368Z" fill="#F5C300" />
    <path d="M21 4V8M21 12H21.01" stroke="#202124" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
