import cn from 'classnames';
import { FC } from 'react';
import { StrictPopupProps } from 'semantic-ui-react';

import Popup from 'shared/components/Popup/Popup';
import { InfoIcon } from 'shared/constants/icons.constants';

import styles from './styles.module.scss';

export type Props = StrictPopupProps & {
  styleType?: 'white' | 'diff' | 'tooltip'
  content: string | JSX.Element
  triggerClassName?: string
  triggerWrapClassName?: string
  root?: HTMLElement
}

const InfoPopup: FC<Props> = ({
  styleType, content, triggerClassName, root, position = 'bottom center', triggerWrapClassName, ...props
}) => (
  <Popup
    styleType={styleType || 'white'}
    content={content}
    position={position}
    root={root}
    trigger={<span className={cn(styles.wrap, triggerWrapClassName)}><InfoIcon className={cn(styles.icon, triggerClassName)} /></span>}
    on="hover"
    hoverable
    className={cn(styles.popup, props.className)}
    {...props}
  />
);

InfoPopup.defaultProps = {
  triggerClassName: '',
  styleType: 'white',
  root: undefined,
};

export default InfoPopup;
