import cn from 'classnames';
import { memo } from 'react';
import { Table, TableHeaderProps } from 'semantic-ui-react';

import styles from '../styles.module.scss';

type Props = TableHeaderProps & {
  className?: string
}

const TableHead = ({ children, className, ...props }: Props) => (
  <Table.Header
    className={cn(styles.tableHead, className)}
    {...props}
  >
    {children}
  </Table.Header>
);

TableHead.defaultProps = {
  className: '',
};

export default memo(TableHead);
