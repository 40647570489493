import { FC } from 'react';

import SwapInfo from 'shared/components/SwapInfo';
import { CUSTOM_CHAINS } from 'shared/constants/custom-chain.constants';
import { RISK_TYPE } from 'shared/constants/risks.constants';
import { withTranslation } from 'shared/helpers/i18n.helpers';
import { Web3ContractEntity } from 'shared/interfaces/analyze.interfaces';
import { TraceWithRisk } from 'shared/interfaces/fetch.interfaces';

import { ProtocolRisks } from './interfaces';
import styles from './styles.module.scss';
import AdditionalInfo from '../../components/AdditionalInfo';
import Report from '../../components/Report';
import CustomChainMessage from '../components/CustomChainMessage';
import Note from '../components/Note';
import { TransactionDetailsData, TransactionGasDetails } from '../interfaces';

interface Props {
  transactionDetails: TransactionDetailsData;
  risks: ProtocolRisks[];
  handleTokenSelect: (tokenAddress: string, type: Web3ContractEntity.type) => void;
  gasDetails: TransactionGasDetails | null;
  risk: RISK_TYPE;
  trace: TraceWithRisk[];
  contractsCount: number;
  chain?: CUSTOM_CHAINS;
}

const ScanningResult: FC<Props> = ({
  risks, handleTokenSelect, transactionDetails, gasDetails, risk, trace, contractsCount, chain,
}) => {

  const dangerMessages = [
    ...transactionDetails.dangerMessages || [],
  ];

  const hasSwapDetails = transactionDetails.swap?.income?.length > 0 || transactionDetails.swap?.loss?.length > 0;

  return (
    <>
      <div className={styles.wrap}>
        {chain && <CustomChainMessage chain={chain} />}
        <Report
          risk={risk}
          itemsCount={contractsCount || 0}
          risks={risks}
          handleContractSelect={handleTokenSelect}
          trace={trace}
          detects={dangerMessages}
          chain={chain}
        />
        {hasSwapDetails && (
          <div className={styles.block}>
            <div className={styles.title}>{withTranslation('You send and receive')}</div>
            <SwapInfo data={transactionDetails.swap} />
          </div>
        )}
        {/* <TotalRisks risks={risks} handleTokenSelect={handleTokenSelect} /> */}
        <AdditionalInfo gasDetails={gasDetails} dexPairs={transactionDetails.swap?.dexPairs || null} />
        <Note />
      </div>
    </>
  );
};

export default ScanningResult;
