export const BROWSER = process.env.REACT_APP_BROWSER;
export const APP_CONFIG = {
  BROWSER: process.env.NEXT_PUBLIC_BROWSER || process.env.REACT_APP_BROWSER,
  IS_WEBAPP: process.env.NEXT_PUBLIC_IS_WEBAPP || process.env.REACT_APP_IS_WEBAPP,
};

export const ENTER_KEY = 'Enter';

export const W3A_DECISION_EVENT = 'w3aDecisionEvent';
export const REQUEST_OPEN_TRACING_DIAGRAM_PAGE = 'requestOpenTracingDiagramPage';
