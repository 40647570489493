import { RISK_TYPE } from 'shared/constants/risks.constants';

export const getRiskByOverpaymentPercent = (
  percent: number,
): { risk: RISK_TYPE; isIncreased: boolean } => {
  if (percent <= 20) {
    const risk = RISK_TYPE.LOW;
    return { risk, isIncreased: percent >= 10 };
  }
  if (percent >= 20 && percent < 100) {
    const risk = RISK_TYPE.MIDDLE;
    return { risk, isIncreased: percent >= 50 };
  }

  const risk = RISK_TYPE.CRITICAL;
  return { risk, isIncreased: percent >= 200 };
};
