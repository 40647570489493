import cn from 'classnames';
import {
  FC, useEffect, useRef, useState,
} from 'react';

import Skeleton from 'shared/components/Skeleton/Skeleton';

import { IMAGE_SIZES } from './constants';
import styles from './styles.module.scss';

type Props = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> &
{ name?: string, isRound?: boolean, size?: IMAGE_SIZES }

const Image: FC<Props> = ({
  alt,
  src,
  className = '',
  isRound,
  name,
  size = IMAGE_SIZES.M,
  ...props
}) => {
  const [hasError, setHasError] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const ref = useRef<HTMLImageElement>(null);

  const onLoad = () => setLoaded(true);

  useEffect(() => {
    if (ref.current && ref.current.complete) {
      onLoad();
    }
  });

  const showStub = hasError || !src;

  useEffect(() => {
    setHasError(false);
  }, [src]);

  const stub = (
    <div {...props} className={cn(styles.wrap, styles.stub, styles[size], className, { [styles.round]: isRound })}>
      {name ? (name?.[0] || '?') : '?'}
    </div>
  );

  if (showStub) {
    return stub;
  }

  return (
    <>
      {!loaded && (
        <Skeleton
          {...props}
          containerClassName={styles.skeletonContainer}
          className={cn(styles.wrap, styles.skeleton, className, styles[size], { [styles.hidden]: loaded, [styles.round]: isRound })}
        />
      )}
      <img
        alt={alt}
        src={src}
        onError={() => setHasError(true)}
        onLoad={onLoad}
        style={{ display: loaded ? 'block' : 'none' }}
        className={cn(styles.wrap, styles.image, className, styles[size], { [styles.hidden]: !loaded, [styles.round]: isRound })}
        {...props}
      />
    </>
  );
};

export default Image;
