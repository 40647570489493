import cn from 'classnames';
import {
  memo, useCallback, useState,
} from 'react';

import { RISK_TYPE } from 'shared/constants/risks.constants';
import { TRANSACTION_VIEWS } from 'shared/constants/view.constants';
import { WALLET_PROVIDERS } from 'shared/constants/wallet.constants';
import { useExtensionScroll } from 'shared/hooks/common.hooks';
import { ProceedFuncWithManualId } from 'shared/interfaces/common.interfaces';

import styles from './styles.module.scss';
import FooterButtons from '../FooterButtons';

interface Props {
  handleDecline: () => void;
  handleProceed: ProceedFuncWithManualId;
  children: (setRisk: (value: RISK_TYPE) => void) => JSX.Element;
  walletProvider?: WALLET_PROVIDERS | null;
  risk?: RISK_TYPE;
  transactionView?: TRANSACTION_VIEWS | null;
  wrapClassName?: string;
}

const WindowWithButtons = ({
  handleDecline, handleProceed, children, walletProvider, risk = RISK_TYPE.LOW, transactionView, wrapClassName,
}: Props) => {
  const [innerRisk, setInnerRisk] = useState<RISK_TYPE>(risk);
  const setRisk = useCallback((riskValue: RISK_TYPE) => {
    setInnerRisk(riskValue);
  }, []);

  useExtensionScroll(styles.wrap);
  return (
    <>
      <div className={cn(styles.wrap, wrapClassName)}>
        {children(setRisk)}
      </div>
      <FooterButtons
        className={styles.buttons}
        handleDecline={handleDecline}
        handleProceed={handleProceed}
        walletProvider={walletProvider}
        risk={innerRisk}
        transactionView={transactionView}
      />
    </>
  );
};

export default memo(WindowWithButtons);
