/* eslint-disable max-len */
import { APP_CONFIG } from 'shared/constants/common.constants';
import { IPFS_PROTOCOL, IPFS_URL } from 'shared/services/token/shared/constants';

const { getExtensionImageSrc } = APP_CONFIG.IS_WEBAPP ? require('shared/helpers/stub.helpers') : require('shared/helpers/browser-api.helpers.ts');

export const getImageUrl = (src: string): string => {
  if (APP_CONFIG.IS_WEBAPP) {
    return src;
  }
  return getExtensionImageSrc(src) as string;
};

export const fixImageLink = (src: string | undefined): string | undefined => {
  if (src?.includes(IPFS_PROTOCOL)) {
    let newSrc = src.replace(IPFS_PROTOCOL, '');
    newSrc = `${IPFS_URL}${newSrc}`;
    return newSrc;
  }

  return src;
};
